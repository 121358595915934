"use client";

import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

interface DatePickerDemoProps {
  date: Date | undefined;
  onChange: (date: Date | undefined) => void;
  label: string;
  minDate?: Date; // Agregamos minDate
  maxDate?: Date; // Agregamos maxDate
}

export function DatePickerDemo({
  date,
  onChange,
  label,
  minDate,
  maxDate,
}: DatePickerDemoProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[240px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP", { locale: es }) : <span>{label}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={onChange}
          initialFocus
          disabled={(date) =>
            !!((minDate && date < minDate) || (maxDate && date > maxDate))
          }
        />
      </PopoverContent>
    </Popover>
  );
}
