/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";
import { MdiPencilBoxMultipleOutline } from "@/components/icons/MdiPencilBoxMultipleOutline";
import { Label } from "@/components/ui/label";

interface EditIngresoDialogProps {
  ingresoId: number;
  text: string;
  value: number;
  onSave: (
    ingresoId: number,
    updatedData: { text: string; value: number },
  ) => Promise<void>;
  disabled?: boolean;
}

export function EditIngresoDialog({
  ingresoId,
  text,
  value,
  onSave,
  disabled = false,
}: EditIngresoDialogProps) {
  const [textInput, setTextInput] = useState(text);
  const [valueInput, setValueInput] = useState(value.toString());

  useEffect(() => {
    setTextInput(text);
    setValueInput(value.toString());
  }, [text, value]);

  const handleSave = async () => {
    const updatedData = {
      text: textInput,
      value: Number(valueInput),
    };
    await onSave(ingresoId, updatedData);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" disabled={disabled}>
          <MdiPencilBoxMultipleOutline className="w-4 h-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editar Ingreso</DialogTitle>
          <DialogDescription>
            Modifica el texto y el valor aquí y haz clic en guardar cuando
            termines.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Label htmlFor="textInput">Tipo de honorario</Label>
          <Input
            id="textInput"
            value={textInput}
            onChange={(e) => {
              setTextInput(e.target.value);
            }}
          />
          <Label htmlFor="valueInput">Valor</Label>
          <Input
            id="valueInput"
            type="number"
            value={valueInput}
            onChange={(e) => {
              setValueInput(e.target.value);
            }}
          />
        </div>
        <DialogFooter>
          <Button onClick={handleSave}>Guardar Cambios</Button>
          <DialogClose asChild>
            <Button variant="secondary">Cerrar</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
