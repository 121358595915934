import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "@/components/ui/card";
import { toast } from "sonner";
import {
  getTodaySiniestrosCounts,
  getOpenSiniestrosCount,
  getMonthlySiniestrosCounts,
  getAverageClosureDays,
  getUserActivity,
} from "@/services/siniestroService";
import DateFilter from "./DateFilter";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Skeleton } from "@/components/ui/skeleton";

import { HiChartPie } from "react-icons/hi";
import { FaRegClock } from "react-icons/fa";
import { FaCircleExclamation } from "react-icons/fa6";
import { LuPlusCircle } from "react-icons/lu";
import NotFoundPage from "../pages/NotFoundPage";
import { useAuth } from "@/contexts/AuthContext";

interface UserActivity {
  username: string;
  access_count: number;
}

const DashboardPage = () => {
  const [totalOpenSiniestros, setTotalOpenSiniestros] = useState<number>(0);
  const [totalStateId8, setTotalStateId8] = useState<number>(0);
  const [newSiniestros, setNewSiniestros] = useState<number>(0);
  const [userActivityToday, setUserActivityToday] = useState([]);
  const [userActivityWeekly, setUserActivityWeekly] = useState([]);
  const [closedToday, setClosedToday] = useState<number>(0);
  const [monthlyData, setMonthlyData] = useState<{
    newMonthSiniestros: number;
    closedMonth: number;
  }>({
    newMonthSiniestros: 0,
    closedMonth: 0,
  });
  const [averageClosureDays, setAverageClosureDays] = useState<number | null>(
    null
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { roles } = useAuth();
  const hasRole2 = roles.includes(2);

  if (!hasRole2) {
    return <NotFoundPage />;
  }

  const fetchSiniestrosCounts = async (year: number, month: number) => {
    try {
      setIsLoading(true);
      const { totalCount } = await getOpenSiniestrosCount();
      const { countStateId8, newSiniestros, closedToday } =
        await getTodaySiniestrosCounts();
      const monthlyCounts = await getMonthlySiniestrosCounts(year, month);
      const avgClosureDays = await getAverageClosureDays();

      let activityToday = await getUserActivity("today");
      let activityWeekly = await getUserActivity("weekly");

      // Ordenar por access_count de mayor a menor
      activityToday = activityToday.sort(
        (a: UserActivity, b: UserActivity) => b.access_count - a.access_count
      );
      activityWeekly = activityWeekly.sort(
        (a: UserActivity, b: UserActivity) => b.access_count - a.access_count
      );

      setTotalOpenSiniestros(totalCount);
      setTotalStateId8(countStateId8);
      setNewSiniestros(newSiniestros);
      setClosedToday(closedToday);
      setMonthlyData(monthlyCounts);
      setAverageClosureDays(avgClosureDays);
      setUserActivityToday(activityToday);
      setUserActivityWeekly(activityWeekly);
    } catch (error) {
      toast.error("Error al obtener los siniestros");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSiniestrosCounts(selectedYear, selectedMonth);
  }, [selectedYear, selectedMonth]);

  const handleYearChange = (value: string) => {
    setSelectedYear(Number(value));
  };

  const handleMonthChange = (value: string) => {
    setSelectedMonth(Number(value));
  };

  const getMonthName = (month: number): string => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return months[month - 1];
  };

  const pieChartData = [
    {
      name: "Nuevos",
      value: monthlyData.newMonthSiniestros,
      fill: "hsl(var(--chart-1))",
    },
    {
      name: "Cerrados",
      value: monthlyData.closedMonth,
      fill: "hsl(var(--chart-2))",
    },
  ];

  const todayPieChartData = [
    {
      name: "Nuevos",
      value: newSiniestros,
      fill: "hsl(var(--chart-3))",
    },
    {
      name: "Cerrados",
      value: closedToday,
      fill: "hsl(var(--chart-4))",
    },
  ];

  const chartConfig = {
    newMonthSiniestros: {
      label: "Nuevos Siniestros",
      color: "var(--chart-1)",
    },
    closedMonth: {
      label: "Siniestros Cerrados",
      color: "var(--chart-2)",
    },
    newSiniestros: {
      label: "Nuevos Hoy",
      color: "var(--chart-3)",
    },
    closedToday: {
      label: "Cerrados Hoy",
      color: "var(--chart-4)",
    },
  } satisfies ChartConfig;

  return (
    <div className="p-4 space-y-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Dashboard</h1>
        <DateFilter
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          onYearChange={handleYearChange}
          onMonthChange={handleMonthChange}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {isLoading ? (
          Array(4)
            .fill(null)
            .map((_, index) => (
              <Card key={index}>
                <CardHeader>
                  <CardTitle>
                    <Skeleton className="h-6 w-1/2" />
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <Skeleton className="h-12 w-3/4" />
                </CardContent>
              </Card>
            ))
        ) : (
          <>
            <Card>
              <CardHeader className="items-center">
                <CardTitle className="flex items-center space-x-2">
                  <FaCircleExclamation className="h-6 w-6 text-blue-500" />
                  <span>Total siniestros abiertos (reabiertos)</span>
                </CardTitle>
              </CardHeader>
              <CardContent className="flex items-center justify-center">
                <p className="text-2xl font-bold">
                  {totalOpenSiniestros} ({totalStateId8})
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="items-center">
                <CardTitle className="flex items-center space-x-2">
                  <LuPlusCircle className="h-6 w-6 text-green-500" />
                  <span>
                    Nuevos y cerrados hoy (
                    {new Date().toLocaleDateString("es-AR", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                    )
                  </span>
                </CardTitle>
              </CardHeader>
              <CardContent className="flex justify-between items-center">
                <div>
                  <p className="text-lg font-semibold">Nuevos:</p>
                  <p className="text-2xl font-bold">{newSiniestros}</p>
                </div>
                <div>
                  <p className="text-lg font-semibold">Cerrados:</p>
                  <p className="text-2xl font-bold">{closedToday}</p>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="items-center">
                <CardTitle className="flex items-center space-x-2">
                  <LuPlusCircle className="h-6 w-6 text-green-500" />
                  <span>
                    Siniestros este mes ({getMonthName(selectedMonth)})
                  </span>
                </CardTitle>
              </CardHeader>
              <CardContent className="flex justify-between">
                <div>
                  <p className="text-lg font-semibold">Nuevos:</p>
                  <p className="text-2xl font-bold">
                    {monthlyData.newMonthSiniestros}
                  </p>
                </div>
                <div>
                  <p className="text-lg font-semibold">Cerrados:</p>
                  <p className="text-2xl font-bold">
                    {monthlyData.closedMonth}
                  </p>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="items-center">
                <CardTitle className="flex items-center space-x-2">
                  <FaRegClock className="h-6 w-6 text-red-500" />
                  <span>Tiempo promedio de cierre de siniestros</span>
                </CardTitle>
                <CardDescription>
                  Últimos 45 días - no se cuentan telefónicos
                </CardDescription>
              </CardHeader>
              <CardContent className="flex items-center justify-center">
                {isLoading ? (
                  <Skeleton className="h-12 w-3/4" />
                ) : (
                  <p className="text-2xl font-bold">
                    {averageClosureDays !== null
                      ? `${averageClosureDays} días`
                      : "N/A"}
                  </p>
                )}
              </CardContent>
            </Card>
          </>
        )}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <Card className="col-span-1 lg:col-span-4">
          <CardHeader className="items-center pb-0">
            <CardTitle className="flex items-center space-x-2">
              <HiChartPie className="h-6 w-6 text-purple-500" />
              <span>Gráfico de siniestros</span>
            </CardTitle>
            <CardDescription>
              Mostrando siniestros nuevos y cerrados por mes y hoy
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col lg:flex-row justify-between pb-0">
            <div className="flex-1">
              <h2 className="text-lg font-semibold mb-2 text-center">
                Siniestros del Mes ({getMonthName(selectedMonth)})
              </h2>
              {isLoading ? (
                <div className="flex justify-center items-center h-full">
                  <Skeleton className="h-[250px] w-full" />
                </div>
              ) : (
                <ChartContainer
                  config={chartConfig}
                  className="mx-auto aspect-square max-h-[250px]"
                >
                  <PieChart>
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <Pie
                      data={pieChartData}
                      dataKey="value"
                      nameKey="name"
                      innerRadius={60}
                    />
                  </PieChart>
                </ChartContainer>
              )}
            </div>
            <div className="flex-1">
              <h2 className="text-lg font-semibold mb-2 text-center">
                Siniestros de hoy{" "}
                {new Date().toLocaleDateString("es-AR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </h2>
              {isLoading ? (
                <div className="flex justify-center items-center h-full">
                  <Skeleton className="h-[250px] w-full" />
                </div>
              ) : (
                <ChartContainer
                  config={chartConfig}
                  className="mx-auto aspect-square max-h-[250px]"
                >
                  <PieChart>
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <Pie
                      data={todayPieChartData}
                      dataKey="value"
                      nameKey="name"
                      innerRadius={60}
                    />
                  </PieChart>
                </ChartContainer>
              )}
            </div>
          </CardContent>
          <CardFooter className="flex-col gap-2 text-sm">
            <div className="leading-none text-muted-foreground">
              Mostrando total de siniestros para el último mes seleccionado y
              hoy
            </div>
          </CardFooter>
        </Card>

        <Card className="col-span-1 lg:col-span-4">
          <CardHeader className="items-center pb-0">
            <CardTitle className="flex items-center space-x-2">
              <HiChartPie className="h-6 w-6 text-purple-500" />
              <span>Actividad de Usuarios - Hoy</span>
            </CardTitle>
            <CardDescription>
              Mostrando la actividad de los usuarios del día.
            </CardDescription>
          </CardHeader>
          <CardContent>
            {isLoading ? (
              <Skeleton className="h-[250px] w-full" />
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={userActivityToday}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="username" />
                  <YAxis />
                  <Tooltip
                    formatter={(value) => `${value} interacciones`}
                    labelFormatter={(label) => `Usuario: ${label}`}
                  />
                  <Legend />
                  <Bar
                    dataKey="access_count"
                    fill="#8884d8"
                    name="Interacciones"
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </CardContent>
        </Card>

        <Card className="col-span-1 lg:col-span-4">
          <CardHeader className="items-center pb-0">
            <CardTitle className="flex items-center space-x-2">
              <HiChartPie className="h-6 w-6 text-purple-500" />
              <span>Actividad de Usuarios - esta semana</span>
            </CardTitle>
            <CardDescription>
              Mostrando la actividad de los usuarios de la semana.
            </CardDescription>
          </CardHeader>
          <CardContent>
            {isLoading ? (
              <Skeleton className="h-[250px] w-full" />
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={userActivityWeekly}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="username" />
                  <YAxis />
                  <Tooltip
                    formatter={(value) => `${value} interacciones`}
                    labelFormatter={(label) => `Usuario: ${label}`}
                  />
                  <Legend />
                  <Bar
                    dataKey="total_access_count"
                    fill="#82ca9d"
                    name="Interacciones"
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DashboardPage;
