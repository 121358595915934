import { create } from "zustand";

import { fetchTipoPagos, updateTipoPago } from "@/services/tipoPagoService";
import useAuthStore from "./authStore";

interface TipoPago {
  id: number;
  name: string;
  oc: boolean;
}

interface TipoPagoState {
  tipoPagos: TipoPago[];
  isLoading: boolean; // Añadir estado de carga
  currentPage: number;
  fetchTipoPagos: () => Promise<void>;
  updateTipoPago: (tipoPagoId: number, values: any) => Promise<void>;
  setCurrentPage: (page: number) => void;
  editDialogId: number | null;
  setEditDialogId: (id: number | null) => void;
  setLoading: (loading: boolean) => void; // Método para establecer el estado de carga
}

export const useTipoPagoStore = create<TipoPagoState>((set) => ({
  tipoPagos: [],
  isLoading: false, // Inicializar el estado de carga como false
  currentPage: 0,
  fetchTipoPagos: async () => {
    try {
      const tipoPagosData = await fetchTipoPagos();
      set({ tipoPagos: tipoPagosData });
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "USER_DISABLED") {
          useAuthStore.getState().setUserDisabled(true);
        } else {
          console.error("Error in fetchTipoPagos:", error);
        }
      }
    }
  },
  updateTipoPago: async (tipoPagoId, values) => {
    set({ isLoading: true }); // Establece el estado de carga antes de la operación
    try {
      await updateTipoPago(tipoPagoId, values);
      // Luego de la operación exitosa puedes actualizar el estado global si es necesario
    } catch (error) {
      // Manejo de errores
    } finally {
      set({ isLoading: false }); // Restablecer el estado de carga al final de la operación
    }
  },
  setCurrentPage: (page) => {
    set({ currentPage: page });
  },
  editDialogId: null,
  setEditDialogId: (id) => {
    set({ editDialogId: id });
  },
  setLoading: (loading) => {
    set({ isLoading: loading });
  }, // Método para actualizar el estado de carga
}));
