import { create } from "zustand";

import { fetchCausas, updateCausa } from "@/services/causaService";
import useAuthStore from "./authStore";

interface Causa {
  id: number;
  name: string;
}

interface CausaState {
  causas: Causa[];
  isLoading: boolean; // Añadir estado de carga
  currentPage: number;
  fetchCausas: () => Promise<void>;
  setCurrentPage: (page: number) => void;
  editDialogId: number | null;
  setEditDialogId: (id: number | null) => void;
  setLoading: (loading: boolean) => void; // Método para establecer el estado de carga
}

export const useCausaStore = create<CausaState>((set) => ({
  causas: [],
  isLoading: false, // Inicializar el estado de carga como false
  currentPage: 0,
  fetchCausas: async () => {
    try {
      const causasData = await fetchCausas();
      set({ causas: causasData });
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "USER_DISABLED") {
          useAuthStore.getState().setUserDisabled(true);
        } else {
          throw error;
        }
      }
    }
  },
  updateCausa: async (causaId: number, values: any) => {
    set({ isLoading: true }); // Establece el estado de carga antes de la operación
    try {
      await updateCausa(causaId, values);
      // Luego de la operación exitosa puedes actualizar el estado global si es necesario
    } catch (error) {
      // Manejo de errores
    } finally {
      set({ isLoading: false }); // Restablecer el estado de carga al final de la operación
    }
  },
  setCurrentPage: (page) => {
    set({ currentPage: page });
  },
  editDialogId: null,
  setEditDialogId: (id) => {
    set({ editDialogId: id });
  },
  setLoading: (loading) => {
    set({ isLoading: loading });
  }, // Método para actualizar el estado de carga
}));
