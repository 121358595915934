import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { fetchSiniestrosOlderThan } from "@/services/siniestroService";
import { fetchParentableCompanies } from "@/services/companyService";
import Spinner from "../ui/spinner";

const OldSiniestrosTable = () => {
  const [siniestros6Months, setSiniestros6Months] = useState<Siniestro[]>([]);
  const [siniestros12Months, setSiniestros12Months] = useState<Siniestro[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [data6Months, data12Months, companiesData] = await Promise.all([
          fetchSiniestrosOlderThan(
            6,
            selectedCompany ? parseInt(selectedCompany) : undefined
          ),
          fetchSiniestrosOlderThan(
            12,
            selectedCompany ? parseInt(selectedCompany) : undefined
          ),
          fetchParentableCompanies(),
        ]);
        setSiniestros6Months(data6Months.siniestros);
        setSiniestros12Months(data12Months.siniestros);
        setCompanies(companiesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [selectedCompany]);

  return (
    <div className="space-y-8">
      <Card>
        <CardHeader>
          <CardTitle>Siniestros prescritos</CardTitle>
        </CardHeader>
        <CardContent>
          <Select onValueChange={setSelectedCompany} value={selectedCompany}>
            <SelectTrigger className="w-[200px] mb-4">
              <SelectValue placeholder="Filtrar por compañía" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Todas las compañías</SelectItem>
              {companies.map((company: Company) => (
                <SelectItem key={company.id} value={company.id.toString()}>
                  {company.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <h2 className="text-xl font-semibold mb-4">
            Siniestros de más de 6 meses
          </h2>
          {isLoading ? (
            <div className="flex justify-center items-center h-40">
              <Spinner />
            </div>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Número de siniestro</TableHead>
                    <TableHead>Fecha de asignación</TableHead>
                    <TableHead>Compañía</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {siniestros6Months.map((siniestro: Siniestro) => (
                    <TableRow key={siniestro.id}>
                      <TableCell>{siniestro.companyIncidentNumber}</TableCell>
                      <TableCell>
                        {new Date(siniestro.insertedAt).toLocaleDateString(
                          "es-ES"
                        )}
                      </TableCell>
                      <TableCell>{siniestro.companyName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <h2 className="text-xl font-semibold mt-8 mb-4">
                Siniestros de más de 12 meses
              </h2>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Número de siniestro</TableHead>
                    <TableHead>Fecha de asignación</TableHead>
                    <TableHead>Compañía</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {siniestros12Months.map((siniestro: Siniestro) => (
                    <TableRow key={siniestro.id}>
                      <TableCell>{siniestro.companyIncidentNumber}</TableCell>
                      <TableCell>
                        {new Date(siniestro.insertedAt).toLocaleDateString(
                          "es-ES"
                        )}
                      </TableCell>
                      <TableCell>{siniestro.companyName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default OldSiniestrosTable;
