import { useEffect, useState } from "react";
import useUserStore from "@/stores/userStore";
import {
  fetchUserRoles,
  assignUserRole,
  revokeUserRole,
} from "@/services/userService";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { toast } from "sonner";
import useLoadingStore from "@/stores/loadingStore";
import Spinner from "../ui/spinner";
import { ReloadIcon } from "@radix-ui/react-icons";
import { fetchCompanies } from "@/services/companyService";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "../ui/select";

interface EditUserRolesDialogProps {
  userId: number;
}

interface User {
  display_name: string;
  id: number;
  name: string;
  company_id: number;
}

export function EditUserRolesDialog({ userId }: EditUserRolesDialogProps) {
  const [loading, setLoading] = useState(true);
  const roles = useUserStore((state) => state.roles);
  const [userRoles, setUserRoles] = useState<number[]>([]);
  const [initialUserRoles, setInitialUserRoles] = useState<number[]>([]);
  const { isSaving, startSaving, stopSaving } = useLoadingStore();
  const users = useUserStore((state) => state.users) as User[];
  const user = users.find((u) => u.id === userId);
  const userName = user != null ? user.display_name : "Desconocido";
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const [companies, setCompanies] = useState<{ id: number; name: string }[]>(
    []
  );

  // Función para cargar compañías
  const fetchCompaniesUsers = async () => {
    try {
      const response = await fetchCompanies(); // Llama al servicio de fetchCompanies
      setCompanies(response);
    } catch (error) {}
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        // Llamada asíncrona para obtener los roles globales y actualizar el estado de la tienda
        await useUserStore.getState().fetchRoles();

        // Obtener los roles actualizados del estado de la tienda
        const globalRoles = useUserStore.getState().roles;

        // Verifica si los roles globales se cargaron correctamente
        if (globalRoles.length === 0) {
          throw new Error("No se pudieron cargar los roles globales.");
        }

        // Llamada asíncrona para obtener los roles del usuario
        const userRolesResponse = await fetchUserRoles(userId);
        setUserRoles(userRolesResponse.roles);
        setInitialUserRoles(userRolesResponse.roles);

        // Cargar las compañías si el rol de compañía está seleccionado
        if (userRolesResponse.roles.includes(7)) {
          // Verifica si el rol 7 está en los roles del usuario
          await fetchCompaniesUsers();
          const userCompany =
            users.find((u) => u.id === userId)?.company_id || null;
          setSelectedCompanyId(userCompany);
        }
      } catch (error) {
        toast.error("Error al cargar los datos.");
      } finally {
        setLoading(false);
      }
    }

    void loadData();
  }, [userId]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  const handleRoleChange = (roleId: number, isChecked: boolean) => {
    if (isChecked) {
      setUserRoles([roleId]);
      if (roleId !== 7) {
        setSelectedCompanyId(null);
      } else if (companies.length === 0) {
        void fetchCompaniesUsers();
      }
    } else {
      setUserRoles([]);
      setSelectedCompanyId(null);
    }
  };

  const handleSave = async () => {
    startSaving(); // Inicia el estado de guardado
    try {
      const addedRoles = userRoles.filter(
        (roleId) => !initialUserRoles.includes(roleId)
      );
      const revokedRoles = initialUserRoles.filter(
        (roleId) => !userRoles.includes(roleId)
      );

      // Primero, revocar los roles existentes que deben ser eliminados
      for (const roleId of revokedRoles) {
        try {
          await revokeUserRole(userId, roleId);
        } catch (error) {
          console.warn(
            `Error al revocar el rol ${roleId} del usuario ${userId}:`,
            error
          );
        }
      }

      // Luego, asignar los nuevos roles
      for (const roleId of addedRoles) {
        try {
          if (roleId === 7 && selectedCompanyId != null) {
            await assignUserRole(userId, roleId, selectedCompanyId);
          } else {
            await assignUserRole(userId, roleId, null);
          }
        } catch (error) {
          console.warn(
            `Error al asignar el rol ${roleId} al usuario ${userId}:`,
            error
          );
        }
      }

      // If the new role is not 7, ensure company_id is set to null
      if (!userRoles.includes(7)) {
        await assignUserRole(userId, userRoles[0], null);
      }

      // Finalmente, si la compañía cambió, reasignar el rol 7 con la nueva compañía
      if (initialUserRoles.includes(7) && selectedCompanyId !== null) {
        try {
          await assignUserRole(userId, 7, selectedCompanyId);
        } catch (error) {
          console.warn(
            `Error al actualizar la compañía para el rol 7 del usuario ${userId}:`,
            error
          );
        }
      }

      // Forzar actualización del estado
      const updatedRoles = await fetchUserRoles(userId);
      useUserStore.getState().updateUserRoles(userId, updatedRoles.roles);

      // Forzar actualización de usuarios
      await useUserStore.getState().fetchUsers();

      toast.success("Roles actualizados correctamente.");
    } catch (error) {
      toast.error("Error al guardar los roles.");
    } finally {
      setTimeout(() => {
        stopSaving();
        useUserStore.getState().setLoading(false);
        useUserStore.getState().setEditRolesDialogId(null);
      }, 500);
    }
  };

  return (
    <div className="sm:max-w-[425px] space-y-4">
      <div className="mb-4">
        <label className="text-base">Roles</label>
        <div className="text-sm text-gray-600">
          Seleccione los roles para el usuario {userName}
        </div>
      </div>
      <div>
        {roles.map((role: { id: number; name: string }) => (
          <div
            key={role.id}
            className="flex flex-row items-start space-x-3 space-y-0 mb-2"
          >
            <Checkbox
              checked={Boolean(userRoles.includes(role.id))}
              onCheckedChange={(isChecked) => {
                handleRoleChange(role.id, Boolean(isChecked));
              }}
            />
            <label className="text-sm font-normal">{role.name}</label>
            {role.id === 7 &&
              userRoles.includes(7) && ( // Mostrar select solo si el rol de compañía está seleccionado
                <Select
                  value={selectedCompanyId?.toString() || ""}
                  onValueChange={(value) => setSelectedCompanyId(Number(value))}
                >
                  <SelectTrigger className="ml-2">
                    <SelectValue placeholder="Seleccionar compañía" />
                  </SelectTrigger>
                  <SelectContent className="overflow-y-auto max-h-[15rem]">
                    {companies.map((company) => (
                      <SelectItem
                        key={company.id}
                        value={company.id.toString()}
                      >
                        {company.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <Button
          type="button"
          className="mt-2"
          disabled={isSaving}
          onClick={() => {
            void handleSave();
          }}
        >
          {isSaving ? (
            <>
              <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
              Guardando...
            </>
          ) : (
            "Guardar"
          )}
        </Button>
      </div>
    </div>
  );
}
