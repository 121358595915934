import { useState, useEffect, useRef } from "react";
import {
  addAccionToSiniestro,
  deleteAttachment,
  uploadAttachment,
  fetchAttachmentsBySiniestroId,
  apiFetch,
} from "@/services/siniestroService";
import Spinner from "@/components/ui/spinner";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { DeleteAttachmentDialog } from "../dialogs/DeleteAttachmentDialog";
import { TeenyiconsPdfOutline } from "@/components/icons/TeenyiconsPdfOutline";
import { useAuth } from "@/contexts/AuthContext";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Checkbox } from "@/components/ui/checkbox";
import { Reorder } from "framer-motion";
import { IoClose, IoCloudUpload } from "react-icons/io5";
import { RotateCcw, RotateCw } from "lucide-react";
import { Card, CardContent, CardFooter } from "@/components/ui/card";

interface AttachmentsGalleryProps {
  incidentId: number;
}

const AttachmentsGallery = ({ incidentId }: AttachmentsGalleryProps) => {
  const { roles } = useAuth();
  const userRoles = Array.isArray(roles) ? roles : [];
  const hasRole7 = userRoles.includes(7);
  const [isDownloading, setIsDownloading] = useState(false);
  const [attachments, setAttachments] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [orderedSelectedAttachments, setOrderedSelectedAttachments] = useState<
    string[]
  >([]);
  const [rotations, setRotations] = useState<Record<string, number>>({});
  const [isRotating, setIsRotating] = useState<Record<string, boolean>>({});

  const handleRotate = (attachmentPath: string, angle: number) => {
    setRotations((prev) => ({
      ...prev,
      [attachmentPath]: ((prev[attachmentPath] || 0) + angle + 360) % 360,
    }));
  };

  const handleSaveRotation = async (attachmentPath: string) => {
    setIsRotating((prev) => ({ ...prev, [attachmentPath]: true }));
    try {
      const urlParts = attachmentPath.split("/");
      const relativeKey = urlParts.slice(urlParts.length - 3).join("/");

      const response = await apiFetch(`/api/files/rotate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          path: relativeKey,
          rotation: rotations[attachmentPath] || 0,
        }),
      });

      if (!response.ok) {
        throw new Error("Error saving attachment rotation");
      }

      const { newUrl } = await response.json();
      setAttachments((prevAttachments) =>
        prevAttachments.map((att) => (att === attachmentPath ? newUrl : att))
      );

      toast.success("Rotación guardada exitosamente");
    } catch (error) {
      console.error("Error saving attachment rotation:", error);
      toast.error("Error saving attachment rotation");
    } finally {
      setIsRotating((prev) => ({ ...prev, [attachmentPath]: false }));
    }
  };

  useEffect(() => {
    const loadAttachments = async () => {
      try {
        setLoading(true);
        const fetchedAttachments =
          await fetchAttachmentsBySiniestroId(incidentId);
        setAttachments(fetchedAttachments || []);
        setError(null);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Ocurrió un error al cargar los adjuntos");
        }
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    void loadAttachments();
  }, [incidentId]);

  const fileInputRef = useRef(null);

  const handleAttachmentSelection = (attachmentUrl: string) => {
    setOrderedSelectedAttachments((prevSelected) =>
      prevSelected.includes(attachmentUrl)
        ? prevSelected.filter((url) => url !== attachmentUrl)
        : [...prevSelected, attachmentUrl]
    );
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files?.[0]) {
      try {
        setLoading(true);
        const uploadedFile = event.target.files[0];
        const idActionType = 502;
        await uploadAttachment(incidentId, uploadedFile);
        await addAccionToSiniestro(incidentId, idActionType, null, null);
        const fetchedAttachments =
          await fetchAttachmentsBySiniestroId(incidentId);
        setAttachments(fetchedAttachments);
        toast.success("Archivo subido exitosamente");
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Error al subir el archivo");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (attachmentPath: string) => {
    const urlParts = attachmentPath.split("/");
    const relativeKey = urlParts.slice(urlParts.length - 3).join("/");

    try {
      setLoading(true);
      await deleteAttachment(relativeKey);
      setAttachments(
        attachments.filter((attachment) => attachment !== attachmentPath)
      );
      toast.success("Archivo eliminado exitosamente");
      await addAccionToSiniestro(incidentId, 503, null, null);
    } catch (err) {
      setError("Error al eliminar el archivo");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSelectedPDF = async () => {
    setIsDownloading(true);

    try {
      const response = await apiFetch(`/api/pdf/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ imageUrls: orderedSelectedAttachments }),
      });

      if (!response.ok) {
        throw new Error("Error generating PDF");
      }

      const pdfBlob = await response.blob();
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "adjuntos.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Error generating PDF");
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDownloadAllPDF = async () => {
    setIsDownloading(true);

    try {
      const response = await apiFetch(`/api/pdf/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ imageUrls: attachments }),
      });

      if (!response.ok) {
        throw new Error("Error generating PDF");
      }

      const pdfBlob = await response.blob();
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "adjuntos.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Error generating PDF");
    } finally {
      setIsDownloading(false);
    }
  };

  if (loading) return <Spinner />;
  if (error) return <p>Error al cargar adjuntos: {error}</p>;

  return (
    <div>
      <div className="flex items-center justify-between mb-4 mt-4">
        <div className="w-1/3"></div>
        <h2 className="text-xl font-bold text-center w-1/3">Adjuntos</h2>
        <div className="w-1/3 flex justify-end">
          <Button
            asChild
            className="inline-flex items-center justify-center mr-4"
          >
            <label className="cursor-pointer text-sm">
              <IoCloudUpload className="mr-2 h-5 w-5" />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                className="hidden"
              />
              Cargar archivo
            </label>
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4">
        {attachments.map((path) => {
          const isPdf = path.toLowerCase().endsWith(".pdf");
          const fileName = path.split("/").pop();
          const isSelected = orderedSelectedAttachments.includes(path);

          return (
            <Card key={path} className="overflow-hidden">
              <CardContent className="p-2">
                <div className="flex items-center mb-2">
                  <Checkbox
                    id={path}
                    checked={isSelected}
                    onCheckedChange={() => handleAttachmentSelection(path)}
                    className="mr-2"
                  />
                  <label htmlFor={path} className="text-sm truncate">
                    {fileName}
                  </label>
                </div>
                <div className="relative">
                  <a href={path} target="_blank" rel="noopener noreferrer">
                    {isPdf ? (
                      <div className="bg-gray-100 dark:bg-gray-700 p-6 flex flex-col items-center justify-center h-48">
                        <TeenyiconsPdfOutline className="w-16 h-16 text-red-500" />
                        <p className="text-sm text-center text-gray-700 dark:text-gray-300 mt-2">
                          {fileName}
                        </p>
                      </div>
                    ) : (
                      <img
                        className="w-full h-48 object-cover"
                        src={path}
                        alt={`Adjunto ${fileName}`}
                        style={{
                          transform: `rotate(${rotations[path] || 0}deg)`,
                        }}
                      />
                    )}
                  </a>
                  {!hasRole7 && (
                    <div className="absolute top-2 right-2">
                      <DeleteAttachmentDialog
                        onDelete={async () => await handleDelete(path)}
                        attachmentId={path}
                      />
                    </div>
                  )}
                </div>
              </CardContent>
              {!isPdf && (
                <CardFooter className="p-2 flex justify-between items-center">
                  <div className="flex flex-col space-y-2 w-full">
                    <div className="flex justify-between">
                      <Button
                        onClick={() => handleRotate(path, -90)}
                        size="sm"
                        variant="outline"
                      >
                        <RotateCcw className="h-4 w-4" />
                      </Button>
                      <Button
                        onClick={() => handleRotate(path, 90)}
                        size="sm"
                        variant="outline"
                      >
                        <RotateCw className="h-4 w-4" />
                      </Button>
                    </div>
                    {rotations[path] !== undefined && rotations[path] !== 0 && (
                      <Button
                        onClick={() => handleSaveRotation(path)}
                        size="sm"
                        variant="default"
                        disabled={isRotating[path]}
                        className="w-full"
                      >
                        {isRotating[path] ? (
                          <>
                            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                            Guardando...
                          </>
                        ) : (
                          "Guardar rotación"
                        )}
                      </Button>
                    )}
                  </div>
                </CardFooter>
              )}
            </Card>
          );
        })}
      </div>

      <div className="mt-8 bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
          Archivos seleccionados (podés ordenar para exportarlos):
        </h3>
        {orderedSelectedAttachments.length > 0 ? (
          <Reorder.Group
            axis="y"
            values={orderedSelectedAttachments}
            onReorder={setOrderedSelectedAttachments}
            className="space-y-2"
          >
            {orderedSelectedAttachments.map((path) => (
              <Reorder.Item key={path} value={path}>
                <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-md shadow-sm flex items-center justify-between cursor-move hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200">
                  <div className="flex items-center">
                    {path.toLowerCase().endsWith(".pdf") ? (
                      <TeenyiconsPdfOutline className="w-10 h-10 text-red-500 mr-3" />
                    ) : (
                      <img
                        src={path}
                        alt="thumbnail"
                        className="w-10 h-10 object-cover mr-3 rounded"
                      />
                    )}
                    <span className="text-gray-700 dark:text-gray-300">
                      {path.split("/").pop()}
                    </span>
                  </div>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleAttachmentSelection(path)}
                    className="text-red-500 hover:text-red-700 focus:outline-none"
                  >
                    <IoClose className="h-5 w-5" />
                  </Button>
                </div>
              </Reorder.Item>
            ))}
          </Reorder.Group>
        ) : (
          <p className="text-gray-500 dark:text-gray-400 italic">
            No hay archivos seleccionados
          </p>
        )}
      </div>

      {attachments.length === 0 && !error && (
        <p className="text-center p-4">Sin adjuntos</p>
      )}

      <div className="text-right">
        <Button
          onClick={handleDownloadSelectedPDF}
          disabled={isDownloading || orderedSelectedAttachments.length === 0}
          className="inline-flex items-center justify-center px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded"
        >
          {isDownloading ? (
            <Spinner />
          ) : (
            <span>Generar PDF con seleccionados</span>
          )}
        </Button>
        <Button
          onClick={handleDownloadAllPDF}
          disabled={attachments.length === 0 || isDownloading}
          className="my-4 ml-4 mr-4"
        >
          {isDownloading ? (
            <>
              <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
              Generando PDF...
            </>
          ) : (
            "Descargar todos como PDF"
          )}
        </Button>
      </div>
    </div>
  );
};

export default AttachmentsGallery;
