import { useState, useEffect } from "react";
import { getSiniestroById } from "@/services/siniestroService";

const useSiniestroData = (siniestroId: number) => {
  const [siniestro, setSiniestro] = useState<Siniestro | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSiniestroById(siniestroId);
        setSiniestro(data[0]);
      } catch (err) {
        setError("No se pudo cargar la información del siniestro.");
      } finally {
        setIsLoading(false);
      }
    };

    void fetchData();
  }, [siniestroId]);

  const updateSiniestro = (newData: Partial<Siniestro>) => {
    if (siniestro) {
      const updatedSiniestro: Siniestro = {
        ...siniestro,
        ...newData,
      };
      setSiniestro(updatedSiniestro);
    }
  };

  return { siniestro, isLoading, error, updateSiniestro };
};

export default useSiniestroData;
