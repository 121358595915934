/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import { Input } from "@/components/ui/input";
import { MdiPencilBoxMultipleOutline } from "@/components/icons/MdiPencilBoxMultipleOutline";
import { Label } from "@/components/ui/label";
import { updateSiniestroDetails } from "@/services/siniestroService";
import { toast } from "sonner";
import { ButtonLoading } from "@/components/ui/ButtonLoading";
interface EditSumaAseguradaDialogProps {
  siniestro: Siniestro;
  onSiniestroUpdated: (siniestro: Siniestro) => void;
  disabled?: boolean; // Añadir propiedad disabled
}

export const EditSumaAseguradaDialog = ({
  siniestro,
  onSiniestroUpdated,
  disabled = false, // Valor por defecto
}: EditSumaAseguradaDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [coveredValue, setcoveredValue] = useState(siniestro.coveredValue);

  const handleSave = async () => {
    if (disabled) return; // Evitar que se ejecute si está deshabilitado
    setLoading(true);
    try {
      const updatedDetails = { coveredValue };
      const updatedSiniestro = await updateSiniestroDetails(
        siniestro.id,
        updatedDetails,
      );
      onSiniestroUpdated(updatedSiniestro);
      toast.success("Suma asegurada actualizada");
    } catch (error) {
      toast.error("Error al actualizar los detalles del siniestro");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" disabled={disabled}>
          <MdiPencilBoxMultipleOutline className="w-4 h-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editar suma asegurada del siniestro</DialogTitle>
          <DialogDescription>
            Modifica la suma asegurada del siniestro
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Label htmlFor="sumaAsegurada">Suma asegurada</Label>
          <Input
            id="sumaAsegurada"
            type="number"
            value={coveredValue}
            onChange={(e) => setcoveredValue(e.target.value)}
            disabled={disabled} // Deshabilitar el campo si es necesario
          />
        </div>
        <DialogFooter>
          {loading ? (
            <ButtonLoading />
          ) : (
            <Button onClick={handleSave} disabled={disabled}>
              Guardar Cambios
            </Button>
          )}
          <DialogClose asChild>
            <Button variant="secondary" disabled={disabled}>
              Cerrar
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
