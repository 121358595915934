/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useState } from "react";
import { SolarNotebookOutline } from "@/components/icons/SolarNotebookOutline";

interface EditObservacionesDialogProps {
  bienId: number;
  otherObservations: string;
  onSave: (bienId: number, otherObservations: string) => Promise<void>;
  onDetailsSaved: (bienId: number, updatedDetails: any) => void;
  disabled?: boolean;
}

export function EditOtherObservacionesDialog({
  bienId,
  otherObservations,
  onSave,
  onDetailsSaved,
  disabled = false,
}: EditObservacionesDialogProps) {
  const [otherObservacionesInput, setOtherObservacionesInput] =
    useState(otherObservations);
  const [open, setOpen] = useState(false);

  const handleSave = async () => {
    onDetailsSaved(bienId, { otherObservations: otherObservacionesInput });
    await onSave(bienId, otherObservacionesInput);
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="ml-2" disabled={disabled}>
          <SolarNotebookOutline className="w-5 h-5 mr-2" />
          Editar observaciones
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editando observaciones</DialogTitle>
          <DialogDescription>
            Modifica las observaciones aquí y haz clic en guardar cuando
            termines.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Textarea
            id="otherObservacionesInput"
            value={otherObservacionesInput}
            onChange={(e) => {
              setOtherObservacionesInput(e.target.value);
            }}
            className="col-span-3"
          />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button onClick={handleSave}>Guardar cambios</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="secondary">Cerrar</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
