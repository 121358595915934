import React, { useState, useEffect } from "react";
import {
  addGastoSiniestro,
  addIngresoSiniestro,
  deleteGastoSiniestro,
  deleteIngresoSiniestro,
  fetchBienesSiniestro,
  fetchGastosSiniestro,
  fetchHistorialSiniestro,
  fetchIngresosSiniestro,
  updateBienTipoPago,
  updateBienDetails,
  updateBienObservations,
  updateBienResolucion,
  updateGastoSiniestro,
  updateIngresoSiniestro,
  updateBienInstance,
  addAccionToSiniestro,
  duplicateSiniestroAsGarantia,
  deleteCoberturaForIncident,
  updateBienOtherObservations,
} from "@/services/siniestroService";
import { Card, CardContent, CardDescription } from "@/components/ui/card";
import { fetchTipoPagos } from "@/services/tipoPagoService";
import { format, parseISO } from "date-fns";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import Spinner from "../../ui/spinner";
import { MaterialSymbolsHomeWorkOutlineRounded } from "../../icons/MaterialSymbolsHomeWorkOutlineRounded";
import { PhUserFocus } from "../../icons/PhUserFocus";
import { CheckCircleIcon, ChevronDownIcon, UserIcon } from "lucide-react";
import { TablerFileText } from "../../icons/TablerFileText";
import { Separator } from "../../ui/separator";
import { useSiniestroStore } from "@/stores/siniestroStore";
import { fetchResoluciones } from "@/services/resolucionService";
import { toast } from "sonner";
import { EditIngresoDialog } from "../dialogs/EditIngresoDialog";
import { AddIngresoDialog } from "../dialogs/AddIngresoDialog";
import { DeleteIngresoDialog } from "../dialogs/DeleteIngresoDialog";
import { EditGastoDialog } from "../dialogs/EditGastoDialog";
import { DeleteGastoDialog } from "../dialogs/DeleteGastoDialog";
import { AddGastoDialog } from "../dialogs/AddGastoDialog";
import useSiniestroData from "../hooks/useSiniestroData";
import { AseguradoDetails } from "./AseguradoDetails";
import HistorialSiniestro from "./HistorialSiniestro";
import BienesSiniestro from "./BienesSiniestro";
import { fetchInstancias } from "@/services/instanciaService";
import { EditStroDetailsDialog } from "../dialogs/EditStroDetailsDialog";
import { fetchCausas } from "@/services/causaService";
import {
  addFactura,
  deleteFactura,
  fetchFacturaBySiniestroNumber,
} from "@/services/facturaService";
import { AddFacturaDialog } from "../dialogs/AddFacturaDialog";
import { DeleteFacturaDialog } from "@/components/facturacion/dialogs/DeleteFacturaDialog";
import AttachmentsGallery from "./AttachmentsGallery";
import AccionesSiniestro from "./AccionesSiniestro";
import { exportRemitoRetiroToPDF } from "../exports-pdf/exportRemitoRetiroToPDF";
import { BienSelectionRetiroDialog } from "../dialogs/BienSelectionRetiroDialog";
import { BienSelectionRechazoDialog } from "../dialogs/BienSelectionRechazoDialog";
import { BienSelectionEntregaDialog } from "../dialogs/BienSelectionEntregaDialog";
import { exportRemitoEntregaReparadoToPDF } from "../exports-pdf/exportRemitoEntregaReparadoToPDF";
import { exportRemitoEntregaRechazoToPDF } from "../exports-pdf/exportRemitoEntregaRechazoToPDF";
import { InformeTecnicoExportToPDF } from "../exports-pdf/informeTecnicoExportPDF";
import { EditAseguradoDetailsDialog } from "../dialogs/EditAseguradoDetailsDialog";
import { EditAddressDialog } from "../dialogs/EditAdressDialog";
import { EditSumaAseguradaDialog } from "../dialogs/EditSumaAsegurada";
import { InformeTecnicoDialog } from "../dialogs/InformeTecnicoDialog";
import { AddCoberturaDialog } from "../dialogs/AddCoberturaDialog";
import { EditCoberturasDialog } from "../dialogs/EditCoberturasDialog";
import ProveedoresSiniestro from "./ProveedoresSiniestro";
import { BienSelectionDuplicateDialog } from "../dialogs/DuplicateSiniestroDialog";
import { useAuth } from "@/contexts/AuthContext";
import { Button } from "@/components/ui/button";
import RemitosElectroDetails from "@/components/remitos/RemitosElectroDetails";
import { formattedValueARS } from "./formattedValue";
import ConfirmacionDialog from "../dialogs/ConfirmacionDialog";
import { usePredefinedHonorarios } from "@/components/hooks/usePredefinedHonorarios";
import OrdenCompraDialog from "../dialogs/OrdenCompraDialog";
import { FaFileAlt, FaShoppingCart } from "react-icons/fa";
import ChubbConformityDialog from "../conformidades-export/ChubbConformityDialog";
import LaCajaConformityDialog from "../conformidades-export/LaCajaConformityDialog";
import ProvinciaConformityDialog from "../conformidades-export/ProvinciaConformityDialog";
import RioUruguayConformityDialog from "../conformidades-export/RioUruguayConformityDialog";
import RivadaviaConformityDialog from "../conformidades-export/RivadaviaConformityDialog";
import SMGConformityDialog from "../conformidades-export/SMGConformityDialog";
import LifeConformityDialog from "../conformidades-export/LifeConformityDialog";

export const buildAddressString = (siniestro: Siniestro) => {
  const addressParts = [];

  // Concatena calle y número si están disponibles
  if (siniestro.occurrenceStreet && siniestro.occurrenceNumber) {
    addressParts.push(
      `${siniestro.occurrenceStreet} ${siniestro.occurrenceNumber}`
    );
  } else if (siniestro.occurrenceStreet) {
    // Solo calle si no hay número
    addressParts.push(siniestro.occurrenceStreet);
  }

  // Agrega piso y departamento si están disponibles
  if (siniestro.occurrenceFloor) {
    addressParts.push(`Piso: ${siniestro.occurrenceFloor}`);
  }
  if (siniestro.occurrenceApartment) {
    addressParts.push(`Depto: ${siniestro.occurrenceApartment}`);
  }

  // Agrega localidad y provincia si están disponibles
  if (siniestro.occurrenceLocality) {
    addressParts.push(siniestro.occurrenceLocality);
  }
  if (siniestro.occurrenceProvince) {
    addressParts.push(siniestro.occurrenceProvince);
  }

  // Une las partes con comas
  return addressParts.join(", ");
};

interface SiniestroDetailsProps {
  siniestroId: number;
}

export const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString || isNaN(Date.parse(dateString))) {
    return "Fecha no disponible"; // Valor predeterminado para fechas inválidas
  }
  return format(parseISO(dateString), "dd/MM/yyyy");
};

const SiniestroDetails: React.FC<SiniestroDetailsProps> = ({ siniestroId }) => {
  const [historial, setHistorial] = useState<HistorialSiniestro[]>([]);
  const { roles, username } = useAuth();
  const hasRole2 = roles.includes(2);
  const hasRole3 = roles.includes(3);
  const hasRole4 = roles.includes(4);
  const hasRole5 = roles.includes(5);
  const hasRole13 = roles.includes(13);
  const hasRole7 = roles.includes(7);
  const hasRole11 = roles.includes(11);
  const hasRole15 = roles.includes(15);
  const [isLoadingHistorial, setIsLoadingHistorial] = useState(true);
  const [bienes, setBienes] = useState<Bien[]>([]);
  const [isLoadingBienes, setIsLoadingBienes] = useState(true);
  const { siniestros } = useSiniestroStore();
  const [resoluciones, setResoluciones] = useState<Resolucion[]>([]);
  const [facturas, setFacturas] = useState<Factura[]>([]);
  const [instancias, setInstancias] = useState<Instancia[]>([]);
  const [causas, setCausas] = useState<Causa[]>([]);
  const [updatingBien, setUpdatingBien] = useState<Record<number, boolean>>({});
  const [updatingBienInstance, setUpdatingBienInstance] = useState<
    Record<number, boolean>
  >({});
  const [ingresosSiniestro, setIngresosSiniestro] = useState<Ingreso[]>([]);
  const [isLoadingIngresos, setIsLoadingIngresos] = useState(true);
  const [gastosSiniestro, setGastosSiniestro] = useState<Gasto[]>([]);
  const [isLoadingGastos, setIsLoadingGastos] = useState(true);
  const [tipoPagos, setTipoPagos] = useState<TipoPago[]>([]);
  const { siniestro, updateSiniestro, isLoading, error } =
    useSiniestroData(siniestroId);
  console.log(siniestro);
  const [, setSiniestro] = useState(siniestro);
  const [instanciaActual, setInstanciaActual] = useState<
    Record<number, number>
  >({});
  const [resolucionActual, setResolucionActual] = useState<
    Record<number, number>
  >({});
  const isSiniestroCerrado = siniestro?.state_id === 7;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isOrdenCompraDialogOpen, setIsOrdenCompraDialogOpen] = useState(false);
  const { predefinedHonorarios, isLoading: isLoadingHonorarios } =
    usePredefinedHonorarios(siniestro?.company_id ?? 0);
  const [isChubbDialogOpen, setIsChubbDialogOpen] = useState(false);
  const [isLaCajaDialogOpen, setIsLaCajaDialogOpen] = useState(false);
  const [isProvinciaDialogOpen, setIsProvinciaDialogOpen] = useState(false);
  const [isRioUruguayDialogOpen, setIsRioUruguayDialogOpen] = useState(false);
  const [isRivadaviaDialogOpen, setIsRivadaviaDialogOpen] = useState(false);
  const [isSMGDialogOpen, setIsSMGDialogOpen] = useState(false);
  const [isLifeDialogOpen, setIsLifeDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (siniestro) {
      setSiniestro(siniestro);
    }
  }, [siniestro]);

  // Verificación de acceso
  const hasAccess =
    !hasRole11 ||
    (siniestro &&
      siniestro.assignedUsers.some(
        (assignedUser: { id: number; username: string }) =>
          assignedUser.username === username
      ));

  const handleEditIngreso = async (
    ingresoId: number,
    updatedData: { text: string; value: number }
  ) => {
    try {
      await updateIngresoSiniestro(ingresoId, updatedData);
      toast.success("Ingreso actualizado con éxito");
      setIngresosSiniestro((prevIngresos) =>
        prevIngresos.map((ingreso) => {
          if (ingreso.id === ingresoId) {
            return { ...ingreso, ...updatedData };
          }
          return ingreso;
        })
      );
    } catch (error) {
      console.error("Error al actualizar el ingreso:", error);
      toast.error("Error al actualizar el ingreso");
    }
  };

  useEffect(() => {
    const loadFacturasSiniestro = async () => {
      try {
        const response = await fetchFacturaBySiniestroNumber(siniestroId);
        // Asegúrate de que la respuesta contenga la propiedad 'factura' y sea un arreglo
        if (response && Array.isArray(response.factura)) {
          setFacturas(response.factura); // Actualiza el estado con el arreglo de facturas
        } else {
          console.error("Formato de respuesta inesperado:", response);
        }
      } catch (error) {
        console.error("Error loading facturas:", error);
      }
    };

    void loadFacturasSiniestro();
  }, [siniestroId]);

  const calculateIVA = (amount: number) => {
    return amount * 1.21;
  };

  useEffect(() => {
    const loadInstanciasSiniestro = async () => {
      try {
        const fetchedInstancias = await fetchInstancias();
        setInstancias(fetchedInstancias);
      } catch (error) {
        console.error("Error loading instancias:", error);
      }
    };

    void loadInstanciasSiniestro();
  }, []);

  useEffect(() => {
    const loadIngresosSiniestro = async () => {
      setIsLoadingIngresos(true);
      try {
        const fetchedIngresos = await fetchIngresosSiniestro(siniestroId);
        setIngresosSiniestro(fetchedIngresos);
      } catch (error) {
        console.error("Error loading ingresos siniestro:", error);
      } finally {
        setIsLoadingIngresos(false);
      }
    };

    if (siniestro) {
      void loadIngresosSiniestro();
    }
  }, [siniestroId, siniestro]);

  useEffect(() => {
    const loadCausas = async () => {
      try {
        const causasFetched = await fetchCausas();
        setCausas(causasFetched);
      } catch (error) {
        console.error("Error loading causas:", error);
      }
    };

    void loadCausas();
  }, []);

  useEffect(() => {
    const obtenerTipoPagos = async () => {
      try {
        const datosTipoPagos = await fetchTipoPagos();
        setTipoPagos(datosTipoPagos);
      } catch (error) {
        console.error("Error al obtener tipos de pago:", error);
      }
    };

    void obtenerTipoPagos();
  }, []);

  useEffect(() => {
    const loadGastosSiniestro = async () => {
      setIsLoadingGastos(true);
      try {
        const fetchedGastos = await fetchGastosSiniestro(siniestroId);
        setGastosSiniestro(fetchedGastos);
      } catch (error) {
        console.error("Error loading gastos siniestro:", error);
      } finally {
        setIsLoadingGastos(false);
      }
    };
    if (siniestro) {
      void loadGastosSiniestro();
    }
  }, [siniestroId, siniestro]);

  useEffect(() => {
    const cargarResoluciones = async () => {
      try {
        const resolucionesFetched = await fetchResoluciones();
        setResoluciones(resolucionesFetched);
      } catch (error) {
        console.error("Error loading resoluciones:", error);
      }
    };

    void cargarResoluciones();
  }, []);

  useEffect(() => {
    // Función para cargar el historial
    const loadHistorial = async () => {
      setIsLoadingHistorial(true);
      try {
        const fetchedHistorial = await fetchHistorialSiniestro(siniestroId);
        setHistorial(fetchedHistorial);
      } catch (error) {
        console.error("Error loading historial:", error);
      } finally {
        setIsLoadingHistorial(false);
      }
    };

    void loadHistorial();
  }, [siniestroId]);

  useEffect(() => {
    const siniestroActualizado = siniestros.find((s) => s.id === siniestroId);
    if (siniestroActualizado && siniestroActualizado.historial !== historial) {
      setHistorial(siniestroActualizado.historial);
    }
  }, [siniestros, siniestroId, historial]);

  useEffect(() => {
    const loadBienes = async () => {
      setIsLoadingBienes(true);
      try {
        const fetchedBienes = await fetchBienesSiniestro(siniestroId);
        setBienes(fetchedBienes);
      } catch (error) {
        console.error("Error loading bienes:", error);
      } finally {
        setIsLoadingBienes(false);
      }
    };

    void loadBienes();
  }, [siniestroId]);

  const handleHistorialUpdated = (
    updatedHistorial: React.SetStateAction<HistorialSiniestro[]>
  ) => {
    setHistorial(updatedHistorial);
  };

  const handleResolutionChange = async (
    bienId: number,
    newResolutionId: number
  ) => {
    setUpdatingBien((prev) => ({ ...prev, [bienId]: true }));

    try {
      await updateBienResolucion(siniestroId, bienId, newResolutionId);
      const newResolutionName =
        resoluciones.find((r) => r.id === newResolutionId)?.name ??
        "Desconocido";

      // Actualizar el estado local de bienes
      setBienes((prevBienes) =>
        prevBienes.map((bien) => {
          if (bien.id === bienId) {
            let updatedBien = {
              ...bien,
              resolution_id: newResolutionId,
              resolutionName: newResolutionName,
            };

            // Verifica si la resolución es 'Reparado', 'Rechazado', o 'Desistido'
            if (
              ["Reparado", "Rechazado", "Desistido"].includes(newResolutionName)
            ) {
              updatedBien = { ...updatedBien, item_payment_type: null };
            }

            return updatedBien;
          }
          return bien;
        })
      );

      // actualizar estado

      setResolucionActual((prev) => ({
        ...prev,
        [bienId]: newResolutionId,
      }));

      toast.success("Resolución actualizada con éxito");
    } catch (error) {
      console.error("Error al actualizar la resolución:", error);
      toast.error("Error al actualizar la resolución");
    } finally {
      setUpdatingBien((prev) => ({ ...prev, [bienId]: false })); // Finaliza loading para este bien
    }
  };

  const handleInstanceChange = async (
    bienId: number,
    newInstanceId: number
  ) => {
    setUpdatingBienInstance((prev) => ({ ...prev, [bienId]: true }));

    try {
      await updateBienInstance(siniestroId, bienId, newInstanceId);
      // Actualizar la instancia actual en el estado
      setInstanciaActual((prev) => ({
        ...prev,
        [bienId]: newInstanceId,
      }));
      // Determinar idActionType basado en newInstanceId
      let idActionType = -1;
      if (newInstanceId === 4) idActionType = 41;
      else if (newInstanceId === 5) idActionType = 42;
      else if (newInstanceId === 9) idActionType = 45;

      // Llamada a la función addAccionToSiniestro
      if (idActionType !== -1) {
        await addAccionToSiniestro(siniestroId, idActionType, null, null);
      }

      // Obtener el nombre de la nueva instancia
      const newInstanceName =
        instancias.find((i) => i.id === newInstanceId)?.name ?? "Desconocido";

      // Actualizar el estado local de bienes
      setBienes((prevBienes) =>
        prevBienes.map((bien) => {
          if (bien.id === bienId) {
            return {
              ...bien,
              instance_id: newInstanceId,
              instanceName: newInstanceName,
            };
          }
          return bien;
        })
      );

      toast.success("Instancia actualizada con éxito");
    } catch (error) {
      console.error("Error al actualizar la instancia:", error);
      toast.error("Error al actualizar la instancia");
    } finally {
      setUpdatingBienInstance((prev) => ({ ...prev, [bienId]: false }));
    }
  };

  const handleSaveBien = async (
    bienId: number,
    { valorReclamado, valorAReconocer, valorReparado }: SaveBienValues
  ) => {
    try {
      const bienData = {
        claimedValue: valorReclamado,
        recognizedValue: valorAReconocer,
        repairedRecognizedValue: valorReparado,
      };

      await updateBienDetails(bienId, bienData);
      toast.success("Bien actualizado con éxito");

      // Actualiza el estado de bienes para reflejar los cambios
      setBienes((prevBienes) =>
        prevBienes.map((bien) => {
          if (bien.id === bienId) {
            return { ...bien, ...bienData };
          }
          return bien;
        })
      );
    } catch (error) {
      console.error("Error al actualizar el bien:", error);
      toast.error("Error al actualizar el bien");
    }
  };

  const handleSaveObservaciones = async (
    bienId: number,
    observaciones: string
  ) => {
    try {
      await updateBienObservations(bienId, observaciones);
      toast.success("Informe técnico actualizado con éxito");
      await addAccionToSiniestro(siniestroId, 33, null, null);
      // Opcionalmente, actualiza el estado de bienes para reflejar los cambios
      setBienes((prevBienes) =>
        prevBienes.map((bien) => {
          if (bien.id === bienId) {
            return { ...bien, observations: observaciones };
          }
          return bien;
        })
      );
    } catch (error) {
      console.error("Error al actualizar el informe técnico:", error);
      toast.error("Error al actualizar  el informe técnico");
    }
  };

  const handleSaveOtherObservations = async (
    bienId: number,
    observaciones: string
  ) => {
    try {
      await updateBienOtherObservations(bienId, observaciones);
      toast.success("Observaciones actualizadas con éxito");
      await addAccionToSiniestro(siniestroId, 560, null, null);
      setBienes((prevBienes) =>
        prevBienes.map((bien) => {
          if (bien.id === bienId) {
            return { ...bien, otherObservations: observaciones };
          }
          return bien;
        })
      );
    } catch (error) {
      console.error("Error al actualizar las observaciones:", error);
      toast.error("Error al actualizar las observaciones");
    }
  };

  const handleDetailsUpdated = (bienId: number, updatedDetails: any) => {
    setBienes((prevBienes) =>
      prevBienes.map((bien) => {
        if (bien.id === bienId) {
          const updatedBien = {
            ...bien,
            itemType: updatedDetails.item_type || bien.itemType,
            brandName: updatedDetails.brand || bien.brandName,
            segmentName: updatedDetails.segment || bien.segmentName,
            causeName: updatedDetails.cause || bien.causeName,
            ...updatedDetails,
          };
          return updatedBien;
        }
        return bien;
      })
    );
  };

  const handleDeleteCobertura = async (coberturaId: number) => {
    try {
      await deleteCoberturaForIncident(siniestroId, coberturaId);
      toast.success("Cobertura eliminada con éxito");

      // Actualizar el estado de siniestro para reflejar la eliminación
      if (siniestro) {
        const updatedCoberturas = siniestro.coberturas.filter(
          (cobertura: Cobertura) => cobertura.id_cobertura !== coberturaId
        );
        handleSiniestroUpdated({ ...siniestro, coberturas: updatedCoberturas });
      }
    } catch (error) {
      console.error("Error al eliminar la cobertura:", error);
      toast.error("Error al eliminar la cobertura");
    }
  };

  // Función para confirmar el handleDeleteCobertura

  const handleDeleteCoberturaConfirm = (coberturaId: number) => {
    if (
      window.confirm("¿Estás seguro de que deseas eliminar esta cobertura?")
    ) {
      handleDeleteCobertura(coberturaId);
    }
  };

  // Función para actualizar la lista de bienes
  const handleAddBien = (nuevoBien: Bien) => {
    setBienes((prevBienes) => [...prevBienes, nuevoBien]);
  };

  const handleAddIngreso = async (newIngresoData: any) => {
    try {
      const newIngreso = await addIngresoSiniestro(siniestroId, newIngresoData);
      setIngresosSiniestro((prevIngresos) => [...prevIngresos, newIngreso]);
      toast.success("Ingreso agregado con éxito");
    } catch (error) {
      console.error("Error al agregar el ingreso:", error);
      toast.error("Error al agregar el ingreso");
    }
  };

  const handleAddFactura = async (facturaData: FacturaInput) => {
    try {
      const response = await addFactura(facturaData);
      if (response?.success) {
        setFacturas((prevFacturas) => [...prevFacturas, response.data]);
        toast.success("Factura agregada con éxito");
      } else {
        throw new Error(response.message || "Error al agregar la factura");
      }
    } catch (error) {
      throw error;
    }
  };

  // Función para obtener el nombre del tipo de pago por ID
  const getTipoPagoNameById = (tipoPagoId: number): string | undefined => {
    return tipoPagos.find((tipoPago) => tipoPago.id === tipoPagoId)?.name;
  };

  const handleTipoPagoChange = async (
    bienId: number,
    newTipoPagoId: number
  ) => {
    try {
      await updateBienTipoPago(bienId, newTipoPagoId);
      toast.success("Tipo de pago actualizado con éxito");

      const newTipoPagoName = getTipoPagoNameById(newTipoPagoId);

      // Actualizar el estado de bienes local
      setBienes((prevBienes) =>
        prevBienes.map((bien) => {
          if (bien.id === bienId) {
            return {
              ...bien,
              item_payment_type: newTipoPagoId,
              tipoPagoName: newTipoPagoName,
            };
          }
          return bien;
        })
      );
    } catch (error) {
      console.error("Error al actualizar el tipo de pago:", error);
      toast.error("Error al actualizar el tipo de pago");
    }
  };

  const handleDeleteIngreso = async (ingresoId: number) => {
    try {
      await deleteIngresoSiniestro(ingresoId);
      toast.success("Ingreso eliminado con éxito");
      // Actualizar el estado para reflejar la eliminación
      setIngresosSiniestro((prevIngresos) =>
        prevIngresos.filter((ingreso) => ingreso.id !== ingresoId)
      );
    } catch (error) {
      console.error("Error al eliminar el ingreso:", error);
      toast.error("Error al eliminar el ingreso");
    }
  };

  const handleAddGasto = async (newGastoData: any) => {
    try {
      const newGasto = await addGastoSiniestro(siniestroId, newGastoData);
      setGastosSiniestro((prevGastos) => [...prevGastos, newGasto]);
      toast.success("Gasto agregado con éxito");
    } catch (error) {
      console.error("Error al agregar el gasto:", error);
      toast.error("Error al agregar el gasto");
    }
  };

  const handleEditGasto = async (
    gastoId: number,
    updatedData: { text: string; value: number }
  ) => {
    try {
      await updateGastoSiniestro(gastoId, updatedData);
      toast.success("Gasto actualizado con éxito");
      setGastosSiniestro((prevGastos) =>
        prevGastos.map((gasto) => {
          if (gasto.id === gastoId) {
            return { ...gasto, ...updatedData };
          }
          return gasto;
        })
      );
    } catch (error) {
      console.error("Error al actualizar el gasto:", error);
      toast.error("Error al actualizar el gasto");
    }
  };

  const handleDeleteGasto = async (gastoId: number) => {
    try {
      await deleteGastoSiniestro(gastoId);
      toast.success("Gasto eliminado con éxito");
      // Actualizar el estado para reflejar la eliminación
      setGastosSiniestro((prevGastos) =>
        prevGastos.filter((gasto) => gasto.id !== gastoId)
      );
    } catch (error) {
      console.error("Error al eliminar el gasto:", error);
      toast.error("Error al eliminar el gasto");
    }
  };

  const handleDeleteFactura = async (facturaId: number) => {
    try {
      await deleteFactura(facturaId);
      toast.success("Factura eliminada con éxito");
      // Actualizar el estado para reflejar la eliminación
      setFacturas((prevFacturas) =>
        prevFacturas.filter((factura) => factura.id !== facturaId)
      );
      await addAccionToSiniestro(siniestroId, 56, null, null);
    } catch (error) {
      console.error("Error al eliminar la factura:", error);
      toast.error("Error al eliminar la factura");
    }
  };

  if (isLoading) return <Spinner />;
  if (error) return <p>Error: {error}</p>;
  if (!siniestro) return <p>No se encontró el siniestro.</p>;

  const handleGeneratePdf = async (bienesToExport: Bien[]) => {
    await exportRemitoRetiroToPDF(siniestro, bienesToExport);
  };

  const handleGeneratePDFForEntregaReparado = async (
    bienesToExport: Bien[]
  ) => {
    await exportRemitoEntregaReparadoToPDF(siniestro, bienesToExport);
  };

  const handleGeneratePDFForRechazo = async (bienesToExport: Bien[]) => {
    await exportRemitoEntregaRechazoToPDF(siniestro, bienesToExport);
  };

  const handleSiniestroUpdated = (updatedSiniestro: Siniestro) => {
    if (siniestro) {
      updateSiniestro(updatedSiniestro); // Esta línea debe asegurarse de que el estado global se actualice
      setSiniestro(updatedSiniestro); // También puedes actualizar el estado local si es necesario
    }
  };

  const handleDuplicateSiniestro = async (selectedBienes: number[]) => {
    try {
      const newSiniestroId = await duplicateSiniestroAsGarantia(
        siniestroId,
        selectedBienes
      );
      await addAccionToSiniestro(siniestroId, 47, null, null); // Usa el ID original del siniestro
      await addAccionToSiniestro(newSiniestroId, 48, null, null); // Usa el nuevo ID del siniestro
      toast.success("Siniestro en garantía generado con éxito");
    } catch (error) {
      console.error("Error al duplicar el siniestro:", error);
      toast.error("Error al duplicar el siniestro");
    }
  };

  if (isSiniestroCerrado && hasRole11) {
    return <p className="text-center">No tienes acceso a este siniestro</p>;
  }

  if (!hasAccess)
    return <p className="text-center">No tienes acceso a este siniestro</p>;

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4 min-h-[500px]">
        <Card className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
          <CardContent className="p-4">
            <Card className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow">
              <div className="flex items-center mb-4">
                <PhUserFocus className="text-gray-700 mr-2 h-6 w-6 dark:text-gray-300" />
                <h3 className="font-semibold text-gray-700 dark:text-gray-300">
                  Asegurado
                </h3>
                <div className="ml-2">
                  {!hasRole7 && (
                    <EditAseguradoDetailsDialog
                      siniestro={siniestro}
                      onSiniestroUpdated={handleSiniestroUpdated}
                      disabled={isSiniestroCerrado}
                    />
                  )}
                </div>
              </div>
              <AseguradoDetails
                nombre={siniestro.insuredName}
                dni={siniestro.insuredDNI}
                email={siniestro.contactEmail}
                telefono={siniestro.occurrenceTelephone}
                celular={siniestro.occurrenceCellphone}
              />

              <div className="mt-4">
                <div className="flex items-center mb-2">
                  <MaterialSymbolsHomeWorkOutlineRounded className="text-gray-700 mr-2 dark:text-gray-300" />
                  <h3 className="font-semibold text-gray-700 dark:text-gray-300">
                    Dirección del siniestro
                  </h3>
                  <div className="ml-2">
                    {!hasRole7 && (
                      <EditAddressDialog
                        siniestro={siniestro}
                        onSiniestroUpdated={handleSiniestroUpdated}
                        disabled={isSiniestroCerrado}
                      />
                    )}
                  </div>
                </div>
                <div className="pl-8 text-gray-600 dark:text-gray-300">
                  <p>{buildAddressString(siniestro)}</p>
                </div>
              </div>

              {!hasRole7 && !hasRole11 && (
                <div className="mt-4 space-y-2">
                  <h3 className="font-semibold text-gray-700 dark:text-gray-300">
                    Envío de conformidades
                  </h3>
                  <div className="flex space-x-2">
                    <Button
                      variant="conformity"
                      onClick={handleOpenDialog}
                      disabled={isSiniestroCerrado}
                      className="flex items-center"
                    >
                      <FaFileAlt className="mr-2" />
                      Generar texto de conformidad (transf.)
                    </Button>
                    <Button
                      variant="orderRequest"
                      onClick={() => setIsOrdenCompraDialogOpen(true)}
                      className="flex items-center"
                      disabled={isSiniestroCerrado}
                    >
                      <FaShoppingCart className="mr-2" />
                      Generar pedido de OC
                    </Button>
                    {siniestro.companyName === "Chubb" && (
                      <Button onClick={() => setIsChubbDialogOpen(true)}>
                        Generar conformidad PDF (Chubb)
                      </Button>
                    )}
                    {siniestro.companyName === "La Caja" && (
                      <Button onClick={() => setIsLaCajaDialogOpen(true)}>
                        Generar conformidad PDF (La Caja)
                      </Button>
                    )}
                    {siniestro.companyName === "Provincia Seguros" && (
                      <Button onClick={() => setIsProvinciaDialogOpen(true)}>
                        Generar conformidad PDF (Provincia)
                      </Button>
                    )}
                    {siniestro.companyName === "Río Uruguay " && (
                      <Button onClick={() => setIsRioUruguayDialogOpen(true)}>
                        Generar conformidad PDF (Río Uruguay)
                      </Button>
                    )}
                    {siniestro.companyName === "Rivadavia Seguros" && (
                      <Button onClick={() => setIsRivadaviaDialogOpen(true)}>
                        Generar conformidad PDF (Rivadavia)
                      </Button>
                    )}
                    {siniestro.companyName === "SMG SEGUROS" && (
                      <Button onClick={() => setIsSMGDialogOpen(true)}>
                        Generar conformidad PDF (SMG)
                      </Button>
                    )}
                    {siniestro.companyName === "Life Seguros" && (
                      <Button onClick={() => setIsLifeDialogOpen(true)}>
                        Generar conformidad PDF (Life Seguros)
                      </Button>
                    )}
                  </div>
                </div>
              )}

              {isLifeDialogOpen && (
                <LifeConformityDialog
                  onClose={() => setIsLifeDialogOpen(false)}
                  siniestro={siniestro}
                />
              )}

              {isSMGDialogOpen && (
                <SMGConformityDialog
                  onClose={() => setIsSMGDialogOpen(false)}
                  siniestro={siniestro}
                />
              )}

              {isRivadaviaDialogOpen && (
                <RivadaviaConformityDialog
                  onClose={() => setIsRivadaviaDialogOpen(false)}
                  siniestro={siniestro}
                />
              )}

              {isRioUruguayDialogOpen && (
                <RioUruguayConformityDialog
                  onClose={() => setIsRioUruguayDialogOpen(false)}
                  siniestro={siniestro}
                />
              )}

              {isProvinciaDialogOpen && (
                <ProvinciaConformityDialog
                  onClose={() => setIsProvinciaDialogOpen(false)}
                  siniestro={siniestro}
                />
              )}

              {isLaCajaDialogOpen && (
                <LaCajaConformityDialog
                  onClose={() => setIsLaCajaDialogOpen(false)}
                  siniestro={siniestro}
                />
              )}

              {isChubbDialogOpen && (
                <ChubbConformityDialog
                  onClose={() => setIsChubbDialogOpen(false)}
                  siniestro={siniestro}
                />
              )}

              {isOrdenCompraDialogOpen && (
                <OrdenCompraDialog
                  asegurado={siniestro.insuredName}
                  poliza={siniestro.policyNumber}
                  siniestro={siniestro.companyIncidentNumber}
                  dni={siniestro.insuredDNI}
                  compania={siniestro.companyName}
                  tramitadorCompania={siniestro.companyResponsible}
                  bienes={bienes}
                  onClose={() => setIsOrdenCompraDialogOpen(false)}
                />
              )}

              {isDialogOpen && (
                <ConfirmacionDialog
                  bienes={bienes.map((bien) => ({
                    id: bien.id,
                    type: bien.itemType,
                    brand: bien.brandName,
                  }))}
                  onClose={handleCloseDialog}
                  sumaAsegurada={siniestro.coveredValue}
                  coberturas={siniestro.coberturas}
                />
              )}
            </Card>

            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="flex items-center space-x-4 rounded-md border p-4 dark:border-gray-600">
                {siniestro.coveredValue === 1 ? (
                  <ChevronDownIcon className="h-5 w-5" />
                ) : (
                  <CheckCircleIcon className="h-5 w-5" />
                )}
                <div className="flex-1">
                  <div className="flex items-center">
                    <p className="text-sm font-medium dark:text-gray-300 mr-2">
                      {siniestro.coveredValue === 1
                        ? "Coberturas debajo"
                        : "Suma asegurada"}
                    </p>
                    {!hasRole7 && (
                      <EditSumaAseguradaDialog
                        siniestro={siniestro}
                        onSiniestroUpdated={handleSiniestroUpdated}
                        disabled={isSiniestroCerrado}
                      />
                    )}
                  </div>
                  {siniestro.coveredValue !== 1 && (
                    <p className="text-sm text-muted-foreground dark:text-gray-400">
                      {formattedValueARS(siniestro.coveredValue)}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex items-center space-x-4 rounded-md border p-4 dark:border-gray-600">
                <TablerFileText />

                <div className="flex-1">
                  <p className="text-sm font-medium dark:text-gray-300">
                    Estado
                  </p>
                  <p className="text-sm text-muted-foreground dark:text-gray-400">
                    {siniestro.stateName}
                  </p>
                  {siniestro.stateName === "Cerrado" && (
                    <p className="text-sm text-muted-foreground dark:text-gray-400">
                      <Separator className="my-1" />
                      Fecha de cierre: {formatDate(siniestro.closedAt)}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center space-x-4 rounded-md border p-4 dark:border-gray-600">
                <UserIcon />
                <div className="flex-1">
                  <p className="text-sm font-medium dark:text-gray-300">
                    Tramitador Tu Service
                  </p>
                  <p className="text-sm text-muted-foreground dark:text-gray-400">
                    {siniestro.responsibleName}
                  </p>
                </div>
              </div>
            </div>
          </CardContent>

          <CardContent className="space-y-3">
            <CardDescription className="text-gray-600 dark:text-gray-300">
              <span className="font-semibold">Coberturas de la póliza:</span>
              {!hasRole7 && (
                <AddCoberturaDialog
                  incidentId={siniestroId}
                  handleSiniestroUpdated={handleSiniestroUpdated}
                  siniestro={siniestro}
                  disabled={isSiniestroCerrado}
                />
              )}
              {!hasRole7 &&
                siniestro.coberturas &&
                siniestro.coberturas.length > 0 && (
                  <EditCoberturasDialog
                    incidentId={siniestroId}
                    onSiniestroUpdated={handleSiniestroUpdated}
                    siniestro={siniestro}
                    disabled={false} // Controla si el botón está deshabilitado, en este caso no
                    isEditingDisabled={isSiniestroCerrado} // Deshabilita la edición si el siniestro está cerrado
                  />
                )}
              <ul className="mt-2">
                {siniestro.coberturas && siniestro.coberturas.length > 0 ? (
                  siniestro.coberturas.map(
                    (cobertura: Cobertura, index: number) => (
                      <li
                        key={index}
                        className="flex items-center justify-between"
                      >
                        <span>
                          {cobertura.nombre_cobertura} - Suma Asegurada:{" "}
                          {formattedValueARS(cobertura.suma_poliza)} -{" "}
                          {cobertura.observaciones}
                        </span>
                        {!hasRole7 && (
                          <Button
                            variant="outline"
                            size="sm"
                            className="ml-2"
                            disabled={isSiniestroCerrado}
                            onClick={() =>
                              handleDeleteCoberturaConfirm(
                                cobertura.id_cobertura
                              )
                            }
                          >
                            Eliminar
                          </Button>
                        )}
                      </li>
                    )
                  )
                ) : (
                  <p>No hay coberturas disponibles.</p>
                )}
              </ul>
            </CardDescription>

            <Separator className="m-2" />
            <div className="flex items-center">
              <p className="font-semibold text-gray-700 dark:text-gray-300">
                Detalles del siniestro
              </p>
              <div className="ml-2">
                {!hasRole7 && !hasRole11 && (
                  <EditStroDetailsDialog
                    siniestro={siniestro}
                    onSiniestroUpdated={handleSiniestroUpdated}
                    disabled={isSiniestroCerrado}
                  />
                )}
              </div>
            </div>

            <CardDescription className="text-gray-600 dark:text-gray-300">
              <span className="font-semibold">Compañía aseguradora:</span>{" "}
              {siniestro.companyName}{" "}
            </CardDescription>
            <CardDescription className="text-gray-600 dark:text-gray-300">
              <span className="font-semibold">Nº de Siniestro:</span>{" "}
              {siniestro.companyIncidentNumber}
            </CardDescription>
            <CardDescription className="text-gray-600 dark:text-gray-300">
              <span className="font-semibold">Nº de Póliza:</span>{" "}
              {siniestro.policyNumber}
            </CardDescription>
            <CardDescription className="text-gray-600 dark:text-gray-300">
              <span className="font-semibold">Tipo de póliza:</span>{" "}
              {siniestro.policyType}
            </CardDescription>
            <CardDescription className="text-gray-600 dark:text-gray-300">
              <span className="font-semibold">Observaciones de la póliza:</span>{" "}
              {siniestro.policyObservations}
            </CardDescription>
            <CardDescription className="text-gray-600 dark:text-gray-300">
              <span className="font-semibold">Fecha de ocurrencia:</span>{" "}
              {formatDate(siniestro.ocurrenceDatetime)}
            </CardDescription>
            <CardDescription className="text-gray-600 dark:text-gray-300">
              <span className="font-semibold">Fecha de denuncia:</span>{" "}
              {formatDate(siniestro.denunciationDatetime)}
            </CardDescription>
            <CardDescription className="text-gray-600 dark:text-gray-300">
              <span className="font-semibold">Descripción del siniestro:</span>{" "}
              {siniestro.ocurrenceDescription}
            </CardDescription>
            <CardDescription className="text-gray-600 dark:text-gray-300">
              <span className="font-semibold">Tramitador de la compañía:</span>{" "}
              {siniestro.companyResponsible}
            </CardDescription>
          </CardContent>

          {!(hasRole11 || hasRole7) && (
            <InformeTecnicoDialog
              siniestro={siniestro}
              bienes={bienes}
              ingresosSiniestro={ingresosSiniestro}
              InformeTecnicoExportToPDF={InformeTecnicoExportToPDF}
            />
          )}

          <div className="m-4 flex flex-col items-start">
            <div className="flex-grow-0 flex-shrink-0 mb-2 ml-2">
              {!hasRole7 && (
                <BienSelectionRetiroDialog
                  bienes={bienes}
                  siniestro={siniestro}
                  onGeneratePdf={handleGeneratePdf}
                />
              )}
            </div>
            <div className="flex-grow-0 flex-shrink-0 mb-2 ml-2">
              {!hasRole7 && (
                <BienSelectionEntregaDialog
                  bienes={bienes}
                  onGeneratePdf={handleGeneratePDFForEntregaReparado}
                />
              )}
            </div>
            <div className="flex-grow-0 flex-shrink-0 mb-2 ml-2">
              {!hasRole7 && (
                <BienSelectionRechazoDialog
                  bienes={bienes}
                  onGeneratePdf={handleGeneratePDFForRechazo}
                />
              )}
            </div>
            <div className="flex-grow-0 flex-shrink-0 mb-2 ml-2">
              {!(hasRole11 || hasRole7) && (
                <BienSelectionDuplicateDialog
                  bienes={bienes}
                  onDuplicateSiniestro={handleDuplicateSiniestro}
                />
              )}
            </div>
            {(hasRole2 || hasRole3 || hasRole4 || hasRole5 || hasRole13) && (
              <RemitosElectroDetails
                incidentId={siniestroId}
                disabled={isSiniestroCerrado}
              />
            )}
          </div>
        </Card>

        <Card className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden min-h-[500px] min-w-[300px]">
          <Tabs defaultValue="historial" className="w-full">
            <TabsList
              className={`grid w-full text-center ${
                hasRole2 || hasRole15
                  ? "grid-cols-6"
                  : hasRole11 || hasRole15
                    ? "grid-cols-4"
                    : hasRole7
                      ? "grid-cols-3"
                      : "grid-cols-5"
              }`}
            >
              <TabsTrigger value="historial">Agenda</TabsTrigger>
              <TabsTrigger value="bienes">Bienes</TabsTrigger>
              {!hasRole11 && !hasRole7 && (
                <TabsTrigger value="proveedores">Proveedores</TabsTrigger>
              )}
              {(hasRole11 || hasRole2 || hasRole15) && (
                <TabsTrigger value={hasRole11 ? "gastos" : "honorarios"}>
                  {hasRole11 ? "Gastos" : "Honorarios"}
                </TabsTrigger>
              )}
              <TabsTrigger value="adjuntos">Adjuntos</TabsTrigger>
              {!hasRole11 && !hasRole7 && (
                <TabsTrigger value="acciones">Acciones</TabsTrigger>
              )}
            </TabsList>

            <TabsContent value="historial">
              <HistorialSiniestro
                historial={historial}
                isLoadingHistorial={isLoadingHistorial}
                siniestroId={siniestroId}
                onHistorialUpdated={handleHistorialUpdated}
                hasRole7={hasRole7}
              />
            </TabsContent>

            <TabsContent value="proveedores">
              <ProveedoresSiniestro
                siniestroId={siniestroId}
                companyIncidentNumber={siniestro.companyIncidentNumber}
              />
            </TabsContent>

            <TabsContent value="acciones">
              <AccionesSiniestro siniestroId={siniestroId} />
            </TabsContent>

            <TabsContent value="bienes">
              <BienesSiniestro
                bienes={bienes}
                isLoadingBienes={isLoadingBienes}
                onUpdateBien={handleSaveBien}
                onResolutionChange={handleResolutionChange}
                onSaveObservaciones={handleSaveObservaciones}
                updatingBien={updatingBien}
                updatingBienInstance={updatingBienInstance}
                resoluciones={resoluciones}
                resolucionActual={resolucionActual}
                tiposPago={tipoPagos}
                onTipoPagoChange={handleTipoPagoChange}
                causas={causas}
                onDetailsUpdated={handleDetailsUpdated}
                siniestroId={siniestroId}
                onAddBien={handleAddBien}
                onInstanceChange={handleInstanceChange}
                instancias={instancias}
                instanciaActual={instanciaActual}
                onOtherObservationsChange={handleSaveOtherObservations}
                sumaAsegurada={siniestro.coveredValue}
                coberturas={siniestro.coberturas}
                disabled={isSiniestroCerrado}
              />
            </TabsContent>

            {hasRole11 ? (
              <TabsContent value="gastos">
                <CardContent className="p-4">
                  <Card className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow">
                    <div className="flex items-center mb-4 justify-between">
                      <h3 className="font-semibold text-gray-700 dark:text-gray-300 mb-4">
                        Gastos del siniestro:
                      </h3>
                      <AddGastoDialog
                        onSave={handleAddGasto}
                        siniestroId={siniestroId}
                      />
                    </div>
                    <div className="space-y-4">
                      {isLoadingGastos ? (
                        <Spinner />
                      ) : (
                        gastosSiniestro.map((gasto) => (
                          <div
                            key={gasto.id}
                            className="bg-white dark:bg-gray-800 p-3 rounded-lg shadow flex items-center justify-between"
                          >
                            <div className="flex flex-1">
                              <p className="text-gray-700 dark:text-gray-300 flex-1">
                                {gasto.text}
                              </p>
                            </div>
                            <div className="flex items-center space-x-3">
                              <p className="text-gray-700 dark:text-gray-300">
                                {formattedValueARS(gasto.value)}
                              </p>
                              <EditGastoDialog
                                gastoId={gasto.id}
                                text={gasto.text}
                                value={gasto.value}
                                onSave={handleEditGasto}
                              />
                              <DeleteGastoDialog
                                onDelete={handleDeleteGasto}
                                gastoId={gasto.id}
                                gastoText={gasto.text}
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </Card>
                </CardContent>
              </TabsContent>
            ) : (
              <TabsContent value="honorarios">
                <CardContent className="p-4">
                  <Card className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow">
                    <div className="flex items-center mb-4 justify-between">
                      <h3 className="font-semibold text-gray-700 dark:text-gray-300 mb-4">
                        Honorarios del siniestro:
                      </h3>
                      {isLoadingHonorarios ? (
                        <Spinner />
                      ) : (
                        <AddIngresoDialog
                          onSave={handleAddIngreso}
                          disabled={isSiniestroCerrado}
                          predefinedHonorarios={predefinedHonorarios}
                        />
                      )}
                    </div>
                    <div className="space-y-4">
                      {isLoadingIngresos ? (
                        <Spinner />
                      ) : (
                        ingresosSiniestro.map((ingreso) => (
                          <div
                            key={ingreso.id}
                            className="bg-white dark:bg-gray-800 p-3 rounded-lg shadow flex items-center justify-between"
                          >
                            <div className="flex flex-1">
                              <p className="text-gray-700 dark:text-gray-300 flex-1">
                                {ingreso.text}
                              </p>
                            </div>
                            <div className="flex items-center space-x-3">
                              <p className="text-gray-700 dark:text-gray-300">
                                {formattedValueARS(ingreso.value)}
                              </p>
                              <EditIngresoDialog
                                ingresoId={ingreso.id}
                                text={ingreso.text}
                                value={ingreso.value}
                                onSave={handleEditIngreso}
                                disabled={isSiniestroCerrado}
                              />
                              <DeleteIngresoDialog
                                onDelete={handleDeleteIngreso}
                                ingresoId={ingreso.id}
                                ingresoText={ingreso.text}
                                disabled={isSiniestroCerrado}
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </Card>

                  <Card className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow mt-8">
                    <div className="flex items-center mb-4 justify-between">
                      <h3 className="font-semibold text-gray-700 dark:text-gray-300 mb-4">
                        Gastos del siniestro:
                      </h3>
                      <AddGastoDialog
                        onSave={handleAddGasto}
                        siniestroId={siniestroId}
                        disabled={isSiniestroCerrado}
                      />
                    </div>
                    <div className="space-y-4">
                      {isLoadingGastos ? (
                        <Spinner />
                      ) : (
                        gastosSiniestro.map((gasto) => (
                          <div
                            key={gasto.id}
                            className="bg-white dark:bg-gray-800 p-3 rounded-lg shadow flex items-center justify-between"
                          >
                            <div className="flex flex-1">
                              <p className="text-gray-700 dark:text-gray-300 flex-1">
                                {gasto.text}
                              </p>
                            </div>
                            <div className="flex items-center space-x-3">
                              <p className="text-gray-700 dark:text-gray-300">
                                {formattedValueARS(gasto.value)}
                              </p>
                              <EditGastoDialog
                                gastoId={gasto.id}
                                text={gasto.text}
                                value={gasto.value}
                                onSave={handleEditGasto}
                                disabled={isSiniestroCerrado}
                              />
                              <DeleteGastoDialog
                                onDelete={handleDeleteGasto}
                                gastoId={gasto.id}
                                gastoText={gasto.text}
                                disabled={isSiniestroCerrado}
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </Card>

                  <Card className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow mt-8">
                    <div className="flex items-center mb-4 justify-between">
                      <h3 className="font-semibold text-gray-700 dark:text-gray-300 mb-4">
                        Facturas del siniestro:
                      </h3>
                      <AddFacturaDialog
                        onSave={handleAddFactura}
                        incidentId={siniestroId}
                        disabled={isSiniestroCerrado}
                      />
                    </div>
                    <div>
                      <div className="bg-white mb-2 dark:bg-gray-800 p-3 rounded-lg shadow grid grid-cols-4 gap-4">
                        <p className="text-gray-700 dark:text-gray-300 font-semibold">
                          Nº de FC
                        </p>
                        <p className="text-gray-700 dark:text-gray-300 font-semibold">
                          Monto
                        </p>
                        <p className="text-gray-700 dark:text-gray-300 font-semibold">
                          Monto c/ IVA
                        </p>
                      </div>
                      {facturas.map((factura, index) => (
                        <div
                          key={index}
                          className="bg-white dark:bg-gray-800 p-3 rounded-lg shadow grid grid-cols-4 items-center mb-2"
                        >
                          <p className="text-gray-700 dark:text-gray-300">
                            {factura.number}
                          </p>
                          <p className="text-gray-700 dark:text-gray-300">
                            {formattedValueARS(factura.amount)}
                          </p>
                          <p className="text-gray-700 dark:text-gray-300">
                            {formattedValueARS(calculateIVA(factura.amount))}
                          </p>
                          <div className="flex justify-end">
                            <DeleteFacturaDialog
                              facturaId={factura.id}
                              facturaNumber={factura.number}
                              onDelete={handleDeleteFactura}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </Card>
                </CardContent>
              </TabsContent>
            )}

            <TabsContent value="adjuntos">
              <AttachmentsGallery incidentId={siniestroId} />
            </TabsContent>
          </Tabs>
        </Card>
      </div>
    </>
  );
};

export default SiniestroDetails;
