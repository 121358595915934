import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fetchParentableCompanies } from "@/services/companyService";
import { useEffect, useState } from "react";

interface CompanyFilterProps {
  onChange: (value: string | undefined) => void;
  selectedValue?: string;
}

export const CompanyFilter: React.FC<CompanyFilterProps> = ({
  selectedValue,
  onChange,
}) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>(
    selectedValue,
  );

  useEffect(() => {
    const fetchAndSetCompanies = async () => {
      const fetchedCompanies = await fetchParentableCompanies();
      setCompanies(fetchedCompanies);
    };

    fetchAndSetCompanies();
  }, []);

  return (
    <Select
      value={selectedCompany?.toString()}
      onValueChange={(value) => {
        const selectedValue = value === "all" ? undefined : value;
        setSelectedCompany(selectedValue);
        onChange(selectedValue);
      }}
    >
      <SelectTrigger className="w-[130px]">
        <SelectValue placeholder="Compañías">
          {companies.find(
            (company) => company.id.toString() === selectedCompany,
          )?.name || "Compañías"}
        </SelectValue>
      </SelectTrigger>
      <SelectContent className="max-h-[250px] overflow-auto">
        <SelectGroup>
          <SelectLabel>Compañías</SelectLabel>
          <SelectItem key="all" value="all">
            Todas
          </SelectItem>
          {companies.map((company) => (
            <SelectItem key={company.id} value={company.id.toString()}>
              {company.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
