import { create } from "zustand";
import { apiFetch } from "@/services/siniestroService";

interface NotificationState {
  unreadCount: number;
  setUnreadCount: (count: number) => void;
  startFetchingUnreadCount: (username: string) => void;
  stopFetchingUnreadCount: () => void;
  resetUnreadCount: () => void;
  fetchInitialUnreadCount: (username: string) => Promise<void>;
}

let intervalId: NodeJS.Timeout | null = null;

const useNotificationStore = create<NotificationState>((set, get) => ({
  unreadCount: 0,
  setUnreadCount: (count) => {
    set({ unreadCount: count });
  },
  startFetchingUnreadCount: (username) => {
    const fetchUnreadCount = async () => {
      if (!username) return;
      try {
        const response = await apiFetch(
          `/api/notificaciones/${username}/unread-count`
        );
        if (response.ok) {
          const data = await response.json();
          get().setUnreadCount(data.unreadCount);
        } else {
          console.error("Failed to fetch unread count:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching unread count:", error);
      }
    };

    fetchUnreadCount(); // Fetch initially
    intervalId = setInterval(fetchUnreadCount, 60000);
  },

  stopFetchingUnreadCount: () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  },
  resetUnreadCount: () => set({ unreadCount: 0 }),
  fetchInitialUnreadCount: async (username) => {
    if (!username) return Promise.resolve();
    try {
      const response = await apiFetch(
        `/api/notificaciones/${username}/unread-count`
      );
      if (response.ok) {
        const data = await response.json();
        get().setUnreadCount(data.unreadCount);
      }
    } catch (error) {
      console.error("Error fetching initial unread notifications:", error);
    }
    return Promise.resolve(); // Asegúrate de que la función devuelva una Promise
  },
}));

export default useNotificationStore;
