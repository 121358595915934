/* eslint-disable @typescript-eslint/no-misused-promises */
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "../ui/button";
import { toast } from "sonner";
import useLoadingStore from "@/stores/loadingStore";
import { ReloadIcon } from "@radix-ui/react-icons";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from "../ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { createUser } from "@/services/userService";
import { IcBaselineAddCircle } from "../icons/IcBaselineAddCircle";
import useUserStore from "@/stores/userStore";

export const formSchema = z.object({
  username: z
    .string()
    .trim()
    .min(2, {
      message: "El nombre de usuario debe tener al menos 2 caracteres.",
    })
    .refine((value) => /^[^\s]+$/.test(value), {
      message: "El nombre de usuario no debe contener espacios.",
    }),
  password: z.string().trim().min(8, {
    message: "La contraseña debe tener al menos 8 caracteres.",
  }),
  email: z.string().trim().email({
    message: "Ingrese un email válido.",
  }),
  display_name: z.string().trim().min(2, {
    message: "El nombre a mostrar debe tener al menos 2 caracteres.",
  }),
  state: z.boolean(),
  company_id: z.string(),
});

export function AddUserDialog() {
  const closeDialogRef = useRef<HTMLButtonElement | null>(null);
  const { fetchUsers } = useUserStore();
  const { isSaving, startSaving, stopSaving } = useLoadingStore();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
      email: "",
      display_name: "",
      state: true,
      company_id: "NULL",
    },
  });

  async function onSubmit(values: any) {
    startSaving(); // Activa el indicador de carga
    try {
      await createUser(values);
      toast.success("Usuario creado con éxito.");
      form.reset();
      closeDialogRef.current?.click();
      await fetchUsers();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        // Puedes manejar otros tipos de errores aquí o simplemente mostrar un mensaje genérico.
        toast.error("Ocurrió un error desconocido.");
      }
    } finally {
      stopSaving(); // Detiene el indicador de carga
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded flex items-center w-full sm:w-auto">
          <IcBaselineAddCircle className="mr-2 h-4 w-4" />
          Agregar usuario
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-center">Agregar usuario</DialogTitle>
          <DialogDescription className="text-center">
            Ingrese los detalles del nuevo usuario a continuación.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            {/* Campo para username */}
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nombre de usuario</FormLabel>
                  <FormControl>
                    <Input placeholder="Ingrese nombre de usuario" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Campo para password */}
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Contraseña</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="Ingrese contraseña"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Campo para email */}
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Ingrese email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Campo para display_name */}
            <FormField
              control={form.control}
              name="display_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nombre a mostrar</FormLabel>
                  <FormControl>
                    <Input placeholder="Ingrese nombre a mostrar" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="mt-4">
              <Button type="submit" className="mt-2" disabled={isSaving}>
                {isSaving ? (
                  <>
                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                    Guardando...
                  </>
                ) : (
                  "Guardar"
                )}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
      <DialogClose asChild ref={closeDialogRef}>
        <Button type="button" variant="secondary" className="hidden">
          Close
        </Button>
      </DialogClose>
    </Dialog>
  );
}
