/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { MdiPencilBoxMultipleOutline } from "@/components/icons/MdiPencilBoxMultipleOutline";
import { Label } from "@/components/ui/label";
import {
  addAccionToSiniestro,
  updateSiniestroDetails,
} from "@/services/siniestroService";
import { toast } from "sonner";
import { ButtonLoading } from "@/components/ui/ButtonLoading";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fetchCompanies } from "@/services/companyService";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import { formatDate } from "../SiniestroDetails/SiniestroDetails";

interface EditStroDetailsDialogProps {
  siniestro: Siniestro;
  onSiniestroUpdated: (siniestro: Siniestro) => void;
  disabled?: boolean;
}

export const EditStroDetailsDialog = ({
  siniestro,
  onSiniestroUpdated,
  disabled = false,
}: EditStroDetailsDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({
    id: siniestro.company_id,
    name: siniestro.companyName,
  });
  const [companyIncidentNumber, setcompanyIncidentNumber] = useState(
    siniestro.companyIncidentNumber
  );
  const [policyNumber, setPolicyNumber] = useState(siniestro.policyNumber);
  const [policyType, setPolicyType] = useState(siniestro.policyType);
  const [policyObservations, setpolicyObservations] = useState(
    siniestro.policyObservations
  );
  const [ocurrenceDatetime, setocurrenceDatetime] = useState(
    siniestro.ocurrenceDatetime
  );
  const [denunciationDatetime, setdenunciationDatetime] = useState(
    siniestro.denunciationDatetime
  );
  const [ocurrenceDescription, setocurrenceDescription] = useState(
    siniestro.ocurrenceDescription
  );
  const [companyResponsible, setcompanyResponsible] = useState(
    siniestro.companyResponsible
  );
  const [companies, setCompanies] = useState<Company[]>([]);

  useEffect(() => {
    const getCompanias = async () => {
      const companias = await fetchCompanies();
      setCompanies(companias);
    };

    void getCompanias();
  }, []);

  useEffect(() => {
    const getCompanies = async () => {
      const companias = await fetchCompanies();
      setCompanies(companias);

      const currentCompany = companias.find(
        (company: { id: any }) => company.id === siniestro.company_id
      );
      if (currentCompany) {
        setCompany(currentCompany);
      }
    };

    void getCompanies();
  }, []);

  const handleSave = async () => {
    setLoading(true);
    try {
      const updatedDetails = {
        company_id: company.id,
        companyName: company.name,
        companyIncidentNumber,
        policyNumber,
        policyType,
        policyObservations,
        ocurrenceDatetime,
        denunciationDatetime,
        ocurrenceDescription,
        companyResponsible,
      };
      const updatedSiniestro = await updateSiniestroDetails(
        siniestro.id,
        updatedDetails
      );
      onSiniestroUpdated(updatedSiniestro);

      // Registrar la acción 24
      await addAccionToSiniestro(siniestro.id, 24, null, null);

      toast.success("Detalles del siniestro actualizados con éxito");
    } catch (error: any) {
      // Imprime el mensaje de error para depuración
      console.error("Error capturado:", error.message);

      if (
        error.message.includes(
          "Un siniestro con el mismo número de la compañía ya existe para esta compañía"
        )
      ) {
        toast.error(
          "Ya existe un siniestro con el mismo número de incidente en esta compañía. Por favor, use un número diferente."
        );
      } else if (error.message.includes("Siniestro no encontrado")) {
        toast.error("No se pudo encontrar el siniestro especificado.");
      } else {
        toast.error("Error al actualizar los detalles del siniestro");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" disabled={disabled}>
          <MdiPencilBoxMultipleOutline className="w-4 h-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="w-[600px] h-[600px] overflow-auto">
        <DialogHeader>
          <DialogTitle>Editar detalles del siniestro</DialogTitle>
          <DialogDescription>
            Modifica los detalles del siniestro
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Label htmlFor="company">Compañía</Label>
          <Select
            value={company.name}
            onValueChange={(value) => {
              const newSelectedCompany = companies.find(
                (company) => company.name === value
              );
              if (newSelectedCompany) {
                setCompany({
                  id: newSelectedCompany.id,
                  name: newSelectedCompany.name,
                });
              }
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Selecciona una compañía" />
            </SelectTrigger>
            <SelectContent className="max-h-[350px] overflow-auto">
              {companies.map((company) => (
                <SelectItem key={company.id} value={company.name}>
                  {company.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Label htmlFor="stroNumber">Número de siniestro</Label>
          <Input
            id="stroNumber"
            type="text"
            value={companyIncidentNumber}
            onChange={(e) => {
              setcompanyIncidentNumber(e.target.value);
            }}
          />

          <Label htmlFor="policyNumber">Número de póliza</Label>
          <Input
            id="policyNumber"
            type="text"
            value={policyNumber}
            onChange={(e) => {
              setPolicyNumber(e.target.value);
            }}
          />

          <Label htmlFor="policyType">Tipo de póliza</Label>
          <Input
            id="policyType"
            type="text"
            value={policyType}
            onChange={(e) => {
              setPolicyType(e.target.value);
            }}
          />

          <Label htmlFor="policyObservations">Observaciones de la póliza</Label>
          <Input
            id="policyObservations"
            type="text"
            value={policyObservations}
            onChange={(e) => {
              setpolicyObservations(e.target.value);
            }}
          />

          <Label htmlFor="ocurrenceDatetime">Fecha y Hora de Ocurrencia</Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline" className="w-full justify-between">
                {formatDate(ocurrenceDatetime) || "Seleccionar fecha"}
                <CalendarIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={
                  ocurrenceDatetime ? new Date(ocurrenceDatetime) : undefined
                }
                onSelect={(date) => {
                  if (date) {
                    setocurrenceDatetime(date.toISOString());
                  }
                }}
              />
            </PopoverContent>
          </Popover>

          <Label htmlFor="denunciationDatetime">Fecha de denuncia</Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline" className="w-full justify-between">
                {formatDate(denunciationDatetime) || "Seleccionar fecha"}
                <CalendarIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={
                  denunciationDatetime
                    ? new Date(denunciationDatetime)
                    : undefined
                }
                onSelect={(date) => {
                  if (date) {
                    setdenunciationDatetime(date.toISOString());
                  }
                }}
              />
            </PopoverContent>
          </Popover>

          <Label htmlFor="ocurrenceDescription">
            Descripción de la ocurrencia
          </Label>
          <Input
            id="ocurrenceDescription"
            type="text"
            value={ocurrenceDescription}
            onChange={(e) => {
              setocurrenceDescription(e.target.value);
            }}
          />

          <Label htmlFor="companyResponsible">Tramitador de la compañía</Label>
          <Input
            id="companyResponsible"
            type="text"
            value={companyResponsible}
            onChange={(e) => {
              setcompanyResponsible(e.target.value);
            }}
          />
        </div>
        <DialogFooter>
          {loading ? (
            <ButtonLoading />
          ) : (
            <Button onClick={handleSave} disabled={disabled}>
              Guardar Cambios
            </Button>
          )}
          <DialogClose asChild>
            <Button variant="secondary">Cerrar</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
