import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  fetchCoberturasForIncident,
  updateCoverageForIncident,
} from "@/services/siniestroService";
import { toast } from "sonner";
import { MdiPencil } from "@/components/icons/MdiPencil";

interface EditCoberturasDialogProps {
  incidentId: number;
  onSiniestroUpdated: (siniestro: any) => void;
  siniestro: any;
  disabled?: boolean; // Ya existente, por ejemplo, para deshabilitar el botón de abrir el diálogo
  isEditingDisabled?: boolean; // Nueva prop para deshabilitar los campos de edición
}

interface CoberturaProps {
  id_coberturas: number;
  desc_coberturas: string;
  suma_poliza: number;
  observaciones: string;
}
export function EditCoberturasDialog({
  incidentId,
  onSiniestroUpdated,
  siniestro,
  isEditingDisabled = false, // Controla si los campos del diálogo están habilitados
}: EditCoberturasDialogProps) {
  const [coberturas, setCoberturas] = useState<CoberturaProps[]>([]);

  useEffect(() => {
    const getCoberturas = async () => {
      const coberturasData = await fetchCoberturasForIncident(incidentId);
      setCoberturas(coberturasData.coberturas);
    };

    getCoberturas();
  }, [incidentId]);

  const handleSave = async () => {
    if (isEditingDisabled) return; // Evitar guardar si la edición está deshabilitada
    const updatedCoberturas = [...coberturas];
    for (const cobertura of coberturas) {
      try {
        const response = await updateCoverageForIncident(
          incidentId,
          cobertura.id_coberturas,
          cobertura.suma_poliza,
          cobertura.observaciones,
        );
        const updatedCobertura = response.cobertura;
        const index = updatedCoberturas.findIndex(
          (c) => c.id_coberturas === updatedCobertura.id_cobertura,
        );
        if (index !== -1) {
          updatedCoberturas[index] = {
            ...updatedCoberturas[index],
            ...updatedCobertura,
          };
        }
        onSiniestroUpdated({ ...siniestro, coberturas: updatedCoberturas });
      } catch (error) {
        toast.error("Error al actualizar las coberturas");
      }
    }
    toast.success("Coberturas actualizadas correctamente");
  };

  const handleInputChange = (
    index: number,
    field: keyof CoberturaProps,
    newValue: string | number,
  ) => {
    setCoberturas(
      coberturas.map((cobertura, i) =>
        i === index ? { ...cobertura, [field]: newValue } : cobertura,
      ),
    );
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="ml-2"
          disabled={isEditingDisabled}
        >
          <MdiPencil />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editar coberturas</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {coberturas.map((cobertura, index) => (
            <div key={cobertura.id_coberturas}>
              <Label htmlFor={`sumaPolizaInput-${cobertura.id_coberturas}`}>
                {cobertura.desc_coberturas}
              </Label>
              <Input
                id={`sumaPolizaInput-${cobertura.id_coberturas}`}
                type="number"
                defaultValue={cobertura.suma_poliza}
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "suma_poliza",
                    Number(e.target.value),
                  )
                }
                disabled={isEditingDisabled} // Controla si el campo está deshabilitado
              />
              <Label htmlFor={`observacionesInput-${cobertura.id_coberturas}`}>
                Observaciones
              </Label>
              <Input
                id={`observacionesInput-${cobertura.id_coberturas}`}
                type="text"
                defaultValue={cobertura.observaciones}
                onChange={(e) =>
                  handleInputChange(index, "observaciones", e.target.value)
                }
                disabled={isEditingDisabled} // Controla si el campo está deshabilitado
              />
            </div>
          ))}
        </div>
        <DialogFooter>
          <Button onClick={handleSave} disabled={isEditingDisabled}>
            Guardar
          </Button>
          <DialogClose asChild>
            <Button variant="secondary">Cerrar</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
