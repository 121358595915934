import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { SolarLogout2Bold } from "./icons/SolarLogout2Bold";
import { Button } from "./ui/button";
import { Icons8Support } from "./icons/Icons8Support";
import { PhFirstAidKitBold } from "./icons/PhFirstAidKitBold";
import { MaterialSymbolsSettingsAccountBox } from "./icons/MaterialSymbolsSettingsAccountBox";
import { ModeToggle } from "./mode-toggle";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "./ui/dropdown-menu";
import { RiNotification3Line, RiTimerLine } from "react-icons/ri";
import useNotificationStore from "@/stores/notificationStore";
import { FaStore } from "react-icons/fa";
import { MdOutlineWavingHand } from "react-icons/md";

const links = [
  { title: "Siniestros", href: "/siniestros" },
  { title: "Parametría", href: "/list" },
  { title: "Facturación", href: "/facturacion" },
  { title: "Dashboard", href: "/dashboard" },
  { title: "Proveedores", href: "/proveedores" },
  { title: "Stros. prescritos", href: "/siniestros/antiguos" },
];

const Navbar = () => {
  const navigate = useNavigate();
  const { roles, isAuthenticated, logout, username } = useAuth();
  const {
    unreadCount,
    startFetchingUnreadCount,
    stopFetchingUnreadCount,
    resetUnreadCount,
    fetchInitialUnreadCount,
  } = useNotificationStore();

  useEffect(() => {
    if (username) {
      fetchInitialUnreadCount(username).catch((error) =>
        console.error("Error during initial fetch:", error)
      );

      startFetchingUnreadCount(username);
    }

    return () => {
      stopFetchingUnreadCount(); // Asegúrate de limpiar el intervalo
    };
  }, [
    username,
    fetchInitialUnreadCount,
    startFetchingUnreadCount,
    stopFetchingUnreadCount,
  ]);

  const handleLogout = () => {
    logout();
    resetUnreadCount();
    navigate("/");
  };

  const handleLogin = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    localStorage.removeItem("state");
    localStorage.removeItem("roles");
    navigate("/");
  };

  const filteredLinks = roles?.includes(2)
    ? links
    : links.filter(
        (link) => link.title === "Siniestros" || link.title === "Notificaciones"
      );

  return (
    <div className="fixed top-0 w-full bg-opacity-60 backdrop-blur-md border-b border-gray-200 dark:border-gray-700 flex justify-between items-center px-4 py-2 z-10">
      <div className="flex items-center space-x-4">
        <Link
          to="/siniestros"
          className="flex items-center space-x-2 no-underline text-gray-800 dark:text-gray-200 hover:no-underline hover:text-gray-800 dark:hover:text-gray-200 focus:no-underline focus:text-gray-800 dark:focus:text-gray-200"
        >
          <Icons8Support className="w-10 h-10" />
          <span className="text-sm font-semibold">Tu Service</span>
        </Link>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button>Menú</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {filteredLinks.map((link) => (
              <DropdownMenuItem key={link.title} asChild>
                <Link
                  to={link.href}
                  className="flex items-center no-underline text-gray-800 dark:text-gray-200 text-sm"
                >
                  {getIcon(link.title)}
                  {link.title}
                </Link>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        <ModeToggle />
      </div>

      <div className="flex items-center space-x-4">
        {isAuthenticated ? (
          <>
            <span className="hidden sm:flex items-center mr-4">
              Bienvenido, {username}{" "}
              <MdOutlineWavingHand className="ml-1 text-yellow-400 animate-pulse" />
            </span>
            <Link to="/notificaciones" className="relative">
              {unreadCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full px-1.5 py-0.5">
                  {unreadCount}
                </span>
              )}
              <RiNotification3Line className="w-6 h-6 text-gray-800 dark:text-gray-200" />
            </Link>
            <Button
              variant="link"
              asChild
              className="text-sm text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400"
            >
              <a
                href="https://tuservicesrl.net/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <FaStore className="mr-1" />
                División Electro
              </a>
            </Button>
            <Button
              onClick={handleLogout}
              variant="destructive"
              className="flex items-center"
            >
              <span className="hidden sm:inline">Cerrar sesión</span>
              <SolarLogout2Bold className="w-4 h-4 ml-2" />
            </Button>
          </>
        ) : (
          <Button
            onClick={handleLogin}
            variant="default"
            className="flex items-center"
          >
            <span>Iniciar sesión</span>
          </Button>
        )}
      </div>
    </div>
  );
};

const getIcon = (title: string) => {
  switch (title) {
    case "Siniestros":
      return <PhFirstAidKitBold className="mr-2" />;
    case "Parametría":
      return <MaterialSymbolsSettingsAccountBox className="mr-2" />;
    case "Dashboard":
      return <MaterialSymbolsSettingsAccountBox className="mr-2" />;
    case "Facturación":
      return <PhFirstAidKitBold className="mr-2" />;
    case "Proveedores":
      return <PhFirstAidKitBold className="mr-2" />;
    case "Stros. prescritos":
      return <RiTimerLine className="mr-2" />;
    default:
      return null;
  }
};

export default Navbar;
