import { DatePickerDemo } from "@/components/ui/DatePickerDemo";
import { format } from "@formkit/tempo";

interface DateFilterProps {
  startDate: Date | undefined;
  endDate: Date | undefined;
  onChangeStartDate: (date: Date | undefined) => void;
  onChangeEndDate: (date: Date | undefined) => void;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
}

export const DateFilter = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
}: DateFilterProps) => {
  const handleStartDateChange = (date: Date | undefined) => {
    if (date) {
      const startOfDay = new Date(date);
      startOfDay.setHours(0, 0, 0, 0); // Establecer la hora de inicio a las 00:00 en la zona local

      if (endDate && startOfDay > endDate) {
        // Validar que la fecha de inicio no sea mayor que la fecha de fin
        console.error(
          "La fecha de inicio no puede ser mayor que la fecha de fin."
        );
        return;
      }

      const formattedDate = format(startOfDay, "YYYY-MM-DDTHH:mm:ssZ", "en-US");
      onChangeStartDate(new Date(formattedDate));
    } else {
      onChangeStartDate(undefined);
    }
  };

  const handleEndDateChange = (date: Date | undefined) => {
    if (date) {
      const endOfDay = new Date(date);
      endOfDay.setHours(23, 59, 59, 999); // Establecer la hora de fin a las 23:59 en la zona local

      if (startDate && endOfDay < startDate) {
        // Validar que la fecha de fin no sea menor que la fecha de inicio
        console.error(
          "La fecha de fin no puede ser menor que la fecha de inicio."
        );
        return;
      }

      const formattedDate = format(endOfDay, "YYYY-MM-DDTHH:mm:ssZ", "en-US");
      onChangeEndDate(new Date(formattedDate));
    } else {
      onChangeEndDate(undefined);
    }
  };

  return (
    <div className="flex space-x-2">
      <DatePickerDemo
        date={startDate}
        onChange={handleStartDateChange}
        label="Fecha de inicio"
        maxDate={endDate} // Restringe la selección de fechas después de la fecha de fin
      />
      <DatePickerDemo
        date={endDate}
        onChange={handleEndDateChange}
        label="Fecha de fin"
        minDate={startDate} // Restringe la selección de fechas antes de la fecha de inicio
      />
    </div>
  );
};
