import jsPDF from "jspdf";
import bannerImage from "../../../bannerIT.png";
import autoTable from "jspdf-autotable";
import {
  buildAddressString,
  formatDate,
} from "../SiniestroDetails/SiniestroDetails";
import { formattedValueARS } from "../SiniestroDetails/formattedValue";

export const InformeTecnicoExportToPDF = (
  siniestro: Siniestro,
  bienes: Bien[],
  ingresos: Ingreso[],
  bienCoberturaAssignments: Record<number, number>
): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const doc = new jsPDF();
      doc.addImage(bannerImage, "PNG", 0, 0, 210, 80);

      let currentY = 90;
      doc.setFontSize(11);

      const margin = 10; // Ajustamos el margen
      const pageWidth = 210; // Ancho estándar de una página A4
      const maxWidth = pageWidth - 2 * margin; // Ancho máximo para el texto
      const textMargin = margin + 5; // Añade un pequeño margen adicional para el texto

      let textoCompleto = `Compañía aseguradora: ${
        siniestro.companyName
      }\nNº de Siniestro: ${siniestro.companyIncidentNumber}\nNº de Póliza: ${
        siniestro.policyNumber
      }\nTipo de Póliza: ${siniestro.policyType}\nObservaciones de la Póliza: ${
        siniestro.policyObservations
      }\n\nAsegurado:\nNombre: ${siniestro.insuredName}\nDNI: ${
        siniestro.insuredDNI
      }\n\nDirección del Siniestro: ${buildAddressString(
        siniestro
      )}\nFecha de ocurrencia: ${formatDate(
        siniestro.ocurrenceDatetime
      )}\nFecha de denuncia: ${formatDate(
        siniestro.denunciationDatetime
      )}\nDescripción del siniestro: ${siniestro.ocurrenceDescription}`;

      textoCompleto = doc.splitTextToSize(textoCompleto, maxWidth - 10);

      // Calcular la altura necesaria para el rectángulo
      const lineHeight = 5; // Ajusta esto según tu configuración de fuente y espacio entre líneas
      const rectHeight = textoCompleto.length * lineHeight + 10; // +10 para un poco de espacio adicional

      doc.setFillColor(224, 224, 224);
      doc.setDrawColor(0);
      doc.rect(margin, currentY, maxWidth, rectHeight, "FD");
      doc.text(textoCompleto, textMargin, currentY + lineHeight);

      currentY += rectHeight + 10;

      const formatCurrency = (value: number) => `${formattedValueARS(value)}`;

      let totalClaimedValue = 0;
      let totalRecognizedValue = 0;
      let totalRepairedValue = 0;

      const bienesRows = bienes.map((bien) => {
        totalClaimedValue += bien.claimedValue ?? 0;

        // El valor reconocido siempre debe ser tomado del campo recognizedValue
        const recognizedValue = bien.recognizedValue ?? 0;
        totalRecognizedValue += recognizedValue;

        // El valor reparado solo se toma si la resolución es "Reparado"
        const repairedValue =
          bien.resolutionName === "Reparado"
            ? bien.repairedRecognizedValue ?? 0
            : 0;
        if (bien.resolutionName === "Reparado") {
          totalRepairedValue += repairedValue;
        }

        const coberturaId = bienCoberturaAssignments[bien.id];
        const coberturaAsignada = siniestro.coberturas.find(
          (c: Cobertura) => c.id_cobertura === Number(coberturaId)
        );

        const textoCobertura = coberturaAsignada
          ? `${
              coberturaAsignada.nombre_cobertura
            } - Suma Asegurada: ${formattedValueARS(
              coberturaAsignada.suma_poliza
            )}`
          : "Sin cobertura";

        const tipoPagoName = ["Reparado", "Rechazado"].includes(
          bien.resolutionName
        )
          ? "No aplica"
          : bien.tipoPagoName ?? "No aplica";

        const textoOtherObservations = bien.otherObservations
          ? bien.otherObservations
          : "";

        // Añadimos una nueva celda para "Valor Reparado" solo si el bien ha sido reparado
        const bienRow = [
          (bien.itemType ?? "Sin especificar") +
            " " +
            (bien.brandName ?? "sin especificar") +
            " " +
            (bien.model ?? "sin especificar") +
            " - Nº de Serie: " +
            (bien.serial ?? "sin especificar"),
          formatCurrency(bien.claimedValue ?? 0),
          formatCurrency(recognizedValue),
          bien.resolutionName === "Reparado"
            ? formatCurrency(repairedValue)
            : "-",
          bien.resolutionName ?? "Sin especificar",
          tipoPagoName,
          textoCobertura,
          textoOtherObservations,
        ];

        return bienRow;
      });

      bienesRows.push([
        "TOTALES:",
        formatCurrency(totalClaimedValue),
        formatCurrency(totalRecognizedValue),
        formatCurrency(totalRepairedValue),
        "",
        "",
        "",
      ]);

      // Agregar después de la definición de bienesRows
      const causasRows = bienes.map((bien) => [
        `${bien.itemType ?? "Sin especificar"} ${
          bien.brandName ?? "sin especificar"
        } - ${bien.model ?? "sin especificar"}`,
        bien.causeName ?? "Sin motivo de denuncia",
      ]);

      autoTable(doc, {
        head: [
          [
            "Bien",
            "Valor reclamado",
            "Valor reconocido",
            "Valor reparado", // Añadimos el encabezado de la nueva columna
            "Resolución",
            "Tipo de pago",
            "Cobertura",
            "Obs.",
          ],
        ],
        body: bienesRows,
        startY: currentY,
        theme: "grid",
        styles: {
          fontSize: 8, // Ajusta la fuente para que el texto quepa mejor
          cellPadding: 1, // Reduce el relleno de las celdas para que todo quepa mejor
        },
        headStyles: { fillColor: [22, 160, 133], textColor: 255 },
        columnStyles: {
          0: { cellWidth: 25 },
          1: { cellWidth: 20 },
          2: { cellWidth: 20 },
          3: { cellWidth: 20 }, // Columna para "Valor reparado"
          4: { cellWidth: 20 },
          5: { cellWidth: 35 },
          6: { cellWidth: 25 },
          7: { cellWidth: 25 }, // Ajuste para la nueva columna "Obs."
        },
        margin: { left: margin, right: margin }, // Deja margen para que no se salga del borde
      });

      currentY = (doc as any).lastAutoTable.finalY + 10;

      // Modificación en la tabla de causas
      if (causasRows.length > 0) {
        currentY = (doc as any).lastAutoTable.finalY + 10; // Asegurarse de colocar la tabla de causas debajo de la anterior
        autoTable(doc, {
          head: [["Bien", "Motivo de Denuncia"]],
          body: causasRows,
          startY: currentY,
          theme: "grid",
          styles: {
            fontSize: 10, // Ajustar según tus necesidades
            cellPadding: 2,
          },
          headStyles: { fillColor: [100, 149, 237], textColor: 255 },
          columnStyles: {
            0: { cellWidth: "wrap" }, // Ajuste automático del ancho de la celda
            1: { cellWidth: "wrap" }, // Ajuste automático del ancho de la celda
          },
          margin: { left: margin, right: margin },
        });

        currentY = (doc as any).lastAutoTable.finalY + 10; // Actualiza currentY después de la tabla de causas
      }

      doc.setFontSize(10);
      bienes.forEach((bien, index) => {
        const observacionTexto = `Informe técnico del bien ${index + 1} (${
          bien.itemType ?? "sin especificar"
        } ${bien.brandName ?? "sin especificar"} ${
          bien.model ?? "sin especificar"
        }): ${bien.observations ?? "sin observaciones"}`;
        const lineasObservacion = doc.splitTextToSize(
          observacionTexto,
          maxWidth
        );

        lineasObservacion.forEach((linea: string | string[]) => {
          // Asegurarse de que 'linea' sea un string
          if (typeof linea === "string") {
            const lineWidth =
              (doc.getStringUnitWidth(linea) * doc.getFontSize()) /
              doc.internal.scaleFactor;
            const startX = (pageWidth - lineWidth) / 2;

            if (currentY > 280) {
              doc.addPage();
              currentY = 20;
            }

            doc.text(linea, startX, currentY);
            currentY += lineHeight;
          }
        });

        currentY += 5; // Espaciado adicional después de cada observación si es necesario
      });

      currentY += 15; // Espaciado adicional después de las observaciones

      const ingresosRows = ingresos.map((ingreso) => [
        ingreso.text,
        formattedValueARS(ingreso.value), // Formatea cada valor con formattedValueARS
      ]);

      // Calcula el total de los valores
      const totalIngreso = ingresos.reduce(
        (total, ingreso) => total + ingreso.value,
        0
      );

      // Agrega la fila de total al final de los ingresosRows
      if (ingresosRows.length > 0) {
        ingresosRows.push(["Total:", formattedValueARS(totalIngreso)]);
      }

      if (ingresosRows.length > 0) {
        autoTable(doc, {
          head: [["Tipo de honorario", "Valor"]],
          body: ingresosRows,
          startY: currentY,
          theme: "grid",
          styles: { fontSize: 10, cellPadding: 2 },
          headStyles: { fillColor: [100, 149, 237], textColor: 255 },
          columnStyles: {
            0: { cellWidth: "auto" },
            1: { cellWidth: "auto" },
          },
          didParseCell: function (data) {
            const row = data.row;
            const rowIndex = row.index;

            // Si es la última fila (la de totales)
            if (rowIndex === ingresosRows.length - 1) {
              data.cell.styles.fontSize = 12; // Tamaño de la fuente más grande
              data.cell.styles.fontStyle = "bold"; // Texto en negrita
            }
          },
        });
      }

      const fileName = `InformeTecnico-Siniestro${siniestro.companyIncidentNumber}.pdf`;
      doc.save(fileName, { returnPromise: true }).then(resolve);
    } catch (error) {
      reject(error);
    }
  });
};
