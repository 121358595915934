import { Button } from "@/components/ui/button";
import React from "react";
import { FaWhatsapp } from "react-icons/fa";

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

interface AseguradoDetailsProps {
  nombre: string | null;
  dni: string | null;
  email: string | null;
  telefono: string | null;
  celular: string | null;
}

export const AseguradoDetails: React.FC<AseguradoDetailsProps> = ({
  nombre,
  dni,
  email,
  telefono,
  celular,
}) => {
  // Determinar si el DNI es en realidad un CUIT
  const isCUIT = dni && dni.length === 11;

  return (
    <div className="pl-8 text-gray-600 dark:text-gray-300">
      <p>Nombre: {toTitleCase(nombre ?? "")}</p>
      <p>
        {isCUIT ? "CUIT" : "DNI"}: {dni}
      </p>
      <p>Correo electrónico: {email}</p>
      <p>Teléfono: {telefono}</p>
      <p>
        Celular: {celular}
        {celular && (
          <Button
            variant="green"
            size="icon"
            onClick={() => {
              window.open(`https://wa.me/${celular}`, "_blank");
            }}
            className="ml-2"
          >
            <FaWhatsapp size={15} />
          </Button>
        )}
      </p>
    </div>
  );
};
