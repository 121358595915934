import { type SVGProps } from "react";

export function PajamasStatusNeutral(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6 12A6 6 0 1 0 6 0a6 6 0 0 0 0 12zM3 4.75A.75.75 0 0 1 3.75 4h4.5a.75.75 0 0 1 0 1.5h-4.5A.75.75 0 0 1 3 4.75zm0 2.5a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5A.75.75 0 0 1 3 7.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}
