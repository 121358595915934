import { useEffect, useState } from "react";
import { SiniestroDataTable } from "./SiniestroDataTable";
import { useAuth } from "@/contexts/AuthContext";
import { useSiniestroStore } from "@/stores/siniestroStore";
import Spinner from "../ui/spinner";
import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import { IcBaselineAddCircle } from "../icons/IcBaselineAddCircle";

function SiniestrosList() {
  const { username, roles, isAuthenticated, isAuthChecked, checkAuth } =
    useAuth(); // Añadir checkAuth
  const siniestros = useSiniestroStore((state) => state.siniestros);
  const fetchSiniestros = useSiniestroStore((state) => state.fetchSiniestros);
  const currentPage = useSiniestroStore((state) => state.currentPage);
  const isLoading = useSiniestroStore((state) => state.isLoading);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const userHasRole11 = Array.isArray(roles) && roles.includes(11);
  const userHasRole7 = Array.isArray(roles) && roles.includes(7);

  useEffect(() => {
    async function fetchData() {
      if (isAuthenticated && username) {
        try {
          await fetchSiniestros();
        } catch (error) {
          console.error("Error fetching siniestros:", error);
        }
      }
      setIsCheckingAuth(false);
    }

    if (isAuthChecked && isAuthenticated) {
      void fetchData();
    } else if (!isAuthChecked) {
      void checkAuth(); // Asegúrate de verificar la autenticación antes de cargar los siniestros
    }
  }, [fetchSiniestros, isAuthenticated, username, isAuthChecked, checkAuth]);

  if (isCheckingAuth || isLoading || !isAuthChecked) {
    return <Spinner />;
  }

  if (!isAuthenticated) {
    return <Link to="/">Inicie sesión para ver la lista de siniestros</Link>;
  }

  return (
    <div className="container mx-auto min-h-screen">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h1 className="text-3xl font-semibold mb-4 md:mb-0 text-center md:text-left mt-2">
          Siniestros
        </h1>
        {username && !userHasRole11 && !userHasRole7 && (
          <Link to="/nuevo-siniestro/nuevo-asegurado">
            <Button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded flex items-center w-full sm:w-auto">
              <IcBaselineAddCircle className="mr-2 h-4 w-4" />
              Nuevo siniestro
            </Button>
          </Link>
        )}
      </div>
      <SiniestroDataTable data={siniestros} currentPage={currentPage} />
    </div>
  );
}

export default SiniestrosList;
