import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "sonner";
import { apiFetch } from "@/services/siniestroService";
import { ReloadIcon } from "@radix-ui/react-icons";

interface EnviarMensajeModalProps {
  proveedorId: number;
  siniestroId: number;
  companyIncidentNumber: string;
  onClose: () => void;
}

const EnviarMensajeModal: React.FC<EnviarMensajeModalProps> = ({
  proveedorId,
  siniestroId,
  companyIncidentNumber,
  onClose,
}) => {
  const [mensaje, setMensaje] = useState("");
  const [selectedMessage, setSelectedMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const commonMessages = [
    "Tenés un reclamo, revisar",
    "Favor de contactar al cliente",
    "Urgente: Necesitamos una actualización",
    "Favor de enviar documentación faltante",
    "Por favor, confirme recepción del siniestro",
  ];

  const handleEnviarMensaje = async () => {
    setIsLoading(true);
    try {
      const response = await apiFetch("/api/notificaciones/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: proveedorId,
          mensaje: `Siniestro número: ${companyIncidentNumber} - ${
            mensaje || selectedMessage
          }`,
          tipo: "mensaje",
          siniestroId: siniestroId,
          companyIncidentNumber: companyIncidentNumber,
        }),
      });

      if (!response.ok) {
        throw new Error("Error al enviar el mensaje");
      }

      toast.success("Mensaje enviado correctamente");
      setMensaje("");
      setSelectedMessage("");
      onClose();
    } catch (error) {
      toast.error("Error al enviar el mensaje");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChange = (value: string) => {
    setSelectedMessage(value);
    setMensaje(value);
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogTitle>Enviar mensaje al proveedor</DialogTitle>
        <DialogDescription>
          Escribe un mensaje para el proveedor seleccionado.
        </DialogDescription>
        <div className="mt-4">
          <Select onValueChange={handleSelectChange}>
            <SelectTrigger>
              <SelectValue placeholder="Seleccionar mensaje común" />
            </SelectTrigger>
            <SelectContent>
              {commonMessages.map((msg, index) => (
                <SelectItem key={index} value={msg}>
                  {msg}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="mt-4">
          <Textarea
            placeholder="Escribe tu mensaje aquí..."
            value={mensaje}
            onChange={(e) => setMensaje(e.target.value)}
          />
        </div>
        <div className="mt-4 flex justify-end">
          <DialogClose asChild>
            <Button variant="outline" onClick={onClose}>
              Cancelar
            </Button>
          </DialogClose>
          <Button
            className="ml-2"
            onClick={handleEnviarMensaje}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <ReloadIcon className="animate-spin mr-2" />
                Enviando...
              </>
            ) : (
              "Enviar"
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EnviarMensajeModal;
