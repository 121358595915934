import React, { useState, useEffect } from "react";
import {
  fetchRemitoByIncidentId,
  createRemito,
  updateRemito,
} from "@/services/remitosService";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "@/components/ui/dialog";
import Spinner from "../ui/spinner";
import { toast } from "sonner";
import { FiEdit } from "react-icons/fi"; // Importa el ícono de editar

interface RemitosElectroDetailsProps {
  incidentId: number;
  disabled?: boolean;
}

const RemitosElectroDetails: React.FC<RemitosElectroDetailsProps> = ({
  incidentId,
  disabled = false,
}) => {
  const [remitos, setRemitos] = useState<any[]>([]);
  const [numeroRemito, setNumeroRemito] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRemito, setSelectedRemito] = useState<any | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const loadRemitos = async () => {
      try {
        const fetchedRemitos = await fetchRemitoByIncidentId(incidentId);
        setRemitos(fetchedRemitos);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    loadRemitos();
  }, [incidentId]);

  const handleCreateOrUpdateRemito = async (id?: number) => {
    if (!numeroRemito) {
      toast.error("Por favor, ingrese el número de remito.");
      return;
    }

    try {
      setIsLoading(true);

      // Verifica si el número de remito ya existe en la lista de remitos cargados
      const remitoExists = remitos.some(
        (remito) => remito.numero_remito === numeroRemito && remito.id !== id,
      );

      if (remitoExists) {
        toast.error("El número de remito ya existe.");
        setIsLoading(false);
        return;
      }

      if (id) {
        await updateRemito(id, numeroRemito);
        toast.success("Remito actualizado correctamente");
      } else {
        await createRemito(numeroRemito, incidentId);
        toast.success("Remito creado correctamente");
      }

      const fetchedRemitos = await fetchRemitoByIncidentId(incidentId);
      setRemitos(fetchedRemitos);
      handleDialogClose(); // Restablecer el estado y cerrar el diálogo
    } catch (error) {
      toast.error("Error al guardar el remito");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = (remito: any) => {
    setNumeroRemito(remito.numero_remito);
    setSelectedRemito(remito);
    setIsEditing(true);
    setOpenDialog(true); // Abrir el diálogo al hacer clic en "Editar Remito"
  };

  const handleAddClick = () => {
    setNumeroRemito("");
    setSelectedRemito(null);
    setIsEditing(false);
    setOpenDialog(true); // Abrir el diálogo al hacer clic en "Agregar Remito"
  };

  const handleDialogClose = () => {
    setNumeroRemito("");
    setSelectedRemito(null);
    setOpenDialog(false);
  };

  if (isLoading) {
    return (
      <Card className="w-full p-4">
        <CardContent>
          <Spinner />
          <p className="text-sm">Cargando remitos...</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full p-4">
      <CardHeader>
        <div className="flex justify-between items-center">
          <div>
            <CardTitle className="text-lg">Remito División Electro</CardTitle>
            <CardDescription className="text-sm">
              Detalles de los remitos asociados al siniestro
            </CardDescription>
          </div>
          <Button onClick={handleAddClick} disabled={disabled}>
            Agregar remito
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        {remitos.length > 0 ? (
          remitos.map((remito) => (
            <div
              key={remito.id}
              className="mt-2 flex items-center justify-between"
            >
              <p className="text-gray-700 dark:text-gray-300 text-sm">
                <strong>Número de Remito:</strong> {remito.numero_remito}
              </p>
              <Button onClick={() => handleEditClick(remito)} className="ml-2">
                <FiEdit className="text-sm" />
              </Button>
            </div>
          ))
        ) : (
          <p>No hay remitos asociados a este siniestro.</p>
        )}
      </CardContent>
      <Dialog open={openDialog} onOpenChange={handleDialogClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-lg">
              {isEditing ? "Editar Remito" : "Agregar Remito"}
            </DialogTitle>
            <DialogDescription className="text-sm">
              {isEditing
                ? "Edita los detalles del remito aquí. Haz clic en actualizar cuando termines."
                : "Agrega un nuevo remito aquí. Haz clic en crear cuando termines."}
            </DialogDescription>
          </DialogHeader>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleCreateOrUpdateRemito(selectedRemito?.id);
            }}
          >
            <div className="flex flex-col space-y-2">
              <label
                htmlFor="numeroRemito"
                className="text-gray-700 dark:text-gray-300 text-sm"
              >
                Número de Remito
              </label>
              <Input
                id="numeroRemito"
                value={numeroRemito}
                onChange={(e) => setNumeroRemito(e.target.value)}
                className="dark:bg-gray-800 dark:text-gray-300"
              />
            </div>
            <DialogFooter className="flex justify-end mt-4">
              <Button variant="outline" onClick={handleDialogClose}>
                Cancelar
              </Button>
              <Button type="submit" className="ml-2">
                {isEditing ? "Actualizar Remito" : "Crear Remito"}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default RemitosElectroDetails;
