/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";
import { MaterialSymbolsAddChartOutline } from "@/components/icons/MaterialSymbolsAddChartOutline";
import { Label } from "@/components/ui/label";
import { toast } from "sonner";
import { fetchCoberturas } from "@/services/siniestroService";
import { addCoverageToIncident } from "@/services/siniestroService";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface AddCoberturaDialogProps {
  incidentId: number;
  siniestro: Siniestro;
  handleSiniestroUpdated: (updatedSiniestro: Siniestro) => void;
  disabled?: boolean; // Añadir propiedad disabled
}

interface CoberturaSelectProps {
  id_coberturas: number;
  desc_coberturas: string;
}

export function AddCoberturaDialog({
  incidentId,
  siniestro,
  handleSiniestroUpdated,
  disabled = false, // Valor por defecto
}: AddCoberturaDialogProps) {
  const [coberturas, setCoberturas] = useState<CoberturaSelectProps[]>([]);
  const [selectedCobertura, setSelectedCobertura] = useState("");
  const [sumaPolizaInput, setSumaPolizaInput] = useState("");
  const [observacionesInput, setObservacionesInput] = useState("");

  useEffect(() => {
    const getCoberturas = async () => {
      const coberturasData = await fetchCoberturas();
      setCoberturas(coberturasData.coberturas);
    };

    getCoberturas();
  }, []);

  const handleSave = async () => {
    if (disabled) return; // Evitar que se ejecute si está deshabilitado

    const sumaPoliza = parseFloat(sumaPolizaInput);

    if (isNaN(sumaPoliza)) {
      toast.error("La suma asegurada debe ser un número válido");
      return;
    }

    const newCobertura = {
      coberturaId: Number(selectedCobertura),
      sumaPoliza: sumaPoliza,
      observaciones: observacionesInput,
    };

    try {
      const response = await addCoverageToIncident(incidentId, newCobertura);

      if (response && response.newCoverage) {
        const updatedSiniestro = {
          ...siniestro,
          coberturas: [...siniestro.coberturas, response.newCoverage],
        };

        handleSiniestroUpdated(updatedSiniestro);
        toast.success("Cobertura agregada correctamente");
      } else {
        throw new Error(
          "La respuesta del servidor no contiene la nueva cobertura",
        );
      }
    } catch (error) {
      toast.error("Error al agregar cobertura");
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="ml-2"
          disabled={disabled}
        >
          <MaterialSymbolsAddChartOutline />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Agregar cobertura</DialogTitle>
          <DialogDescription>
            Ingresa los detalles de la nueva cobertura y haz clic en guardar.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Label htmlFor="coberturaSelect">Cobertura</Label>
          <Select
            onValueChange={setSelectedCobertura}
            value={selectedCobertura}
            disabled={disabled} // Deshabilitar el select si es necesario
          >
            <SelectTrigger>
              <SelectValue placeholder="Seleccione una cobertura" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {coberturas.map((cobertura) => (
                  <SelectItem
                    key={cobertura.id_coberturas}
                    value={String(cobertura.id_coberturas)}
                  >
                    {cobertura.desc_coberturas}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>

          <Label htmlFor="sumaPolizaInput">Suma Asegurada</Label>
          <Input
            id="sumaPolizaInput"
            type="number"
            value={sumaPolizaInput}
            onChange={(e) => {
              setSumaPolizaInput(e.target.value);
            }}
            disabled={disabled} // Deshabilitar el input si es necesario
          />

          <Label htmlFor="observacionesInput">Observaciones</Label>
          <Input
            id="observacionesInput"
            type="text"
            value={observacionesInput}
            onChange={(e) => {
              setObservacionesInput(e.target.value);
            }}
            disabled={disabled} // Deshabilitar el input si es necesario
          />
        </div>
        <DialogFooter>
          <Button onClick={handleSave} disabled={disabled}>
            Guardar
          </Button>
          <DialogClose asChild>
            <Button variant="secondary" disabled={disabled}>
              Cerrar
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
