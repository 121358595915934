// authStore.ts
import { create } from "zustand";
const BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

interface AuthState {
  isAuthenticated: boolean;
  userId: number | null;
  username: string | null;
  userDisabled: boolean;
  roles: number[];
  login: (username: string, isDisabled: boolean) => void;
  logout: () => void;
  setUserDisabled: (disabled: boolean) => void;
  getRoles: () => Promise<void>;
  errorMessage: string | null;
  setErrorMessage: (message: string | null) => void;
}

const useAuthStore = create<AuthState>((set, get) => ({
  isAuthenticated: Boolean(localStorage.getItem("username")),
  userId: Number(localStorage.getItem("userId")),
  username: localStorage.getItem("username"),
  userDisabled: localStorage.getItem("state") === "disabled",
  roles:
    localStorage.getItem("roles") !== "undefined"
      ? JSON.parse(localStorage.getItem("roles") || "[]")
      : [],
  login: async (username: string, isDisabled: boolean) => {
    localStorage.setItem("username", username);
    localStorage.setItem("state", isDisabled ? "disabled" : "enabled");
    set({ isAuthenticated: true, username, userDisabled: isDisabled });

    await get().getRoles();
  },
  logout: async () => {
    try {
      // Realiza una solicitud al endpoint de logout
      const response = await fetch(`${BASE_URL}/api/users/logout`, {
        method: "POST",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Logout failed");
      }

      // Borra los datos del usuario del estado y del almacenamiento local
      localStorage.removeItem("userId");
      localStorage.removeItem("username");
      localStorage.removeItem("state");
      set({
        isAuthenticated: false,
        userId: null,
        username: null,
        userDisabled: false,
        roles: [],
      });
    } catch (error) {}
  },
  setUserDisabled: (disabled: boolean) => {
    set({ userDisabled: disabled });
  },
  getRoles: async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/users/roles`, {
        credentials: "include",
      });
      const data = await response.json();
      set({ roles: data.roles });
      localStorage.setItem("roles", JSON.stringify(data.roles)); // Guardar roles en localStorage
    } catch (error) {}
  },
  errorMessage: null,
  setErrorMessage: (message) => {
    set({ errorMessage: message });
  },
}));

export default useAuthStore;
