import { create } from "zustand";
import { fetchEstados, updateEstado } from "@/services/estadoService";
import { fetchTramitadores } from "@/services/siniestroService";
import useAuthStore from "./authStore";

interface Estado {
  id: number;
  name: string;
}

interface Tramitador {
  id: number;
  display_name: string;
  email: string;
}

interface EstadoState {
  estados: Estado[];
  tramitadores: Tramitador[];
  isLoading: boolean;
  currentPage: number;
  fetchEstadosYTramitadores: () => Promise<void>;
  updateEstado: (estadoId: number, values: any) => Promise<void>;
  setCurrentPage: (page: number) => void;
  editDialogId: number | null;
  setEditDialogId: (id: number | null) => void;
  setLoading: (loading: boolean) => void;
}

export const useEstadoStore = create<EstadoState>((set) => ({
  estados: [],
  tramitadores: [],
  isLoading: false,
  currentPage: 0,
  fetchEstadosYTramitadores: async () => {
    set({ isLoading: true });
    try {
      const [estadosData, tramitadoresData] = await Promise.all([
        fetchEstados(),
        fetchTramitadores(),
      ]);
      set({ estados: estadosData, tramitadores: tramitadoresData });
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "USER_DISABLED") {
          useAuthStore.getState().setUserDisabled(true);
        } else {
          throw error;
        }
      }
    } finally {
      set({ isLoading: false });
    }
  },
  updateEstado: async (estadoId, values) => {
    set({ isLoading: true });
    try {
      await updateEstado(estadoId, values);
    } catch (error) {
      // Handle error
    } finally {
      set({ isLoading: false });
    }
  },
  setCurrentPage: (page) => {
    set({ currentPage: page });
  },
  editDialogId: null,
  setEditDialogId: (id) => {
    set({ editDialogId: id });
  },
  setLoading: (loading) => {
    set({ isLoading: loading });
  },
}));
