/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable new-cap */
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { buildAddressString } from "../SiniestroDetails/SiniestroDetails";
import logoTS from "../../../logoTS3.png";
import { apiFetch } from "@/services/siniestroService";

async function generateAndUpdateReceiptNumber(selectedBienesIds: number[]) {
  const response = await apiFetch(`/api/bienes/receipt-number/batch`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ bienIds: selectedBienesIds }),
  });

  const data = await response.json();
  return data;
}

export const exportRemitoRetiroToPDF = async (
  siniestro: Siniestro,
  selectedBienes: Bien[]
) => {
  const selectedBienesIds = selectedBienes.map((bien) => bien.id);
  const receiptNumber = await generateAndUpdateReceiptNumber(selectedBienesIds);

  await new Promise<void>((resolve, reject) => {
    try {
      const doc = new jsPDF();
      doc.addImage(logoTS, "PNG", 0, 0, 60, 60);

      // Establece un margen debajo del logo para empezar a escribir el texto
      const logoHeight = 60;
      const spaceBelowLogo = 0;
      const textBelowLogoY = logoHeight + spaceBelowLogo;

      // Establece el tamaño de fuente para el texto debajo del logo
      doc.setFontSize(10); // Cambia el tamaño de fuente como sea necesario

      // Añade el texto debajo del logo
      const textBelowLogo = "Fraga 201 - (1427) C.A.B.A.";
      doc.text(textBelowLogo, 9, textBelowLogoY);

      // Dibujar 3 cuadrados para indicar si es Retira, Verifica o No retira

      // Define el tamaño de los cuadrados
      const squareSize = 5; // Tamaño de los cuadrados
      const squareMargin = 7; // Margen entre los cuadrados
      const squareY = 65; // Posición Y para los cuadrados

      // Define el texto para cada cuadrado
      const squareTexts = ["Retira", "Verifica", "No retira"];

      // Define el tamaño de fuente para el texto de los cuadrados
      doc.setFontSize(8); // Cambia el tamaño de fuente como sea necesario

      // Dibuja los cuadrados y el texto
      for (let i = 0; i < squareTexts.length; i++) {
        const squareX = 9 + i * squareSize + i * squareMargin; // Calcula la posición X para cada cuadrado
        doc.rect(squareX, squareY, squareSize, squareSize); // Dibuja el cuadrado
        // Dibujar el texto abajo del cuadrado

        // Calcula el ancho del texto para centrarlo dentro del cuadrado
        const textWidth = doc.getTextWidth(squareTexts[i]);

        // Calcula la posición X para centrar el texto dentro del cuadrado
        const textX = squareX + squareSize / 2 - textWidth / 2;

        // Dibuja el texto
        doc.text(squareTexts[i], textX, squareY + squareSize + 3); // Añade 3 para dejar espacio entre el cuadrado y el texto
      }

      let currentY = 80;
      const labelFontSize = 7; // Tamaño de fuente para las etiquetas
      const valueFontSize = 10; // Tamaño de fuente para los valores
      const margin = 10; // Margen externo
      const pageWidth = 210; // Ancho total de la página
      const maxWidth = pageWidth - 2 * margin; // Ancho máximo para el contenido
      const lineHeight = 5; // Altura de la línea de texto

      if (siniestro.type === "G") {
        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0); // Establece el color del texto en negro
        doc.text("GARANTÍA", pageWidth / 2, 70, { align: "center" });
        doc.setTextColor(0); // Reset to black
        doc.setFontSize(10); // Reset font size
      }

      // Define los pares de etiquetas y valores
      const info = [
        [
          "COMPANÍA, NRO. DE SINIESTRO Y NRO. DE PÓLIZA",
          `${siniestro.companyName ?? ""} | Nº ${
            siniestro.companyIncidentNumber ?? ""
          } | Póliza Nº ${siniestro.policyNumber ?? ""}`,
        ],
        ["TIPO Y NRO. DE DOCUMENTO", `DNI ${siniestro.insuredDNI ?? ""}`],
        ["NOMBRE DEL ASEGURADO", siniestro.insuredName ?? ""],
        ["CELULAR", siniestro.occurrenceCellphone || ""],
        ["DIRECCIÓN", buildAddressString(siniestro)],
        ["TELÉFONO", siniestro.occurrenceTelephone || ""],
      ];

      // Cálculo del ancho de las columnas basado en la longitud del texto
      const leftColumnWidth = maxWidth * 0.7; // 70% del ancho disponible para la columna izquierda
      const rightColumnWidth = maxWidth * 0.3; // 30% del ancho disponible para la columna derecha

      // Inicio de la sección adicional
      const sectionX = 15; // El inicio de la X después del logo, ajusta según sea necesario
      const sectionWidth = pageWidth - sectionX - margin;

      // Dibuja la X dentro del rectángulo
      doc.setFontSize(40); // Tamaño grande para la X
      doc.text("X", sectionX + sectionWidth / 2, 30, { align: "center" });

      // Asegúrate de que estás usando la alineación 'center' para el texto debajo de la 'X'
      doc.setFontSize(7); // Tamaño de fuente más pequeño para este texto
      doc.text(
        "Documento no válido como factura",
        sectionX + sectionWidth / 2,
        35,
        { align: "center" }
      );

      /// Alineación derecha para "ORDEN DE TRABAJO Nº" y número de orden
      const rightStartX = pageWidth - margin - 10; // Restamos 10 unidades adicionales para moverlo a la izquierda
      const orderLabelY = 10; // Alineación vertical con el logo y la "X"

      // Calcula la posición X para alinear "ORDEN DE TRABAJO Nº" a la derecha
      const orderLabel = "ORDEN DE TRABAJO Nº " + receiptNumber;
      doc.setFontSize(13); // Tamaño de fuente para el texto de la orden de trabajo
      const orderLabelWidth =
        (doc.getStringUnitWidth(orderLabel) * doc.getFontSize()) /
        doc.internal.scaleFactor;
      doc.text(orderLabel, rightStartX - orderLabelWidth, orderLabelY, {
        align: "left",
      });

      // Dibuja los cuadros para la fecha debajo de "ORDEN DE TRABAJO Nº"
      const boxWidth = 10; // Ancho de cada cuadro para la fecha
      const totalBoxesWidth = 3 * boxWidth; // Ancho total de los 3 cuadros
      const boxStartX = rightStartX - totalBoxesWidth; // Ajusta para que los cuadros queden alineados a la derecha
      const boxY = orderLabelY + lineHeight + 8; // Posición Y debajo del texto "ORDEN DE TRABAJO Nº"

      // Calcula la posición X para centrar "FECHA" sobre los cuadros
      const fechaLabel = "FECHA";
      doc.setFontSize(10); // Tamaño de fuente para el texto "FECHA"
      const fechaLabelWidth = doc.getStringUnitWidth(fechaLabel) * 10; // Ancho del texto "FECHA"
      const fechaLabelX =
        boxStartX + totalBoxesWidth / 2 - fechaLabelWidth / 2 + 10; // Ajusta para centrar el texto "FECHA"
      doc.text(fechaLabel, fechaLabelX, boxY - 3); // Posiciona "FECHA" encima de los cuadros

      // Dibuja cada cuadro para la fecha sin espacio entre ellos
      for (let i = 0; i < 3; i++) {
        doc.rect(boxStartX + i * boxWidth, boxY, boxWidth, 10); // Dibuja cada cuadro
      }

      // Alinea los textos adicionales debajo de los cuadros
      const additionalTexts = [
        "C.U.I.T.: 30-71450473-4",
        "Ing. Brutos: 1275972",
        "Inicio de actividades: 06/2014",
        "At. al Cliente: 4551-7771 Líneas Rotativas",
        "Lunes a viernes 10 a 13 hs - 14 a 17 hs.",
      ];
      let additionalTextY = boxY + 15; // Posición Y inicial para los textos adicionales
      additionalTexts.forEach((text) => {
        doc.text(text, rightStartX, additionalTextY, { align: "right" });
        additionalTextY += 5; // Incrementa para el siguiente texto
      });

      for (let i = 0; i < info.length; i += 2) {
        doc.setFontSize(valueFontSize);
        const leftValueLines = doc.splitTextToSize(info[i][1], leftColumnWidth);
        // Reducir el multiplicador de lineHeight y/o reducir el valor adicional
        const leftRectHeight = leftValueLines.length * lineHeight + 8; // Reducido de +10 a +5

        let rightRectHeight = 0; // Declara la altura del rectángulo derecho

        if (i + 1 < info.length) {
          const rightValueLines = doc.splitTextToSize(
            info[i + 1][1],
            rightColumnWidth
          );
          // Aplicar el mismo ajuste aquí si necesario
          rightRectHeight = rightValueLines.length * lineHeight + 2;
        }

        const rowHeight = Math.max(leftRectHeight, rightRectHeight);

        // Dibuja los rectángulos y el texto de la izquierda
        doc.setFontSize(labelFontSize);
        doc.setFillColor(255, 255, 255);
        doc.rect(margin, currentY, leftColumnWidth, rowHeight, "FD");
        doc.text(info[i][0], margin + 2, currentY + lineHeight);

        doc.setFontSize(valueFontSize);
        doc.text(leftValueLines, margin + 2, currentY + 2 * lineHeight);

        if (i + 1 < info.length) {
          // Asegúrate de que el ancho de la columna derecha es el correcto
          const rightValueLines = doc.splitTextToSize(
            info[i + 1][1],
            rightColumnWidth - margin
          );
          const rightRectHeight =
            (rightValueLines.length + 1) * lineHeight + 10;

          // Ajusta la posición x del rectángulo derecho para que no haya espacio entre ellos
          const rightRectX = margin + leftColumnWidth;

          doc.setFontSize(labelFontSize);
          doc.setFillColor(255, 255, 255);
          // Usa rightRectX para la posición x y asegúrate de que no hay espacio entre los rectángulos
          doc.rect(
            rightRectX,
            currentY,
            rightColumnWidth,
            rightRectHeight - 7,
            "FD"
          );
          doc.text(info[i + 1][0], rightRectX + 2, currentY + lineHeight);

          doc.setFontSize(valueFontSize);
          doc.text(rightValueLines, rightRectX + 2, currentY + 2 * lineHeight);
        }

        // Ajusta currentY para el próximo par de rectángulos
        currentY += rowHeight;
      }

      // Añade la tabla de bienes
      currentY += 10; // Añade un espacio entre la información y la tabla

      const tableStyles = {
        startY: currentY, // La posición Y inicial para la tabla
        margin: { top: 10, right: 10, bottom: 10, left: 10 }, // Margenes alrededor de la tabla
        styles: {
          fontSize: 10,
          cellPadding: { top: 5, right: 0, bottom: 5, left: 3 },
        }, // Estilo para la tabla
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: 40 },
          2: { cellWidth: 40 },
          3: { cellWidth: 40 },
          4: { cellWidth: 30 },
        },
        theme: "striped",
      };

      // Añade los datos de bienes a la tabla
      const tableBody = selectedBienes.map((bien) => [
        bien.itemType || "",
        bien.brandName || "",
        bien.model || "",
        bien.serial || "",
        bien.accesories || "",
      ]);

      // Crea la tabla con autotable
      autoTable(doc, {
        head: [["EQUIPO", "MARCA", "MODELO", "SERIE", "ACCESORIOS"]],
        body: tableBody,
        ...tableStyles,
        theme: "striped",
      });

      // Finaliza la tabla de bienes y guarda la posición Y final
      currentY = (doc as any).lastAutoTable.finalY + 10;

      // Añade un espacio después de la tabla antes de poner más texto
      const spaceAfterTable = 1;
      currentY = currentY + spaceAfterTable;

      // Agrega un texto debajo de la tabla
      const additionalText =
        "Las características de los equipos presentados sin número de serie ni modelo serán determinadas por el perito al momento de realizar la inspección. IMPORTANTE: en caso de que los equipos retirados tengan cobertura por la compañía aseguradora, estos quedarán a disposición del asegurado dentro de los 30 días corridos, cuando se trate de equipos reparados y/o rechazados. Si el equipo fue indemnizado no se procederá a su devolución. En caso que el asegurado no reclame su devolución a la compañía aseguradora dentro del plazo establecido, por la presente autoriza a TU SERVICE S.R.L. a disponer de los mismos para su desguace o destrucción. El retiro de los equipos detallados, suspende el plazo previsto en el art. 56 de la ley de seguros.";
      const additionalTextLines = doc.splitTextToSize(
        additionalText,
        maxWidth - 50
      );
      const lineHeightSmallText = 3; // Altura de línea para el texto pequeño
      // Imprime cada línea del texto adicional
      additionalTextLines.forEach((line: string | string[]) => {
        doc.setFontSize(labelFontSize);
        doc.text(line, margin, currentY);
        currentY += lineHeightSmallText; // Añade la altura de línea para la siguiente línea de texto
      });

      // Añadir a la derecha del texto, de manera centrada, un campo para Firma y aclaración

      // Define el ancho del campo para la firma y aclaración
      const signatureFieldWidth = 80;

      // Calcula la posición X para centrar el campo de la firma y aclaración
      const signatureFieldX = pageWidth / 2 + 15;

      // Dibuja un rectángulo para el área de firma y aclaración
      const signatureFieldHeight = 25; // Ajusta esto según la cantidad de texto que esperas

      // Calcula la posición Y para centrar el campo de la firma y aclaración
      const signatureFieldY = currentY - 28;

      // Dibuja el rectángulo
      doc.rect(
        signatureFieldX,
        signatureFieldY,
        signatureFieldWidth,
        signatureFieldHeight
      );

      // Define the Y position of the lines
      const lineY = signatureFieldY + 15; // Adjust this value to move the lines up or down

      // Define the X positions of the lines
      const firmaLineX1 = signatureFieldX + 2;
      const firmaLineX2 = firmaLineX1 + signatureFieldWidth / 2 - 5;
      const aclaracionLineX1 = firmaLineX2 + 10;
      const aclaracionLineX2 = signatureFieldX + signatureFieldWidth - 2;

      // Draw the lines
      doc.line(firmaLineX1, lineY, firmaLineX2, lineY);
      doc.line(aclaracionLineX1, lineY, aclaracionLineX2, lineY);

      // Add labels for the lines
      const firmaLabel = "Firma Asegurado";
      const aclaracionLabel = "Firma Tu Service";
      doc.setFontSize(10); // Adjust the font size if necessary
      doc.text(firmaLabel, firmaLineX1, lineY + 4); // Adjust the Y position if necessary
      doc.text(aclaracionLabel, aclaracionLineX1, lineY + 4); // Adjust the Y position if necessary

      currentY += 7; // Añade un espacio después del texto adicional

      // Dibuja un rectángulo para el área de observaciones
      const observationsRectHeight = 35; // Ajusta esto según la cantidad de texto que esperas

      // Define the height of the page
      const pageHeightCheck = (doc as any).internal.pageSize.height;

      // Check if there is enough space to draw the rectangle
      if (currentY + observationsRectHeight > pageHeightCheck - margin) {
        // If not, add a new page
        doc.addPage();
        // Reset the current Y position to the top margin
        currentY = margin;
      }
      doc.rect(margin, currentY, maxWidth, observationsRectHeight);

      // Agrega el título 'Observaciones' dentro del rectángulo
      const observationsLabel = "BREVE RELATO DE LO SUCEDIDO / DENUNCIA";
      doc.setFontSize(labelFontSize); // Tamaño de fuente para las etiquetas
      let textYPosition = currentY + lineHeight; // Posición Y para el título dentro del rectángulo

      // Aumenta un poco el margen izquierdo para el título
      const titleMargin = margin + 2; // Añade un pequeño espacio adicional
      doc.text(observationsLabel, titleMargin, textYPosition);

      // Ajusta la posición Y para el texto de las observaciones
      textYPosition += lineHeight; // Deja espacio para el título antes de agregar el texto

      // Añade el texto de las observaciones dentro del rectángulo
      doc.setFontSize(valueFontSize); // Tamaño de fuente para el texto dentro del rectángulo
      const observationsText = siniestro.ocurrenceDescription || "";
      const observationsLines = doc.splitTextToSize(
        observationsText,
        maxWidth - 2 * margin
      );

      // Imprime cada línea del texto de las observaciones
      observationsLines.forEach((line: string | string[]) => {
        doc.text(line, margin + 2, textYPosition); // Usa un pequeño margen dentro del rectángulo
        textYPosition += lineHeight; // Incrementa la posición Y para la siguiente línea
      });

      // Actualiza la posición Y si vas a agregar más contenido después
      currentY = textYPosition + lineHeight + 7;

      // Dibuja un rectángulo para el área de "OBSERVACIONES"
      const observationsFieldHeight = 25; // Ajusta esto según la cantidad de texto que esperas

      // Define the height of the page
      const pageHeightCheck2 = (doc as any).internal.pageSize.height;

      // Check if there is enough space to draw the rectangle
      if (currentY + observationsFieldHeight > pageHeightCheck2 - margin) {
        // If not, add a new page
        doc.addPage();
        // Reset the current Y position to the top margin
        currentY = margin;
      }
      doc.rect(margin, currentY, maxWidth, observationsFieldHeight);

      // Agrega el título 'OBSERVACIONES' dentro del rectángulo
      const observationsFieldLabel = "OBSERVACIONES:";
      doc.setFontSize(labelFontSize); // Tamaño de fuente para las etiquetas
      textYPosition = currentY + lineHeight; // Actualiza la posición Y para el título dentro del rectángulo
      doc.text(observationsFieldLabel, margin + 2, textYPosition);

      // Añade líneas sólidas para escritura
      let lineYPosition = textYPosition + lineHeight; // Comienza un poco más abajo del título
      const lineSpacing = 7; // Aumenta el espaciado entre líneas
      // Calcula cuántas líneas caben en el espacio dado
      const numberOfLines = Math.floor(
        (observationsFieldHeight - lineHeight) / lineSpacing
      );

      for (let i = 0; i < numberOfLines; i++) {
        doc.line(
          margin + 2,
          lineYPosition,
          margin + maxWidth - 2,
          lineYPosition
        ); // Dibuja la línea
        lineYPosition += lineSpacing;
      }

      doc.save("remito_siniestro.pdf");

      resolve(); // Resuelve la promesa cuando el PDF se ha generado y guardado correctamente
    } catch (error) {
      reject(error);
    }
  });
};
