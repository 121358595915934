import React, { useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";

interface BienSelectionDuplicateDialogProps {
  bienes: Bien[];
  onDuplicateSiniestro: (selectedBienes: number[]) => Promise<void>;
}

export const BienSelectionDuplicateDialog: React.FC<
  BienSelectionDuplicateDialogProps
> = ({ bienes, onDuplicateSiniestro }) => {
  const [selectedBienes, setSelectedBienes] = useState<number[]>([]);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isSelectionValid, setIsSelectionValid] = useState(false);

  const toggleBien = (bienId: number) => {
    setSelectedBienes((prevSelectedBienes) => {
      const newSelection = prevSelectedBienes.includes(bienId)
        ? prevSelectedBienes.filter((id) => id !== bienId)
        : [...prevSelectedBienes, bienId];
      setIsSelectionValid(newSelection.length > 0);
      return newSelection;
    });
  };

  const handleDuplicateSiniestro = async () => {
    setIsDuplicating(true);

    try {
      await onDuplicateSiniestro(selectedBienes);
    } catch (error) {}

    setIsDuplicating(false);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="default">Generar garantía</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Seleccione los bienes</DialogTitle>
          <DialogDescription>
            Seleccione los bienes que desea incluir en la garantía del siniestro
          </DialogDescription>
        </DialogHeader>
        {bienes.map((bien: Bien) => (
          <div key={bien.id} className="flex items-center justify-start">
            <Checkbox
              id={`bien-${bien.id}`}
              checked={selectedBienes.includes(bien.id)}
              onCheckedChange={() => {
                toggleBien(bien.id);
              }}
            />
            <label htmlFor={`bien-${bien.id}`} className="ml-2">
              {bien.itemType} - {bien.brandName} - {bien.model} - {bien.serial}
            </label>
          </div>
        ))}
        {!isSelectionValid && (
          <p className="text-red-500 text-sm mt-2">
            Por favor, seleccione al menos un bien.
          </p>
        )}
        <DialogFooter>
          <Button
            onClick={handleDuplicateSiniestro}
            disabled={isDuplicating || !isSelectionValid}
          >
            {isDuplicating ? "Generando..." : "Generar garantía"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
