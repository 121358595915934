import { create } from "zustand";

// Definiciones de interfaces para ambos formularios
interface AseguradoInfo {
  id: number;
  name: string;
  documentType: string;
  documentNumber: string;
}

interface DireccionInfo {
  id: number;
  street: string;
  number: string;
  floor?: string;
  apartment?: string;
  locality: string;
  province_id: string;
  zipcode?: string;
  telephone?: string;
  cellphone?: string;
  phonepas?: string;
  insured_id: number;
}

interface SiniestroInfo {
  incident_id: number;
  state_id: number;
  company_id: number;
  insured_id: number;
  ocurrence_address_id: number;
  responsible: number;
  preDenunciationNumber?: string;
  companyIncidentNumber: string;
  policyNumber: string;
  policyType: string;
  policyObservations?: string;
  denunciationDatetime: string;
  ocurrenceDatetime: string;
  ocurrenceDescription: string;
  ocurrenceObservations?: string;
  companyResponsible: string;
  contactEmail: string;
  coveredValue: number;
  type?: string;
  tcObservations?: string;
}

interface BienesState {
  bienesInfo: Bien[];
  isBienesCompleted: boolean;
  agregarBien: (bien: Bien) => void;
}

// Estado y acciones para el formulario de Siniestro
interface SiniestroState {
  siniestroInfo: SiniestroInfo | null;
  isSiniestroCompleted: boolean;
  setSiniestroInfo: (info: SiniestroInfo | null) => void;
}

// Estado y acciones para el formulario de Asegurado
interface AseguradoState {
  aseguradoInfo: AseguradoInfo | null;
  isAseguradoCompleted: boolean;
  isAseguradoConfirmed: boolean;
  setAseguradoInfo: (info: AseguradoInfo | null) => void;
  confirmAsegurado: () => void;
}

// Estado y acciones para el formulario de Dirección
interface DireccionState {
  direccionInfo: DireccionInfo | null;
  isDireccionCompleted: boolean;
  setDireccionInfo: (info: DireccionInfo | null) => void;
}

// Creando la tienda con Zustand para cada formulario
export const useAseguradoStore = create<AseguradoState>((set) => ({
  aseguradoInfo: null,
  isAseguradoCompleted: false,
  isAseguradoConfirmed: false,
  setAseguradoInfo: (info) => {
    set({
      aseguradoInfo: info,
      isAseguradoCompleted: true,
    });
  },
  confirmAsegurado: () => {
    set({ isAseguradoConfirmed: true });
  },
}));

export const useDireccionStore = create<DireccionState>((set) => ({
  direccionInfo: null,
  isDireccionCompleted: false,
  setDireccionInfo: (info) => {
    set({ direccionInfo: info, isDireccionCompleted: true });
  },
}));

export const useSiniestroFormStore = create<SiniestroState>((set) => ({
  siniestroInfo: null,
  isSiniestroCompleted: false,
  setSiniestroInfo: (info) => {
    set({ siniestroInfo: info, isSiniestroCompleted: true });
  },
}));

export const useBienesStore = create<BienesState>((set) => ({
  bienesInfo: [],
  isBienesCompleted: false,
  agregarBien: (bien) => {
    set((state) => ({
      bienesInfo: [...state.bienesInfo, bien],
      isBienesCompleted: true,
    }));
  },
}));
