import { apiFetch } from "./siniestroService";

export async function fetchHonorariosByCompanyId(
  companyId: number
): Promise<PredefinedHonorario[]> {
  const response = await apiFetch(
    `/api/ingresos-siniestros/predefined/${companyId}`
  );

  if (!response.ok) {
    throw new Error("Error al obtener los honorarios predefinidos");
  }

  const data = await response.json();
  return data.honorarios;
}

export async function addIngresoSiniestro(
  siniestroId: number,
  ingresoData: IngresoInput
): Promise<Ingreso> {
  const response = await apiFetch(`/api/ingresos/${siniestroId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ingresoData),
  });

  if (!response.ok) {
    throw new Error("Error al agregar el ingreso");
  }

  return response.json();
}

export async function fetchAllPredefinedHonorarios(
  page: number = 1,
  limit: number = 10
): Promise<{ honorarios: PredefinedHonorario[]; total: number }> {
  const response = await apiFetch(
    `/api/ingresos-siniestros/predefined?page=${page}&limit=${limit}`
  );

  if (!response.ok) {
    throw new Error("Error al obtener los honorarios predefinidos");
  }

  return response.json();
}

export async function createPredefinedHonorario(honorarioData: {
  company_id: number;
  description: string;
  amount: number;
}): Promise<PredefinedHonorario> {
  const response = await apiFetch("/api/ingresos-siniestros/predefined", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(honorarioData),
  });

  if (!response.ok) {
    throw new Error("Error al crear el honorario predefinido");
  }

  return response.json();
}

export async function updatePredefinedHonorario(
  id: number,
  updateData: Partial<PredefinedHonorario>
): Promise<PredefinedHonorario> {
  const response = await apiFetch(`/api/ingresos-siniestros/predefined/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateData),
  });

  if (!response.ok) {
    throw new Error("Error al actualizar el honorario predefinido");
  }

  return response.json();
}
