import { type SVGProps } from "react";

export function MdiPencilBoxMultipleOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4 6H2v14a2 2 0 0 0 2 2h14v-2H4V6m14.7 1.35l-1 1l-2.05-2.05l1-1c.21-.22.56-.22.77 0l1.28 1.28c.22.21.22.56 0 .77M9 12.94l6.06-6.06l2.06 2.06L11.06 15H9v-2.06M20 4v12H8V4h12m0-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2Z"
      />
    </svg>
  );
}
