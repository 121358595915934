import { useEffect, useState } from "react";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { apiFetch } from "@/services/siniestroService";
import { fetchAseguradoByDocument } from "@/services/aseguradoService";
import { MingcuteSearch3Line } from "@/components/icons/MingcuteSearch3Line";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ButtonLoading } from "@/components/ui/ButtonLoading";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useAseguradoStore, useDireccionStore } from "@/stores/formStore";
import { toast } from "sonner";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import Spinner from "@/components/ui/spinner";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { IonSearch } from "@/components/icons/IonSearch";
import { MdiCheckBold } from "@/components/icons/MdiCheckBold";
import * as z from "zod";

// Esquema de validación con Zod
const formSchema = z.object({
  name: z.string().min(1, "El nombre es requerido"),
  documentType: z.enum(["DNI", "CUIT"]),
  documentNumber: z.string().min(1, "El número de documento es requerido"),
});

interface FormValues {
  id: number;
  province_id: string;
  street: string;
  number: string;
  floor: string;
  apartment: string;
  zipcode: string;
  locality: string;
  telephone: string;
  cellphone: string;
  phonepas: string;
  insured_id: number;
}

export function AseguradoForm() {
  const { aseguradoInfo, setAseguradoInfo, confirmAsegurado } =
    useAseguradoStore();
  const { setDireccionInfo } = useDireccionStore(); // Importa y usa setDireccionInfo
  const [direccionInfo, setDireccionInfoState] = useState<FormValues | null>(
    null,
  ); // Define direccionInfo aquí
  const [isLoading, setIsLoading] = useState(false);
  const [aseguradoEncontrado, setAseguradoEncontrado] = useState(false);
  const navigate = useNavigate();
  const [siniestros, setSiniestros] = useState<Siniestro[]>([]);
  const [isNameFieldDisabled, setIsNameFieldDisabled] = useState(true);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      documentType: "DNI",
      documentNumber: "",
    },
  });
  const {
    field: { ...inputProps },
  } = useController({
    name: "documentType",
    control: form.control,
  });

  const { setValue } = form; // Desestructura setValue de useForm

  const buscarAsegurado = async () => {
    const documentNumber = form.getValues("documentNumber");
    const parsedNumber = parseInt(documentNumber, 10);
    if (!isNaN(parsedNumber)) {
      setIsLoading(true);
      try {
        const documentType = form.getValues("documentType");
        const result = await fetchAseguradoByDocument(
          documentType,
          parsedNumber,
        );
        if (result?.asegurado) {
          setAseguradoEncontrado(true);
          setValue("name", result.asegurado.name);
          setAseguradoInfo(result.asegurado);
        } else {
          setAseguradoEncontrado(false);
          setIsNameFieldDisabled(false);
          toast.info(
            "No se encontró un asegurado con ese número de documento. Por favor, ingrese el nombre para crear un nuevo asegurado.",
          );
        }
      } catch (error) {
        toast.error("Error al buscar el asegurado");
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchSiniestros = async () => {
      if (
        aseguradoInfo &&
        aseguradoInfo.documentNumber &&
        aseguradoInfo.documentType
      ) {
        try {
          if (aseguradoInfo.documentType === "DNI") {
            const response = await apiFetch(
              `/api/siniestros/dni/${aseguradoInfo.documentNumber}`,
            );
            const data = await response.json();
            setSiniestros(data.siniestros);
          } else if (aseguradoInfo.documentType === "CUIT") {
            const response = await apiFetch(
              `/api/siniestros/cuit/${aseguradoInfo.documentNumber}`,
            );
            const data = await response.json();
            setSiniestros(data.siniestros);
          }
        } catch (error) {
          setSiniestros([]);
        }
      }
    };

    fetchSiniestros();
  }, [aseguradoInfo]);

  const confirmarEleccion = () => {
    if (aseguradoInfo) {
      const newDireccionInfo: FormValues = {
        id: direccionInfo?.id || 0, // Proporciona un valor por defecto para id
        insured_id: aseguradoInfo.id,
        province_id: direccionInfo?.province_id || "",
        street: direccionInfo?.street || "",
        number: direccionInfo?.number || "",
        floor: direccionInfo?.floor || "",
        apartment: direccionInfo?.apartment || "",
        zipcode: direccionInfo?.zipcode || "",
        locality: direccionInfo?.locality || "",
        telephone: direccionInfo?.telephone || "",
        cellphone: direccionInfo?.cellphone || "",
        phonepas: direccionInfo?.phonepas || "",
      };
      setDireccionInfoState(newDireccionInfo);
      setDireccionInfo(newDireccionInfo);
      toast.success("Asegurado seleccionado correctamente");
      confirmAsegurado();
      navigate("/nuevo-siniestro/nueva-direccion");
    }
  };

  const reiniciarFormulario = () => {
    form.reset({
      name: "",
      documentType: "DNI",
      documentNumber: "",
    });
    setAseguradoInfo(null);
    setAseguradoEncontrado(false);
  };

  const onSubmit = async (data: any) => {
    if (!aseguradoEncontrado) {
      setIsLoading(true);
      try {
        const formattedData = {
          ...data,
          name: data.name.toUpperCase(),
          documentType: data.documentType,
        };
        const response = await apiFetch(`/api/asegurados`, {
          method: "POST",
          body: JSON.stringify(formattedData),
        });
        const result = await response.json();
        if (response.ok) {
          setAseguradoInfo({
            id: result.asegurado.id,
            ...result.asegurado,
          });
          toast.success("Asegurado procesado correctamente");
          navigate("/nuevo-siniestro/nueva-direccion");
        } else {
          throw new Error(result.message || "Error al procesar el asegurado");
        }
      } catch (error) {
        toast.error("Error al crear el asegurado");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="flex-1 p-4">
          <h1 className="text-2xl font-bold mb-4 dark:text-white">Asegurado</h1>
          <p className="text-gray-500 mb-8 dark:text-gray-300">
            Para comenzar, ingrese el número de documento del asegurado.
          </p>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 bg-white dark:bg-gray-800 p-8 rounded-lg"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name="documentType"
                  render={({}) => (
                    <FormItem>
                      <FormLabel>Tipo de Documento</FormLabel>
                      <Select
                        {...inputProps}
                        onValueChange={inputProps.onChange}
                        value={inputProps.value}
                        disabled={!!aseguradoInfo}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="DNI">DNI</SelectItem>
                          <SelectItem value="CUIT">CUIT</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="documentNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Número de Documento</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Número de Documento"
                          {...field}
                          onChange={(e) =>
                            field.onChange(e.target.value.trim())
                          }
                          disabled={!!aseguradoInfo}
                        />
                      </FormControl>
                      <FormDescription>
                        Para cargar un asegurado, primero debes buscar el DNI o
                        CUIT.
                      </FormDescription>
                      <Button
                        variant="outline"
                        onClick={buscarAsegurado}
                        disabled={isLoading || aseguradoEncontrado}
                        className="mt-2"
                      >
                        {isLoading ? "Buscando..." : <MingcuteSearch3Line />}
                      </Button>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nombre</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Ejemplo: Apellido, Nombres"
                        disabled={isNameFieldDisabled || !!aseguradoInfo}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-center">
                {isLoading ? (
                  <ButtonLoading />
                ) : (
                  <Button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    disabled={
                      aseguradoEncontrado || isLoading || isNameFieldDisabled
                    }
                  >
                    Crear Asegurado
                  </Button>
                )}
              </div>
            </form>
          </Form>
        </div>
        {aseguradoInfo && (
          <div className="flex-1 p-4">
            <Card>
              <CardHeader>
                <CardTitle>Información del asegurado</CardTitle>
              </CardHeader>
              <CardContent>
                <p>
                  <strong>Nombre:</strong> {aseguradoInfo.name}
                </p>
                <p>
                  <strong>Tipo de Documento:</strong>{" "}
                  {aseguradoInfo.documentType}
                </p>
                <p>
                  <strong>Número de Documento:</strong>{" "}
                  {aseguradoInfo.documentNumber}
                </p>
                <div className="flex justify-center mt-4">
                  <Button
                    onClick={confirmarEleccion}
                    variant="green"
                    className="m-2"
                  >
                    <MdiCheckBold className="mr-2" />
                    Confirmar elección
                  </Button>
                  <Button
                    onClick={reiniciarFormulario}
                    variant="default"
                    className="m-2"
                  >
                    <IonSearch className="mr-2" />
                    Buscar otro Asegurado
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
      {aseguradoInfo && (
        <div className="w-full mt-4">
          <Table>
            <TableCaption>Siniestros del asegurado</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Nº Siniestro</TableHead>
                <TableHead>Estado</TableHead>
                <TableHead>Compañía</TableHead>
                <TableHead>Asegurado</TableHead>
                <TableHead>DNI</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {siniestros?.length > 0 ? (
                siniestros.map((siniestro) => (
                  <TableRow key={siniestro.id}>
                    <TableCell>{siniestro.companyIncidentNumber}</TableCell>
                    <TableCell>{siniestro.stateName}</TableCell>
                    <TableCell>{siniestro.companyName}</TableCell>
                    <TableCell>{siniestro.insuredName}</TableCell>
                    <TableCell>{siniestro.insuredDocumentNumber}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className="text-center">
                    No hay siniestros disponibles para este asegurado.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
      {isLoading && <Spinner />}
    </>
  );
}
