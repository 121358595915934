import { useAuth } from "@/contexts/AuthContext";
import { FacturaColumns } from "./FacturaColumns";
import { FacturaDataTable } from "./FacturasDataTable";
import NotFoundPage from "../pages/NotFoundPage";

function FacturasList() {
  const columns = FacturaColumns(() => {});
  const { roles } = useAuth();
  const hasRole2 = roles.includes(2);

  if (!hasRole2) {
    return <NotFoundPage />;
  }
  return (
    <div className="container mx-auto">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h1 className="text-3xl font-semibold mb-4 md:mb-0 text-center md:text-left">
          Facturas
        </h1>
      </div>
      <FacturaDataTable columns={columns} currentPage={0} />
    </div>
  );
}

export default FacturasList;
