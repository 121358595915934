/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { Card, CardContent } from "@/components/ui/card";
import Spinner from "@/components/ui/spinner";
import {
  apiFetch,
  fetchAccionesBySiniestroId,
} from "@/services/siniestroService";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { FaHistory, FaUserCircle, FaClock, FaBox } from "react-icons/fa";

interface AccionesSiniestroProps {
  siniestroId: number;
}

const idsAccionBien = [25, 29, 32, 41, 33, 46, 40, 42];

const AccionesSiniestro = ({ siniestroId }: AccionesSiniestroProps) => {
  const [acciones, setAcciones] = useState<AccionBySiniestro[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bienes, setBienes] = useState<Bien[]>([]);

  const fetchBienes = async (siniestroId: number) => {
    try {
      const response = await apiFetch(`/api/siniestros/${siniestroId}/bienes`);
      if (!response.ok) {
        throw new Error(`Error HTTP: ${response.status}`);
      }
      const data = await response.json();
      return data.bienes;
    } catch (error) {
      console.error("Error al obtener bienes:", error);
      return [];
    }
  };

  useEffect(() => {
    const loadAccionesYBienes = async () => {
      setIsLoading(true);
      try {
        const fetchedAcciones = await fetchAccionesBySiniestroId(siniestroId);
        setAcciones(fetchedAcciones);
        const fetchedBienes = await fetchBienes(siniestroId);
        setBienes(fetchedBienes);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    void loadAccionesYBienes();
  }, [siniestroId]);

  const getBienName = (itemId: string | number) => {
    if (!itemId || itemId === "NULL") {
      return "N/A";
    }
    const bien = bienes.find((b) => b.id.toString() === itemId.toString());
    if (bien) {
      return `${bien.itemType} ${bien.model || ""} ${bien.brandName || ""}`;
    }
    return "Bien desconocido";
  };
  return (
    <CardContent className="p-6">
      <Card className="bg-gradient-to-br from-indigo-50 to-indigo-100 dark:from-gray-800 dark:to-gray-700 p-6 rounded-lg shadow-lg">
        <h3 className="font-bold text-xl text-indigo-700 dark:text-indigo-300 mb-6 flex items-center">
          <FaHistory className="mr-3" /> Acciones del siniestro
        </h3>
        <div className="space-y-4 max-h-[32rem] overflow-y-auto pr-2 custom-scrollbar">
          {isLoading ? (
            <Spinner />
          ) : (
            acciones.map((accion) => (
              <div
                key={accion.id}
                className="bg-white dark:bg-gray-900 p-4 rounded-2xl"
              >
                <div className="flex items-center justify-between text-sm text-gray-600 dark:text-gray-300 mb-2">
                  <div className="flex items-center">
                    <FaUserCircle className="mr-2 text-indigo-500 dark:text-indigo-400" />
                    <span className="font-semibold text-indigo-600 dark:text-indigo-300">
                      {accion.userName}
                    </span>
                  </div>
                  <div className="flex items-center text-gray-500 dark:text-gray-400">
                    <FaClock className="mr-2" />
                    <span>
                      {format(parseISO(accion.insertedAt), "dd/MM/yyyy HH:mm")}
                    </span>
                  </div>
                </div>
                <p className="text-gray-700 dark:text-gray-200 mt-2 flex items-center flex-wrap">
                  <span className="text-blue-800 mr-1">{accion.userName}</span>
                  <span className="mr-1">{accion.TEXTO}</span>
                  {idsAccionBien.includes(accion.idActionType) &&
                  accion.item_id ? (
                    <span className="font-semibold flex items-center">
                      <FaBox className="mx-1 text-indigo-500 dark:text-indigo-400" />
                      <span className="mr-1">bien</span>"
                      {getBienName(accion.item_id)}"
                    </span>
                  ) : idsAccionBien.includes(accion.idActionType) ? (
                    <span className="font-semibold mr-1">bien</span>
                  ) : accion.idActionType === 101 ||
                    accion.idActionType === 552 ||
                    accion.idActionType === 551 ? (
                    <span className="font-semibold text-indigo-600 dark:text-indigo-300 mr-1">
                      {accion.userAssignedName}
                    </span>
                  ) : accion.idActionType !== 53 ? (
                    <span className="font-semibold mr-1">siniestro</span>
                  ) : null}
                </p>
              </div>
            ))
          )}
        </div>
      </Card>
    </CardContent>
  );
};

export default AccionesSiniestro;
