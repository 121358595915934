import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  pageCount: number;
  total: number;
}
export function DataTablePagination<TData>({
  table,
  pageCount,
  total,
}: DataTablePaginationProps<TData>) {
  const formattedTotal = new Intl.NumberFormat().format(total);
  const currentPage = table.getState().pagination.pageIndex + 1;
  const showPagination = total > 0;

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between px-2 mt-4 mb-8">
      <div className="text-sm font-medium mb-4 lg:mb-0">
        Total de resultados:{" "}
        <span className="text-primary">{formattedTotal}</span>
      </div>
      {showPagination && (
        <div className="flex items-center space-x-6 lg:space-x-8">
          <div className="text-sm font-medium">
            Página {currentPage} de {pageCount}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Ir a la primera página</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Ir a la página anterior</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.nextPage()}
              disabled={currentPage >= pageCount}
            >
              <span className="sr-only">Ir a la página siguiente</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(pageCount - 1)}
              disabled={currentPage >= pageCount}
            >
              <span className="sr-only">Ir a la última página</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
