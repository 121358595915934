const BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

export const fetchResoluciones = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/resoluciones`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.status === 403) {
      throw new Error("USER_DISABLED");
    }

    if (!response.ok) {
      throw new Error("Failed to fetch resoluciones");
    }

    const data = await response.json();
    return data.resoluciones;
  } catch (error) {
    console.error("Error fetching resoluciones:", error);
    throw error;
  }
};

export const createResolucion = async (values: any) => {
  try {
    const response = await fetch(`${BASE_URL}/api/resoluciones`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",

      body: JSON.stringify(values),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creando resolución:", error);
    throw error;
  }
};

export const updateResolucion = async (resolucionId: number, values: any) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/resoluciones/${resolucionId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(values),
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Hubo un error actualizando la resolución:", error);
    throw error;
  }
};

export const deleteResolucion = async (resolucionId: number, token: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/resoluciones/${resolucionId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Hubo un error eliminando la resolución:", error);
    throw error;
  }
};

export const getResolucion = async (resolucionId: number) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/resoluciones/${resolucionId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Hubo un error obteniendo la resolución:", error);
    throw error;
  }
};

export const checkResolucionNameExists = async (
  name: string,
  token: string,
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/resoluciones/check-name?name=${name}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status === 403) {
      throw new Error("USER_DISABLED");
    }

    if (!response.ok) {
      throw new Error("Failed to check resolucion name");
    }

    const data = await response.json();
    return data.exists;
  } catch (error) {
    console.error("Error checking resolucion name:", error);
    throw error;
  }
};
