import { useEffect, useState } from "react";
import { apiFetch } from "@/services/siniestroService";
import AsignacionesCard from "./AsignacionesCard";
import MensajesCard from "./MensajesCard";
import Spinner from "../ui/spinner";
import { useAuth } from "@/contexts/AuthContext";
import { RocketIcon } from "@radix-ui/react-icons";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import useNotificationStore from "@/stores/notificationStore"; // Importa el store

interface Notificacion {
  id: number;
  usuario: number;
  fecha: string;
  mensaje: string;
  leido: string;
  tipo: string;
}

const NotificacionesPage: React.FC = () => {
  const [notificaciones, setNotificaciones] = useState<Notificacion[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { username } = useAuth();
  const { setUnreadCount } = useNotificationStore();
  const [currentPageAsignaciones, setCurrentPageAsignaciones] =
    useState<number>(1);
  const [totalAsignaciones, setTotalAsignaciones] = useState<number>(0);
  const [currentPageMensajes, setCurrentPageMensajes] = useState<number>(1);
  const [totalMensajes, setTotalMensajes] = useState<number>(0);
  const pageSize = 10;

  useEffect(() => {
    const fetchNotificaciones = async () => {
      if (!username) {
        setLoading(false);
        setError("No hay usuario logueado");
        return;
      }

      setLoading(true);

      try {
        const [asignacionesResponse, mensajesResponse] = await Promise.all([
          apiFetch(
            `/api/notificaciones/${username}/asignacion?offset=${
              (currentPageAsignaciones - 1) * pageSize
            }&limit=${pageSize}`
          ),
          apiFetch(
            `/api/notificaciones/${username}/mensaje?offset=${
              (currentPageMensajes - 1) * pageSize
            }&limit=${pageSize}`
          ),
        ]);

        const [asignaciones, mensajes] = await Promise.all([
          asignacionesResponse.json(),
          mensajesResponse.json(),
        ]);

        setNotificaciones([
          ...asignaciones.notificaciones,
          ...mensajes.notificaciones,
        ]);
        setTotalAsignaciones(asignaciones.total);
        setTotalMensajes(mensajes.total);
      } catch (error) {
        setError("Error al cargar notificaciones");
      } finally {
        setLoading(false);
      }
    };

    fetchNotificaciones();
  }, [username, currentPageAsignaciones, currentPageMensajes, pageSize]);

  const handleMarkAsRead = async (notificacionId: number, leido: boolean) => {
    try {
      // Primero, envía la actualización al backend
      const response = await apiFetch(`/api/notificaciones/${notificacionId}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ leido }),
      });

      if (!response.ok) throw new Error("Error al actualizar la notificación");

      // Luego, recalcula el contador de no leídos desde el backend
      const countResponse = await apiFetch(
        `/api/notificaciones/${username}/unread-count`
      );
      if (countResponse.ok) {
        const { unreadCount } = await countResponse.json();
        setUnreadCount(unreadCount); // Actualiza el estado de unreadCount con el valor correcto
      }

      // Finalmente, actualiza las notificaciones locales con los cambios confirmados
      setNotificaciones((prevNotificaciones) =>
        prevNotificaciones.map((notificacion) =>
          notificacion.id === notificacionId
            ? { ...notificacion, leido: leido ? "1" : "0" }
            : notificacion
        )
      );
    } catch (err) {
      console.error("Error al marcar como leído/no leído", err);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-red-500">Error: {error}</p>
      </div>
    );
  }

  // si no hay notificaciones
  if (notificaciones.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-gray-500">No hay notificaciones</p>
      </div>
    );
  }

  const asignaciones = notificaciones.filter(
    (notificacion) => notificacion.tipo === "asignacion"
  );
  const mensajes = notificaciones.filter(
    (notificacion) => notificacion.tipo === "mensaje"
  );

  return (
    <div className="w-full p-10">
      <Alert className="mb-4 bg-accent text-accent-foreground">
        <RocketIcon className="h-4 w-4" />
        <AlertTitle>
          ¡Bienvenido a la bandeja de entrada de notificaciones!
        </AlertTitle>
        <AlertDescription>
          Dale click derecho a cada mensaje para marcar como leído, ir al
          siniestro, etc.
        </AlertDescription>
      </Alert>
      <div className="mb-4">
        <h1 className="text-2xl font-bold">Bandeja de entrada</h1>
      </div>
      <ResizablePanelGroup
        direction="horizontal"
        className="min-h-[200px] w-full rounded-lg border"
      >
        <ResizablePanel defaultSize={50}>
          <div className="flex h-full items-center justify-center p-6">
            <div className="w-full">
              <AsignacionesCard
                asignaciones={asignaciones}
                handleMarkAsRead={handleMarkAsRead}
                totalAsignaciones={totalAsignaciones}
                currentPage={currentPageAsignaciones}
                onPageChange={setCurrentPageAsignaciones}
              />
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={50}>
          <div className="flex h-full items-center justify-center p-6">
            <div className="w-full">
              <MensajesCard
                mensajes={mensajes}
                handleMarkAsRead={handleMarkAsRead}
                totalMensajes={totalMensajes}
                currentPage={currentPageMensajes}
                onPageChange={setCurrentPageMensajes}
              />
            </div>
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};

export default NotificacionesPage;
