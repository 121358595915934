import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { TdesignMoney } from "@/components/icons/TdesignMoney";
import { formattedValueARS } from "../SiniestroDetails/formattedValue";
import { Separator } from "@/components/ui/separator";

interface EditBienDialogProps {
  bienId: number;
  tipoResolucion:
    | "Rechazado"
    | "Liquidado"
    | "Reparado"
    | "Reingreso por garantía"
    | "Desistido";
  valorReclamado?: number | null;
  valorAReconocer?: number | null;
  valorReparado?: number | null;
  sumaAsegurada?: number;
  coberturas?: Cobertura[];
  onSave: (bienId: number, valores: SaveBienValues) => Promise<void>;
  onDetailsSaved: (bienId: number, updatedDetails: any) => void;
  disabled?: boolean;
}

export function EditBienDialog({
  bienId,
  tipoResolucion,
  valorReclamado = 0,
  valorAReconocer = 0,
  valorReparado = 0,
  sumaAsegurada, // Recibe la suma asegurada
  coberturas, // Recibe las coberturas
  onSave,
  onDetailsSaved,
  disabled = false,
}: EditBienDialogProps) {
  const [valorReclamadoInput, setValorReclamadoInput] = useState(
    valorReclamado !== null ? valorReclamado.toString() : ""
  );
  const [valorAReconocerInput, setValorAReconocerInput] = useState(
    valorAReconocer !== null ? valorAReconocer.toString() : ""
  );
  const [valorReparadoInput, setValorReparadoInput] = useState(
    valorReparado !== null ? valorReparado.toString() : ""
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const handleSave = () => {
    let isValid = true;
    const valorReclamadoParsed = parseFloat(
      valorReclamadoInput.replace(",", ".")
    );
    const valorAReconocerParsed = parseFloat(
      valorAReconocerInput.replace(",", ".")
    );
    const valorReparadoParsed = parseFloat(
      valorReparadoInput.replace(",", ".")
    );

    // Validar los valores basados en la resolución
    if (
      tipoResolucion === "Liquidado" ||
      (tipoResolucion === "Reparado" && valorAReconocerInput !== "")
    ) {
      if (
        valorAReconocerInput === "" ||
        valorAReconocerParsed < 0 ||
        valorAReconocerParsed > valorReclamadoParsed
      ) {
        setErrorMessage(
          "El valor a reconocer no puede estar vacío, ser negativo o mayor que el valor reclamado."
        );
        isValid = false;
      }
    }

    if (
      tipoResolucion === "Reparado" &&
      (valorReparadoInput === "" ||
        valorReparadoParsed < 0 ||
        valorReparadoParsed > valorReclamadoParsed)
    ) {
      setErrorMessage(
        "El valor reparado no puede estar vacío, ser negativo o mayor que el valor reclamado."
      );
      isValid = false;
    }

    if (isValid) {
      const saveData = {
        valorReclamado: valorReclamadoParsed,
        valorAReconocer:
          tipoResolucion === "Liquidado" || tipoResolucion === "Reparado"
            ? valorAReconocerParsed
            : null,
        valorReparado:
          tipoResolucion === "Reparado" ? valorReparadoParsed : null,
      };

      void onSave(bienId, saveData);

      onDetailsSaved(bienId, {
        claimedValue: valorReclamadoParsed,
        recognizedValue:
          tipoResolucion === "Liquidado" || tipoResolucion === "Reparado"
            ? valorAReconocerParsed
            : null,
        repairedRecognizedValue:
          tipoResolucion === "Reparado" ? valorReparadoParsed : null,
      });

      // Limpiar el mensaje de error
      setErrorMessage(null);

      // Cerrar el modal
      setOpen(false);
    }
  };

  const handleInputChange = (
    setValue: React.Dispatch<React.SetStateAction<string>>,
    value: string
  ) => {
    // Convertir la entrada a un número
    const parsedValue = parseFloat(value.replace(",", "."));

    // Permitir valores mayores o iguales a 0 y menores o iguales al valor reclamado
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setValue(value);
      setErrorMessage(null);
    } else {
      setErrorMessage("El valor no puede ser negativo.");
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" disabled={disabled}>
          <TdesignMoney className="w-5 h-5 mr-2" />
          Editar valores
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editar valor</DialogTitle>
          <DialogDescription>
            Cambia el valor aquí y haz clic en guardar cuando termines.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {/* Mostrar Suma Asegurada y Coberturas como ayuda */}
          {sumaAsegurada && (
            <div className="grid grid-cols-4 items-center gap-4">
              <Label className="text-right">Suma Asegurada:</Label>
              <div className="col-span-3">
                {formattedValueARS(sumaAsegurada)}
              </div>
            </div>
          )}
          {coberturas && coberturas.length > 0 && (
            <>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label className="text-right">Coberturas:</Label>
                <div className="col-span-3">
                  {coberturas.map((cobertura, index) => (
                    <div key={index} className="mb-2">
                      <p className="font-semibold">
                        {cobertura.nombre_cobertura}
                      </p>
                      <p>
                        Suma Asegurada:{" "}
                        {formattedValueARS(cobertura.suma_poliza)}
                      </p>
                      {cobertura.observaciones && (
                        <p>Observaciones: {cobertura.observaciones}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <Separator className="my-4" />
            </>
          )}

          {/* Valor Reclamado siempre se muestra */}
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="valorReclamadoInput" className="text-right">
              Valor reclamado
            </Label>
            <Input
              id="valorReclamadoInput"
              type="number"
              value={valorReclamadoInput}
              onChange={(e) => {
                setValorReclamadoInput(e.target.value);
              }}
              className="col-span-3"
            />
          </div>
          <Separator className="my-4" />
          {/* Mostrar estos campos basados en el tipo de resolución */}
          {tipoResolucion === "Liquidado" && (
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="valorAReconocerInput" className="text-right">
                Valor a reconocer
              </Label>
              <Input
                id="valorAReconocerInput"
                type="number"
                value={valorAReconocerInput}
                onChange={(e) =>
                  handleInputChange(setValorAReconocerInput, e.target.value)
                }
                className="col-span-3"
              />
            </div>
          )}
          {tipoResolucion === "Reparado" && (
            <>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="valorReparadoInput" className="text-right">
                  Valor reparado
                </Label>
                <Input
                  id="valorReparadoInput"
                  type="number"
                  value={valorReparadoInput}
                  onChange={(e) =>
                    handleInputChange(setValorReparadoInput, e.target.value)
                  }
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="valorAReconocerInput" className="text-right">
                  Valor a reconocer
                </Label>
                <Input
                  id="valorAReconocerInput"
                  type="number"
                  value={valorAReconocerInput}
                  onChange={(e) =>
                    handleInputChange(setValorAReconocerInput, e.target.value)
                  }
                  className="col-span-3"
                />
              </div>
            </>
          )}
        </div>
        {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
        <Separator className="my-4" />
        <DialogFooter>
          <Button onClick={handleSave}>Guardar cambios</Button>
          <DialogClose asChild>
            <Button variant="secondary">Cerrar</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
