/* eslint-disable @typescript-eslint/no-misused-promises */
import { create } from "zustand";

import { fetchSiniestros, getSiniestroById } from "@/services/siniestroService";
import useAuthStore from "./authStore";

interface SiniestroState {
  addHistorialMessage: (
    siniestroId: number,
    newMessage: HistorialMessage,
  ) => void;
  siniestros: Siniestro[];
  historialSiniestro: HistorialMessage[];
  isLoading: boolean;
  currentPage: number;
  fetchSiniestros: () => Promise<void>;
  fetchSiniestroDetails: (siniestroId: number) => Promise<void>;
  updateSiniestro: (siniestroId: number, values: any) => Promise<void>;
  setCurrentPage: (page: number) => void;
  editDialogId: number | null;
  setEditDialogId: (id: number | null) => void;
  setLoading: (loading: boolean) => void;
  editingSiniestroId: number | null;
  setEditingSiniestroId: (id: number | null) => void;
  updateDetailsSiniestro: (updatedSiniestro: Siniestro) => void;
  isAuthenticated: boolean;
  isUpdatingState: boolean; // Añadir esta línea
  setIsUpdatingState: (updating: boolean) => void; // Añadir esta línea
}

export const useSiniestroStore = create<SiniestroState>((set, get) => ({
  isAuthenticated: useAuthStore.getState().isAuthenticated,
  siniestros: [],
  historialSiniestro: [],
  isLoading: false,
  currentPage: 0,
  fetchSiniestros: async () => {
    if (!get().isAuthenticated) {
      return;
    }

    try {
      const siniestrosData = await fetchSiniestros();
      set({ siniestros: siniestrosData.siniestros });
    } catch (error) {
      console.error("Error fetching siniestros in store:", error);
      if (error instanceof Error) {
        if (error.message === "USER_DISABLED") {
          useAuthStore.getState().setUserDisabled(true);
        } else if (error.message === "UNAUTHORIZED") {
          useAuthStore.getState().logout();
        }
      }
      throw error;
    }
  },

  fetchSiniestroDetails: async (siniestroId: number) => {
    set({ isLoading: true });
    try {
      const siniestroDetails = await getSiniestroById(siniestroId);
      set((state) => ({
        ...state,
        siniestros: state.siniestros.map((s) =>
          s.id === siniestroId ? siniestroDetails : s,
        ),
      }));
    } catch (error) {
      console.error("Error fetching siniestro details:", error);
    } finally {
      set({ isLoading: false });
    }
  },
  editingSiniestroId: null,
  setEditingSiniestroId: (id) => {
    set({ editingSiniestroId: id });
  },
  updateSiniestro: async (
    siniestroId: number,
    updates: Partial<Siniestro>,
  ): Promise<void> => {
    set((state) => {
      const siniestroIndex = state.siniestros.findIndex(
        (s) => s.id === siniestroId,
      );

      if (siniestroIndex !== -1) {
        const updatedSiniestros = [...state.siniestros]; // Create a copy of the siniestros array
        updatedSiniestros[siniestroIndex] = {
          ...state.siniestros[siniestroIndex],
          ...updates,
        };

        return { siniestros: updatedSiniestros }; // Return an object with the updated 'siniestros' array
      }

      return state;
    });

    return Promise.resolve();
  },

  updateDetailsSiniestro: (updatedSiniestro: Siniestro) => {
    set((state) => ({
      siniestros: state.siniestros.map((s) =>
        s.id === updatedSiniestro.id ? updatedSiniestro : s,
      ),
    }));
  },
  setCurrentPage: (page) => {
    set({ currentPage: page });
  },
  editDialogId: null,
  setEditDialogId: (id) => {
    set({ editDialogId: id });
  },
  setLoading: (loading) => {
    set({ isLoading: loading });
  },
  addHistorialMessage: (siniestroId, newMessage) => {
    set((state) => {
      const siniestroIndex = state.siniestros.findIndex(
        (s) => s.id === siniestroId,
      );
      if (siniestroIndex !== -1) {
        const updatedSiniestro = {
          ...state.siniestros[siniestroIndex],
          historial: [
            ...state.siniestros[siniestroIndex].historial,
            newMessage,
          ],
        };
        const updatedSiniestros = [...state.siniestros];
        updatedSiniestros[siniestroIndex] = updatedSiniestro;
        return { ...state, siniestros: updatedSiniestros };
      }
      return state;
    });
  },
  updateHistorialSiniestro: (_siniestroId: any, newMessage: any) => {
    const currentHistorial = get().historialSiniestro;
    const updatedHistorial = [...currentHistorial, newMessage];
    set({ historialSiniestro: updatedHistorial });
  },
  isUpdatingState: false, // Añadir esta línea
  setIsUpdatingState: (updating) => set({ isUpdatingState: updating }), // Añadir esta línea
}));
