import { type SVGProps } from "react";

export function IconamoonDeliveryFastBold(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none">
        <path
          fill="currentColor"
          d="M2 3V1.75C1.31 1.75.75 2.31.75 3zm11 0h1.25c0-.69-.56-1.25-1.25-1.25zm0 6V7.75A1.25 1.25 0 0 0 11.75 9zM2 4.25h11v-2.5H2zM11.75 3v16h2.5V3zm-8.5 14V3H.75v14zM13 10.25h5v-2.5h-5zM20.75 13v4h2.5v-4zm-6.5 6V9h-2.5v10zm4.28.53a.75.75 0 0 1-1.06 0l-1.768 1.768a3.25 3.25 0 0 0 4.596 0zm-1.06-1.06a.75.75 0 0 1 1.06 0l1.768-1.768a3.25 3.25 0 0 0-4.596 0zM6.53 19.53a.75.75 0 0 1-1.06 0l-1.768 1.768a3.25 3.25 0 0 0 4.596 0zm-1.06-1.06a.75.75 0 0 1 1.06 0l1.768-1.768a3.25 3.25 0 0 0-4.596 0zm13.06 0a.74.74 0 0 1 .22.53h2.5a3.24 3.24 0 0 0-.952-2.298zm.22.53a.744.744 0 0 1-.22.53l1.768 1.768A3.244 3.244 0 0 0 21.25 19zM16 17.75h-3v2.5h3zm1.47 1.78a.744.744 0 0 1-.22-.53h-2.5c0 .83.318 1.664.952 2.298zm-.22-.53a.74.74 0 0 1 .22-.53l-1.768-1.768A3.244 3.244 0 0 0 14.75 19zm-11.78.53a.744.744 0 0 1-.22-.53h-2.5c0 .83.318 1.664.952 2.298zM5.25 19a.74.74 0 0 1 .22-.53l-1.768-1.768A3.244 3.244 0 0 0 2.75 19zM13 17.75H8v2.5h5zm-6.47.72a.74.74 0 0 1 .22.53h2.5c0-.83-.318-1.664-.952-2.298zm.22.53a.744.744 0 0 1-.22.53l1.768 1.768A3.244 3.244 0 0 0 9.25 19zm14-2a.75.75 0 0 1-.75.75v2.5A3.25 3.25 0 0 0 23.25 17zM18 10.25A2.75 2.75 0 0 1 20.75 13h2.5c0-2.9-2.35-5.25-5.25-5.25zM.75 17A3.25 3.25 0 0 0 4 20.25v-2.5a.75.75 0 0 1-.75-.75z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
          d="M2 8h3m-3 4h5"
        />
      </g>
    </svg>
  );
}
