/* eslint-disable @typescript-eslint/no-misused-promises */
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "../ui/button";
import { toast } from "sonner";
import Spinner from "../ui/spinner";
import useLoadingStore from "@/stores/loadingStore";
import { ReloadIcon } from "@radix-ui/react-icons";
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { useForm } from "react-hook-form";
import { fetchUserData, updateUser } from "@/services/userService";
import { useState, useEffect } from "react";
import useUserStore from "@/stores/userStore";

export const updateFormSchema = z.object({
  username: z
    .string()
    .min(2, {
      message: "El nombre de usuario debe tener al menos 2 caracteres.",
    })
    .optional(),
  password: z
    .string()
    .min(8, {
      message: "La contraseña debe tener al menos 8 caracteres.",
    })
    .optional(),
  email: z
    .string()
    .email({
      message: "Ingrese un email válido.",
    })
    .optional(),
  display_name: z
    .string()
    .min(2, {
      message: "El nombre a mostrar debe tener al menos 2 caracteres.",
    })
    .optional(),
  state: z.boolean().optional(),
  company_id: z
    .union([z.string().nullable(), z.number().nullable()])
    .optional(),
});

interface UpdateUserDialogProps {
  userId: number;
}

export function UpdateUserDialog({ userId }: UpdateUserDialogProps) {
  const [userData, setUserData] = useState<any>(null);
  const { isSaving, startSaving, stopSaving } = useLoadingStore();
  const form = useForm({
    resolver: zodResolver(updateFormSchema),
    defaultValues: {
      username: "",
      password: "",
      email: "",
      display_name: "",
      state: true,
      company_id: null,
    },
  });

  const { reset } = form;

  useEffect(() => {
    async function loadUserData() {
      try {
        const data = await fetchUserData(userId);
        setUserData(data);
        reset(data);
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        } else {
          toast.error("Ocurrió un error desconocido.");
        }
      }
    }

    void loadUserData();
  }, [userId, reset]);

  async function onSubmit(values: any) {
    startSaving(); // Activa el indicador de carga

    const payload: any = {};
    if (userData) {
      for (const key in values) {
        if (
          values[key] !== userData[key] &&
          values[key] !== undefined &&
          values[key] !== null
        ) {
          // Solo agregar la contraseña si no está vacía
          if (key !== "password" || values[key].length > 0) {
            payload[key] = values[key];
          }
        }
      }

      // Convertir el valor booleano de state a un valor adecuado para bit si está presente
      if (payload.hasOwnProperty("state")) {
        payload.state = payload.state ? 1 : 0;
      }
      // Asegúrate de incluir company_id aunque sea null
      payload.company_id = values.company_id ?? null;

      try {
        await updateUser(userId, payload);
        toast.success("Usuario actualizado con éxito.");

        // Solo cierra el diálogo y recarga los datos si la actualización es exitosa
        useUserStore.getState().setLoading(true); // Activa el Skeleton antes de recargar los datos
        await useUserStore.getState().fetchUsers(); // Recarga los datos
        useUserStore.getState().setEditDialogId(null); // Cierra el diálogo después de la recarga
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        } else {
          toast.error("Ocurrió un error desconocido.");
        }
      } finally {
        stopSaving(); // Desactiva el estado de guardado (Guardando...)
        useUserStore.getState().setLoading(false); // Desactiva el estado de carga (Skeleton)
      }
    } else {
      toast.error("Error al cargar los datos del usuario.");
      stopSaving();
    }
  }

  if (userData === null) {
    return (
      <div className="flex justify-center items-center h-32">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle className="text-center">Editar usuario</DialogTitle>
        <DialogDescription className="text-center">
          Ingrese los detalles del nuevo usuario a continuación.
        </DialogDescription>
      </DialogHeader>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid gap-4 py-4"
        >
          {/* Campo para username */}
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-right">Nombre de usuario</FormLabel>
                <FormControl>
                  <Input placeholder="Ingrese nombre de usuario" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Campo para password */}
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Contraseña</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Dejar en blanco para no cambiar la contraseña"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Campo para email */}
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="Ingrese email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Campo para display_name */}
          <FormField
            control={form.control}
            name="display_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nombre a mostrar</FormLabel>
                <FormControl>
                  <Input placeholder="Ingrese nombre a mostrar" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <DialogFooter className="mt-4">
            <Button type="submit" className="mt-2" disabled={isSaving}>
              {isSaving ? (
                <>
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                  Guardando...
                </>
              ) : (
                "Guardar"
              )}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </>
  );
}

export default UpdateUserDialog;
