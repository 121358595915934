import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEffect } from "react";

interface NumbersFilterProps {
  onChange: (value: string) => void;
  value: string | undefined;
  onSearch: () => void; // Nueva propiedad para manejar la búsqueda
}

export function NumbersFilter({
  value,
  onChange,
  onSearch,
}: NumbersFilterProps) {
  useEffect(() => {
    if (value === undefined) {
      onChange("insuredName");
    }
  }, [value, onChange]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Evita que el select se abra
      onSearch(); // Llama a la función de búsqueda
    }
  };

  return (
    <div onKeyDown={handleKeyDown}>
      <Select
        value={value}
        onValueChange={(value) => {
          onChange(value);
        }}
      >
        <SelectTrigger className="w-[203px]">
          <SelectValue placeholder="Selecciona un filtro" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Filtros</SelectLabel>
            <SelectItem value="dni">DNI</SelectItem>
            <SelectItem value="insuredName">Nombre del asegurado</SelectItem>
            <SelectItem value="siniestroNumber">Nº Siniestro</SelectItem>
            <SelectItem value="receiptNumber">Nº orden de trabajo</SelectItem>
            <SelectItem value="deliveryNumber">Nº de entrega</SelectItem>
            <SelectItem value="policyNumber">Nº Póliza</SelectItem>
            <SelectItem value="electroReceiptNumber">
              Nº remito electro
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
