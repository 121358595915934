/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { type SetStateAction, useEffect, useState } from "react";
import { Columns } from "./Columns";
import { DataTable } from "./DataTable";
import Spinner from "../ui/spinner";
import { AddUserDialog } from "./AddUserDialog";
import { useAuth } from "@/contexts/AuthContext"; // Usa el nuevo contexto de autenticación
import useUserStore from "@/stores/userStore";
import { PhUser } from "../icons/PhUser";
import StatesFilter from "./StatesFilter";
import NotFoundPage from "../pages/NotFoundPage";

function UsersList() {
  const { isAuthenticated, isDisabled, roles } = useAuth(); // Usa el contexto de autenticación
  const hasRole2 = roles.includes(2);

  if (!hasRole2) {
    return <NotFoundPage />;
  }
  const users = useUserStore((state) => state.users);
  const fetchUsers = useUserStore((state) => state.fetchUsers);
  const isLoading = useUserStore((state) => state.isLoading);
  const currentPage = useUserStore((state) => state.currentPage);

  // Estado para manejar el filtro seleccionado
  const [selectedFilter, setSelectedFilter] = useState("todos");

  // Filtra los datos basado en el filtro seleccionado
  const filteredUsers = users.filter((user) => {
    if (selectedFilter === "todos") return true;
    if (selectedFilter === "activo") return user.state;
    if (selectedFilter === "inactivo") return !user.state;
    return false;
  });

  // Función de callback para actualizar el filtro
  const handleFilterChange = (filter: SetStateAction<string>) => {
    setSelectedFilter(filter);
  };

  useEffect(() => {
    async function fetchDataWithToken() {
      await fetchUsers();
    }
    void fetchDataWithToken();
  }, [fetchUsers]);

  if (isDisabled) {
    return (
      <div className="container mx-auto py-10 px-4 text-center">
        <p>
          El usuario ha sido deshabilitado. Por favor, contacte al
          administrador.
        </p>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="container mx-auto py-10 px-4 text-center">
        <p>Por favor, inicie sesión para ver la lista de usuarios.</p>
      </div>
    );
  }

  if (users.length === 0) {
    return <Spinner />;
  }

  return (
    <div className="container mx-auto">
      {isAuthenticated ? (
        <>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <h1 className="text-3xl font-semibold mb-4 md:mb-0 text-center md:text-left">
              Usuarios <PhUser className="inline-block w-8 h-8" />
            </h1>
          </div>

          <DataTable
            columns={Columns}
            data={filteredUsers}
            currentPage={currentPage}
            headerRight={<AddUserDialog />}
            isLoading={isLoading}
            storeType="user"
            filterComponent={
              <StatesFilter onFilterChange={handleFilterChange} />
            } // Usa el componente StatesFilter
          />
        </>
      ) : (
        <p>Por favor, inicie sesión para ver la lista de usuarios.</p>
      )}
    </div>
  );
}

export default UsersList;
