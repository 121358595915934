import { create } from "zustand";

import {
  fetchResoluciones,
  updateResolucion,
} from "@/services/resolucionService";
import useAuthStore from "./authStore";

interface Resolucion {
  id: number;
  name: string;
}

interface ResolucionState {
  resoluciones: Resolucion[];
  isLoading: boolean;
  currentPage: number;
  fetchResoluciones: () => Promise<void>;
  updateResolucion: (resolucionId: number, values: any) => Promise<void>;
  setCurrentPage: (page: number) => void;
  editDialogId: number | null;
  setEditDialogId: (id: number | null) => void;
  setLoading: (loading: boolean) => void;
}

export const useResolucionStore = create<ResolucionState>((set) => ({
  resoluciones: [],
  isLoading: false,
  currentPage: 0,
  fetchResoluciones: async () => {
    try {
      const resolucionesData = await fetchResoluciones();
      set({ resoluciones: resolucionesData });
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "USER_DISABLED") {
          useAuthStore.getState().setUserDisabled(true);
        } else {
          throw error;
        }
      }
    }
  },
  updateResolucion: async (resolucionId, values) => {
    set({ isLoading: true }); // Establece el estado de carga antes de la operación
    try {
      await updateResolucion(resolucionId, values);
      // Luego de la operación exitosa puedes actualizar el estado global si es necesario
    } catch (error) {
      // Manejo de errores
    } finally {
      set({ isLoading: false }); // Restablecer el estado de carga al final de la operación
    }
  },
  setCurrentPage: (page) => {
    set({ currentPage: page });
  },
  editDialogId: null,
  setEditDialogId: (id) => {
    set({ editDialogId: id });
  },
  setLoading: (loading) => {
    set({ isLoading: loading });
  }, // Método para actualizar el estado de carga
}));
