// src/services/remitosService.ts
import { apiFetch } from "./siniestroService";

export const fetchRemitoByIncidentId = async (incidentId: number) => {
  const response = await apiFetch(
    `/api/remitos-electro/incident/${incidentId}`,
  );
  return response.json();
};

export const createRemito = async (
  numero_remito: string,
  incident_id: number,
) => {
  const response = await apiFetch(`/api/remitos-electro`, {
    method: "POST",
    body: JSON.stringify({ numero_remito, incident_id }),
  });
  return response.json();
};

export const updateRemito = async (id: number, numero_remito: string) => {
  const response = await apiFetch(`/api/remitos-electro/${id}`, {
    method: "PUT",
    body: JSON.stringify({ numero_remito }),
  });
  return response.json();
};
