import { apiFetch } from "./siniestroService";

export const fetchEstados = async () => {
  try {
    const response = await apiFetch("/api/estadosiniestros/");
    const data = await response.json();
    return data.estadoSiniestros; // Ajustado para coincidir con la respuesta del servidor
  } catch (error) {
    throw error;
  }
};

export const fetchEstado = async (id: string, token: string) => {
  try {
    const response = await apiFetch(`/api/estadosiniestros/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data.estadoSiniestros; // Ajustado para coincidir con la respuesta del servidor
  } catch (error) {
    throw error;
  }
};

export const createEstado = async (estado: { name: string }) => {
  try {
    const response = await apiFetch("/api/estadosiniestros/", {
      method: "POST",
      body: JSON.stringify(estado), // esto ya enviará el objeto correctamente
    });

    const data = await response.json();
    return data.estado;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error("Failed to create estado");
  }
};

export const getEstado = async (id: number) => {
  try {
    const response = await apiFetch(`/api/estadosiniestros/${id}`);

    const data = await response.json();
    return data.estadoSiniestro; // Ajustado para coincidir con la nueva estructura de la respuesta
  } catch (error) {
    throw error;
  }
};

export const updateEstado = async (id: number, estado: string) => {
  try {
    const response = await apiFetch(`/api/estadosiniestros/${id}`, {
      method: "PATCH",
      body: JSON.stringify(estado),
    });

    const data = await response.json();
    return data.estado;
  } catch (error) {
    throw error;
  }
};
