import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fetchProveedores } from "@/services/siniestroService";

interface Proveedor {
  id: number;
  username: string; // Si esta propiedad no está disponible, puedes usar una propiedad similar
  display_name: string;
}

interface ProveedorFilterProps {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
}

export const ProveedorFilter = ({ value, onChange }: ProveedorFilterProps) => {
  const [proveedores, setProveedores] = useState<Proveedor[]>([]);

  useEffect(() => {
    const loadProveedores = async () => {
      try {
        const tramitadores = await fetchProveedores();

        // Mapea los tramitadores a proveedores
        const mappedProveedores = tramitadores.map((tramitador) => ({
          id: tramitador.id,
          username: tramitador.display_name, // Usa display_name como username
          display_name: tramitador.display_name,
        }));

        setProveedores(mappedProveedores);
      } catch (error) {}
    };

    loadProveedores();
  }, []);

  return (
    <Select
      value={value !== undefined ? value.toString() : ""}
      onValueChange={(val) => onChange(val ? parseInt(val, 10) : undefined)}
    >
      <SelectTrigger className="w-[25%]">
        <SelectValue placeholder="Seleccionar proveedor" />
      </SelectTrigger>
      <SelectContent className="overflow-y-auto max-h-[20rem]">
        <SelectItem value="0">Todos</SelectItem>
        {proveedores.map((proveedor) => (
          <SelectItem key={proveedor.id} value={proveedor.id.toString()}>
            {proveedor.display_name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
