import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface NumbersFCFilterProps {
  onChange: (value: string) => void;
  value: string | undefined;
}

export function NumbersFCFilter({ value, onChange }: NumbersFCFilterProps) {
  return (
    <Select
      value={value}
      onValueChange={(value) => {
        onChange(value);
      }}
    >
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Selecciona un filtro" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Filtros</SelectLabel>
          <SelectItem value="facturaNumber">Núm. Factura</SelectItem>
          <SelectItem value="siniestroNumber">Núm. Siniestro</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
