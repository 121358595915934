import { useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { ReloadIcon } from "@radix-ui/react-icons";
import { CarbonDeliveryAdd } from "@/components/icons/CarbonDeliveryAdd";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { uploadAttachment } from "@/services/siniestroService";
import { toast } from "sonner";

interface ConfirmUploadDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

interface InformeTecnicoDialogProps {
  bienes: Bien[];
  siniestro: Siniestro;
  ingresosSiniestro: Ingreso[];
  InformeTecnicoExportToPDF: (
    siniestro: Siniestro,
    bienes: Bien[],
    ingresos: Ingreso[],
    bienCoberturaAssignments: Record<number, number>
  ) => Promise<void>;
}

export const InformeTecnicoDialog = ({
  bienes,
  siniestro,
  ingresosSiniestro,
  InformeTecnicoExportToPDF,
}: InformeTecnicoDialogProps) => {
  const [selectedBienes, setSelectedBienes] = useState<number[]>([]);
  const [bienCoberturaAssignments, setBienCoberturaAssignments] = useState<
    Record<number, any>
  >({});
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileSelected, setFileSelected] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isSelectionValid, setIsSelectionValid] = useState(false);

  const ConfirmUploadDialog: React.FC<ConfirmUploadDialogProps> = ({
    isOpen,
    onClose,
    onConfirm,
  }) => (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">
            Subir PDF a adjuntos
          </DialogTitle>
          <DialogDescription className="text-sm text-gray-500">
            ¿Desea subir el PDF generado a la sección de adjuntos?
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-end space-x-4 mt-6">
          <Button variant="outline" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="default" onClick={onConfirm}>
            Subir PDF
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );

  const toggleBien = (bienId: number) => {
    setSelectedBienes((prevSelectedBienes) => {
      const newSelection = prevSelectedBienes.includes(bienId)
        ? prevSelectedBienes.filter((id) => id !== bienId)
        : [...prevSelectedBienes, bienId];
      setIsSelectionValid(newSelection.length > 0);
      return newSelection;
    });
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setFileSelected(true);
    }
  };

  const handleUploadPdf = async () => {
    if (!selectedFile) return;
    try {
      await uploadAttachment(siniestro.id, selectedFile);
      toast.success("Informe técnico cargado exitosamente");
      setSelectedFile(null);
      setFileSelected(false);
    } catch (error) {
      console.error("Error al cargar el informe:", error);
      toast.error("Error al cargar el informe");
    }
  };

  const handleGeneratePdf = async () => {
    setIsGeneratingPdf(true);

    // Filtra los bienes seleccionados y prepara las asignaciones de coberturas
    const bienesToExport = bienes.filter((bien) =>
      selectedBienes.includes(bien.id)
    );

    try {
      // Llama directamente a InformeTecnicoExportToPDF aquí, pasando las asignaciones
      await InformeTecnicoExportToPDF(
        siniestro,
        bienesToExport,
        ingresosSiniestro,
        bienCoberturaAssignments
      );

      setShowConfirmDialog(true);
    } catch (error) {
      console.error("Error generando PDF:", error);
    } finally {
      // Añade un pequeño retraso antes de volver a false para que el ícono se vea
      setTimeout(() => {
        setIsGeneratingPdf(false);
      }, 300); // 500 ms de retraso
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="default" className="ml-6">
          <CarbonDeliveryAdd className="mr-2 h-4 w-4" />
          Exportar Informe técnico
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[700px] overflow-y-scroll max-h-screen">
        <DialogHeader>
          <DialogTitle>Seleccione los bienes</DialogTitle>
          <DialogDescription>
            Seleccione los bienes que desea incluir en el informe técnico
          </DialogDescription>
        </DialogHeader>
        {bienes.map((bien) => (
          <div key={bien.id} className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <Checkbox
                id={`bien-${bien.id}`}
                checked={selectedBienes.includes(bien.id)}
                onCheckedChange={() => {
                  toggleBien(bien.id);
                }}
              />
              <label htmlFor={`bien-${bien.id}`} className="ml-2 flex-1">
                {bien.itemType} - {bien.brandName} - {bien.model} -{" "}
                {bien.serial}
              </label>
              {selectedBienes.includes(bien.id) && (
                <div className="flex ml-20">
                  <Select
                    onValueChange={(newValue) => {
                      setBienCoberturaAssignments((prevAssignments) => ({
                        ...prevAssignments,
                        [bien.id]: Number(newValue),
                      }));
                    }}
                  >
                    <SelectTrigger className="w-[210px]">
                      <SelectValue placeholder="Seleccione una cobertura" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Coberturas</SelectLabel>
                        {siniestro.coberturas.map(
                          (cobertura: Cobertura, index: number) => (
                            <SelectItem
                              key={index}
                              value={String(cobertura.id_cobertura)}
                            >
                              {cobertura.nombre_cobertura}
                            </SelectItem>
                          )
                        )}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>
          </div>
        ))}
        {!isSelectionValid && (
          <p className="text-red-500 text-sm mt-2">
            Por favor, seleccione al menos un bien.
          </p>
        )}
        <DialogFooter>
          <Button
            onClick={handleGeneratePdf}
            disabled={isGeneratingPdf || !isSelectionValid}
          >
            {isGeneratingPdf ? (
              <>
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                Generando PDF...
              </>
            ) : (
              "Generar PDF"
            )}
          </Button>
        </DialogFooter>
        {fileSelected && (
          <Button onClick={handleUploadPdf}>Confirmar subida de archivo</Button>
        )}
        <ConfirmUploadDialog
          isOpen={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() => {
            setShowConfirmDialog(false);
            fileInputRef.current?.click();
          }}
        />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileSelect}
          accept=".pdf"
        />
      </DialogContent>
    </Dialog>
  );
};
