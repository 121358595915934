const BASE_URL = `${
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV
}/api/segmentos`;

export const fetchSegmentos = async () => {
  const response = await fetch(BASE_URL, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Error fetching segmentos");
  }

  return await response.json();
};

export const createSegmento = async (segmento: { NOMBRE_SEGMENTO: string }) => {
  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(segmento),
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    if (typeof errorResponse.message === "string") {
      throw new Error(errorResponse.message);
    } else {
      throw new Error("Error creating segmento");
    }
  }

  return await response.json();
};

export const getSegmentoById = async (id: number) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Error fetching segmento by ID");
  }

  return await response.json();
};

export const updateSegmento = async (
  id: number,
  segmento: { NOMBRE_SEGMENTO: string; ESTADO: string },
) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(segmento),
  });

  if (!response.ok) {
    throw new Error("Error updating segmento");
  }

  return await response.json();
};

export const deleteSegmento = async (id: number) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Error deleting segmento");
  }

  return await response.json();
};

export const getSegmento = async (id: number) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Error fetching segmento");
  }

  return await response.json();
};
