import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { formattedValueARS } from "../SiniestroDetails/formattedValue";
import { toast } from "sonner";
import { FaCopy } from "react-icons/fa";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

interface OrdenCompraDialogProps {
  asegurado: string;
  poliza: string;
  siniestro: string;
  dni: string;
  compania: string;
  tramitadorCompania: string;
  bienes: Bien[];
  onClose: () => void;
}

const OrdenCompraDialog: React.FC<OrdenCompraDialogProps> = ({
  asegurado,
  poliza,
  siniestro,
  dni,
  compania,
  tramitadorCompania,
  bienes,
  onClose,
}) => {
  const calculateMonto = () => {
    const validTipoPagos = ["Frávega", "Authogar", "Musimundo"];
    const validBienes = bienes.filter(
      (bien) => bien.tipoPagoName && validTipoPagos.includes(bien.tipoPagoName)
    );
    const totalMonto = validBienes.reduce(
      (sum, bien) => sum + (bien.recognizedValue || 0),
      0
    );
    return totalMonto;
  };
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const monto = calculateMonto();

  React.useEffect(() => {
    if (monto >= 1000000) {
      setShowAlertDialog(true);
    }
  }, [monto]);

  const valorFormateado = formattedValueARS(monto) || "$ 0,00";

  const asunto = `Envío de orden de compra de asegurado ${asegurado} - Póliza: ${poliza} - Siniestro ${siniestro} ${compania}`;

  const textoOrdenCompra = `Por favor, emitir OC por ${valorFormateado} a favor de:

Asegurado: ${asegurado}

Siniestro: ${siniestro}

Póliza: ${poliza}

DNI: ${dni}

Gestionador: ${tramitadorCompania}`;

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="shadow-lg overflow-y-scroll max-h-screen max-w-3xl">
        <DialogHeader>
          <DialogTitle>Generación de OC</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <div className="mt-6">
            <div className="flex justify-between items-center">
              <Label>Asunto:</Label>
              <Button
                size="sm"
                variant="outline"
                onClick={() => {
                  navigator.clipboard.writeText(asunto);
                  toast.success("Asunto copiado al portapapeles");
                }}
              >
                <FaCopy className="mr-2" /> Copiar asunto
              </Button>
            </div>
            <p className="mt-2 p-2 border rounded-md">{asunto}</p>
          </div>

          <div className="mt-6">
            <div className="flex justify-between items-center">
              <Label>Texto:</Label>
              <Button
                size="sm"
                variant="outline"
                onClick={() => {
                  navigator.clipboard.writeText(textoOrdenCompra);
                  toast.success("Mensaje copiado al portapapeles");
                }}
              >
                <FaCopy className="mr-2" /> Copiar mensaje
              </Button>
            </div>
            <pre className="mt-2 p-2 border rounded-md whitespace-pre-wrap">
              {textoOrdenCompra}
            </pre>
          </div>
        </div>
        {showAlertDialog && (
          <AlertDialog open={showAlertDialog} onOpenChange={setShowAlertDialog}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Confirmación de valor alto</AlertDialogTitle>
                <AlertDialogDescription>
                  Estás por poner un valor mayor a $1.000.000. ¿Quieres
                  continuar?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => setShowAlertDialog(false)}>
                  Cancelar
                </AlertDialogCancel>
                <AlertDialogAction onClick={() => setShowAlertDialog(false)}>
                  Continuar
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cerrar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default OrdenCompraDialog;
