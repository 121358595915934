import React, { useEffect } from "react";
import { useTheme } from "../theme-provider";

const NotFoundPage: React.FC = () => {
  const { theme } = useTheme();

  useEffect(() => {
    // Agrega la clase 'dark' al elemento html si el tema es 'dark'
    document.documentElement.classList.toggle("dark", theme === "dark");
  }, [theme]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-800">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center dark:bg-gray-700">
        <h1 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
          404 - Página no encontrada
        </h1>
        <p className="text-gray-600 mb-4 dark:text-gray-300">
          Lo sentimos, no pudimos encontrar la página que estás buscando.
        </p>
        <a
          href="/"
          className="inline-block text-white bg-indigo-600 hover:bg-indigo-700 p-2 rounded-md dark:hover:bg-indigo-800"
        >
          Regresar al inicio
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
