import { create } from "zustand";

// Definimos la interfaz para el estado de nuestra tienda
interface LoadingState {
  isSaving: boolean;
  startSaving: () => void;
  stopSaving: () => void;
}

// Usamos la interfaz definida para tipar la tienda
const useLoadingStore = create<LoadingState>((set) => ({
  isSaving: false,
  startSaving: () => {
    set({ isSaving: true });
  },
  stopSaving: () => {
    set({ isSaving: false });
  },
}));

export default useLoadingStore;
