import { apiFetch } from "./siniestroService";

const BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

export async function loginUser(
  username: string,
  password: string,
): Promise<{ username: string; isDisabled: boolean; roles: number[] }> {
  const response = await fetch(`${BASE_URL}/api/users/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
    credentials: "include",
  });

  if (!response.ok) {
    const responseBody = await response.json();
    const errorMessage = responseBody.error || "Ocurrió un error inesperado.";
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return {
    username: responseData.username,
    isDisabled: !responseData.state,
    roles: Array.isArray(responseData.roles) ? responseData.roles : [], // Asegúrate de que los roles sean un array
  };
}

export async function createUser(userData: any) {
  const response = await apiFetch(`/api/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    const responseBody = await response.json();
    if (
      Boolean(responseBody.message) &&
      typeof responseBody.message === "string" &&
      Boolean(responseBody.message.includes("email already registered"))
    ) {
      throw new Error("El email ya está registrado en otro usuario.");
    } else {
      throw new Error(responseBody.message);
    }
  }

  return await response.json();
}

export const fetchUsers = async () => {
  try {
    const response = await apiFetch(`/api/users`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      if (response.status === 403) {
        throw new Error("USER_DISABLED");
      }
      throw new Error("Failed to fetch users");
    }

    const data = await response.json();
    return data;
  } catch (error) {}
};

export const updateUserState = async (userId: number, newState: boolean) => {
  try {
    const response = await apiFetch(`/api/users/${userId}/state`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ state: newState }),
    });

    if (!response.ok) {
      throw new Error("Failed to update user state");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export async function assignUserRole(
  userId: number,
  roleId: number,
  companyId?: number | null, // Asegurar que `companyId` puede ser `null`
) {
  const body: any = { role_id: String(roleId) }; // Convertir `role_id` a cadena de texto
  if (companyId !== undefined) {
    body.company_id = companyId; // Asegurar que `company_id` se envíe como número
  }

  const response = await apiFetch(`/api/users/${userId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const responseBody = await response.json();
    throw new Error(responseBody.message);
  }
}

export async function revokeUserRole(userId: number, roleId: number) {
  const response = await apiFetch(`/api/users/${userId}/roles/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ roleId }),
  });

  if (!response.ok) {
    const responseBody = await response.json();
    throw new Error(responseBody.message);
  }
}

export async function fetchUserRoles(
  userId: number,
): Promise<{ roles: number[] }> {
  const response = await apiFetch(`/api/users/roles/${userId}`, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  if (response.status === 404) {
    return { roles: [] }; // devuelve un objeto con un array vacío si el usuario no tiene roles
  }

  if (!response.ok) {
    const responseBody = await response.json();
    throw new Error(responseBody.message);
  }

  const data = await response.json();
  return { roles: data.roles };
}

export async function updateUser(userId: number, userData: any) {
  const response = await apiFetch(`/api/users/${userId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    const responseBody = await response.json();
    if (
      Boolean(responseBody.message) &&
      typeof responseBody.message === "string" &&
      Boolean(responseBody.message.includes("email already registered"))
    ) {
      throw new Error("El email ya está registrado en otro usuario.");
    } else {
      throw new Error(responseBody.message);
    }
  }

  return await response.json();
}

export async function fetchUserData(userId: number) {
  const response = await apiFetch(`/api/users/${userId}`, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  if (!response.ok) {
    const responseBody = await response.json();
    throw new Error(responseBody.message);
  }

  return await response.json();
}
