import { type SVGProps } from "react";

export function MaterialSymbolsHomeWorkOutlineRounded(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M17 9h2V7h-2v2Zm0 4h2v-2h-2v2Zm0 4h2v-2h-2v2Zm4 4h-3q-.425 0-.713-.288T17 20q0-.425.288-.713T18 19h3V5h-9v1.4l-2-1.45q0-.8.575-1.375T11.95 3H21q.825 0 1.413.588T23 5v14q0 .825-.588 1.413T21 21ZM3 21q-.825 0-1.413-.588T1 19v-6.975q0-.5.225-.925t.625-.7l5-3.575Q7.375 6.45 8 6.45t1.15.375l5 3.575q.4.275.625.7t.225.925V19q0 .825-.588 1.413T13 21h-3q-.425 0-.713-.288T9 20v-4H7v4q0 .425-.288.713T6 21H3Zm0-9v7h2v-4q0-.425.288-.713T6 14h4q.425 0 .713.288T11 15v4h2v-7L8 8.45L3 12Zm13.175-2.6ZM11 19v-5H5v5v-5h6v5Z"
      />
    </svg>
  );
}
