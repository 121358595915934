/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { Button } from "../ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Badge } from "../ui/badge";
import { Command, CommandItem, CommandList } from "../ui/command";
import { useState, type SetStateAction } from "react";

interface StatesFilterProps {
  onFilterChange: (filter: SetStateAction<string>) => void;
}

export default function StatesFilter({ onFilterChange }: StatesFilterProps) {
  const [selectedFilter, setSelectedFilter] = useState("todos");

  // Actualiza el filtro seleccionado y notifica al componente padre
  const handleSelectFilter = (filter: SetStateAction<string>) => {
    setSelectedFilter(filter);
    onFilterChange(filter); // Notificar al componente padre
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="default" className="h-8 border-dashed">
          <PlusCircledIcon className="mr-2 h-4 w-4" />
          Estado
          {selectedFilter !== "todos" && (
            <Badge
              variant={
                selectedFilter === "inactivo" ? "destructive" : "success"
              }
              className="ml-2"
            >
              {selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1)}
            </Badge>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandList>
            {["todos", "activo", "inactivo"].map((option) => (
              <CommandItem
                key={option}
                onSelect={() => {
                  handleSelectFilter(option);
                }}
              >
                <div
                  className={`mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary ${
                    selectedFilter === option
                      ? "bg-primary text-primary-foreground"
                      : "opacity-50 [&_svg]:invisible"
                  }`}
                >
                  <CheckIcon className="h-4 w-4" />
                </div>
                <span>{option.charAt(0).toUpperCase() + option.slice(1)}</span>
              </CommandItem>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
