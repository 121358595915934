import { useAuth } from "@/contexts/AuthContext";
import { GastosProveedoresColumns } from "./GastosProveedoresColumns";
import { GastosProveedoresDataTable } from "./GastosProveedoresDataTable";
import NotFoundPage from "../pages/NotFoundPage";

function GastosProveedoresList() {
  const columns = GastosProveedoresColumns(() => {});
  const { roles } = useAuth();
  const hasRole2 = roles.includes(2);

  if (!hasRole2) {
    return <NotFoundPage />;
  }
  return (
    <div className="container mx-auto">
      <div className="flex flex-col md:flex-row justify-between items-center mb-8">
        <h1 className="text-3xl font-semibold md:mb-0 text-center md:text-left mb-4">
          Gastos de proveedores
        </h1>
      </div>
      <GastosProveedoresDataTable columns={columns} currentPage={0} />
    </div>
  );
}

export default GastosProveedoresList;
