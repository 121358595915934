import { create } from "zustand";
import * as segmentoService from "../services/segmentoService.ts";

interface Segmento {
  CODIGO_SEGMENTO: number;
  NOMBRE_SEGMENTO: string;
  USUARIO_ALTA: string;
  FECHA_ALTA: string;
  USUARIO_BAJA: string | null;
  FECHA_BAJA: string | null;
  USUARIO_MODIF: string | null;
  FECHA_MODIF: string | null;
  ESTADO: string;
}

interface SegmentoState {
  segmentos: Segmento[];
  setSegmentos: (segmentos: Segmento[]) => void;
  fetchSegmentos: () => Promise<void>;
  createSegmento: (segmento: {
    NOMBRE_SEGMENTO: string;
    ESTADO: string;
  }) => Promise<void>;
  updateSegmento: (
    id: number,
    segmento: { NOMBRE_SEGMENTO: string; ESTADO: string },
  ) => Promise<void>;
  deleteSegmento: (id: number) => Promise<void>;
  editDialogId: number | null;
  setEditDialogId: (id: number | null) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}

export const useSegmentoStore = create<SegmentoState>((set, get) => ({
  segmentos: [],
  isLoading: false,
  setSegmentos: (segmentos) => {
    set({ segmentos });
  },
  setLoading: (isLoading) => {
    set({ isLoading });
  },
  fetchSegmentos: async () => {
    const data = await segmentoService.fetchSegmentos();
    set({ segmentos: data.segmentos });
  },
  createSegmento: async (segmento) => {
    await segmentoService.createSegmento(segmento);
    void get().fetchSegmentos();
  },
  updateSegmento: async (id, segmento) => {
    await segmentoService.updateSegmento(id, segmento);
    void get().fetchSegmentos();
  },
  deleteSegmento: async (id) => {
    await segmentoService.deleteSegmento(id);
    void get().fetchSegmentos();
  },
  editDialogId: null,
  setEditDialogId: (id) => {
    set(() => ({ editDialogId: id }));
  },
  currentPage: 0,
  setCurrentPage: (page) => {
    set({ currentPage: page });
  },
}));
