"use client";

import { type ColumnDef } from "@tanstack/react-table";

import { ArrowUpDown, MoreHorizontal } from "lucide-react";

import { MdiPencil } from "../icons/MdiPencil";
import { PajamasStatusNeutral } from "../icons/PajamasStatusNeutral";
import { MdiPencilBoxMultiple } from "../icons/MdiPencilBoxMultiple";
import { MdiCheckboxMarked } from "../icons/MdiCheckboxMarked";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuShortcut,
  DropdownMenuSubContent,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import { toast } from "sonner";
import useUserStore from "@/stores/userStore";
import { updateUserState } from "@/services/userService";
import { Dialog, DialogTrigger, DialogContent } from "../ui/dialog";
import { UpdateUserDialog } from "./UpdateUserDialog";
import { EditUserRolesDialog } from "./EditUserRolesDialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { useState } from "react";

export interface User {
  id: number;
  username: string;
  email: string;
  display_name: string;
  state: boolean;
  company_id: string | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  roles: number[] | string;
  company_name: string | null;
}

// Esta es la estructura real de lo que estás pasando a ActionCell
interface RowData {
  original: User;
}

const ActionCell = ({ row }: { row: RowData }) => {
  // Estado local para controlar la apertura de los diálogos
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isEditRolesDialogOpen, setEditRolesDialogOpen] = useState(false);
  // Hooks de Zustand para manejar acciones globales
  const setGlobalEditDialogId = useUserStore((state) => state.setEditDialogId);
  const setGlobalEditRolesDialogId = useUserStore(
    (state) => state.setEditRolesDialogId,
  );
  const fetchUsers = useUserStore((state) => state.fetchUsers);

  const handleOpenEditUserDialog = () => {
    setGlobalEditDialogId(row.original.id);
    setEditDialogOpen(true);
  };

  const handleCloseEditUserDialog = () => {
    setGlobalEditDialogId(null);
    setEditDialogOpen(false);
  };

  const handleOpenEditRolesDialog = () => {
    setGlobalEditRolesDialogId(row.original.id);
    setEditRolesDialogOpen(true);
  };

  const handleCloseEditRolesDialog = () => {
    setGlobalEditRolesDialogId(null);
    setEditRolesDialogOpen(false);
  };

  const handleStateChange = async (newState: boolean) => {
    try {
      await updateUserState(row.original.id, newState);
      await fetchUsers();
      toast.success("Estado actualizado correctamente.");
    } catch (error) {
      console.error(error); // Imprime el error real
      toast.error("Ocurrió un error al actualizar el estado del usuario.");
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Abrir menú</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <Dialog>
            <DialogTrigger asChild>
              <DropdownMenuItem onClick={handleOpenEditUserDialog}>
                <span className="flex items-center space-x-2">
                  <MdiPencil className="w-4 h-4" />
                  <span>Editar usuario</span>
                </span>
              </DropdownMenuItem>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <UpdateUserDialog userId={row.original.id} />
            </DialogContent>
          </Dialog>

          <Dialog>
            <DialogTrigger asChild>
              <DropdownMenuItem onClick={handleOpenEditRolesDialog}>
                <span className="flex items-center space-x-2">
                  <MdiPencilBoxMultiple className="w-4 h-4" />
                  <span>Editar roles</span>
                </span>
              </DropdownMenuItem>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <EditUserRolesDialog userId={row.original.id} />
            </DialogContent>
          </Dialog>

          <DropdownMenuSeparator />

          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <span className="flex items-center space-x-2">
                <PajamasStatusNeutral className="w-4 h-4" />
                <span>Estado</span>
              </span>
            </DropdownMenuSubTrigger>

            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  onSelect={() => {
                    void handleStateChange(true);
                  }}
                >
                  Activo
                  {row.original.state && (
                    <DropdownMenuShortcut>
                      {" "}
                      <MdiCheckboxMarked className="w-4 h-4" />{" "}
                    </DropdownMenuShortcut>
                  )}
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => {
                    void handleStateChange(false);
                  }}
                >
                  Inactivo
                  {!row.original.state && (
                    <DropdownMenuShortcut>
                      {" "}
                      <MdiCheckboxMarked className="w-4 h-4" />{" "}
                    </DropdownMenuShortcut>
                  )}
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={isEditDialogOpen} onOpenChange={handleCloseEditUserDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <UpdateUserDialog userId={row.original.id} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isEditRolesDialogOpen}
        onOpenChange={handleCloseEditRolesDialog}
      >
        <DialogContent className="sm:max-w-[425px]">
          <EditUserRolesDialog userId={row.original.id} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export const Columns: Array<ColumnDef<User>> = [
  {
    accessorKey: "display_name",
    header: ({ column }) => {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                onClick={() => {
                  const currentSort = column.getIsSorted();
                  if (currentSort === "asc" || currentSort === false) {
                    column.toggleSorting(true);
                  } else {
                    column.toggleSorting(false);
                  }
                }}
              >
                Nombre
                <ArrowUpDown className="ml-2 h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Ordenar</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    },
  },
  {
    accessorKey: "username",
    header: "Nombre de usuario",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "roles",
    header: "Roles",
    cell: (context) => {
      const roles = useUserStore((state) => state.roles);

      if (!Array.isArray(context.row.original.roles)) {
        return <span>{context.row.original.roles}</span>;
      }

      const roleNames = context.row.original.roles
        .map((roleId: number) => {
          const role = roles.find((r) => r.id === roleId);
          if (role == null) {
            return null;
          }
          return role.name;
        })
        .filter(Boolean)
        .join(", ");

      return <span>{roleNames}</span>;
    },
  },
  {
    accessorKey: "company_name",
    header: "Compañía",
    cell: (context) => {
      const companyName = context.row.original.company_name;
      return <span>{companyName}</span>;
    },
  },
  {
    accessorKey: "state",
    header: "Estado",
    cell: (context) =>
      context.row.original.state ? (
        <Badge variant="success">Activo</Badge>
      ) : (
        <Badge variant="destructive">Inactivo</Badge>
      ),
  },
  {
    id: "actions",
    cell: (context) => <ActionCell row={context.row} />,
  },
];
