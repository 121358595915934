import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  addHistorialMessage,
  checkIfUserIsTramitador,
  fetchHistorialSiniestro,
  getUserInfo,
  notifyAboutComment,
} from "@/services/siniestroService";
import { toast } from "sonner";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Switch } from "../ui/switch";

interface TextAreaHistorialProps {
  siniestroId: number;
  onHistorialUpdated: (updatedHistorial: HistorialSiniestro[]) => void;
  hasRole7: boolean;
}

export function TextAreaHistorial({
  siniestroId,
  onHistorialUpdated,
  hasRole7,
}: TextAreaHistorialProps) {
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notifyCompany, setNotifyCompany] = useState(false);
  const [notifyTramitador, setNotifyTramitador] = useState(false);
  const [isTramitador, setIsTramitador] = useState(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userInfo = await getUserInfo();
        setUserId(userInfo.id);
        const tramitadorCheck = await checkIfUserIsTramitador(
          userInfo.id,
          siniestroId
        );
        setIsTramitador(tramitadorCheck);
      } catch (error) {}
    };

    void fetchUserInfo();
  }, [siniestroId]);

  const handleSendMessage = async () => {
    if (message.trim().length === 0 || !userId || isLoading) return;

    try {
      setIsLoading(true);

      await addHistorialMessage(siniestroId, userId, message);
      toast.success("Mensaje enviado");
      setMessage("");

      const updatedHistorial = await fetchHistorialSiniestro(siniestroId);
      onHistorialUpdated(updatedHistorial);

      const isTramitador = await checkIfUserIsTramitador(userId, siniestroId);

      if ((isTramitador && notifyCompany) || (hasRole7 && notifyTramitador)) {
        await notifyAboutComment(siniestroId, message, userId, isTramitador);
        if (isTramitador && notifyCompany) {
          toast.success(
            "Se ha notificado a los usuarios de la compañía que han comentado en este siniestro"
          );
        } else if (hasRole7 && notifyTramitador) {
          toast.success("Se ha notificado al tramitador sobre su comentario");
        }
      }
    } catch (error) {
      toast.error("Error al enviar el mensaje");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="grid w-full gap-2">
      <Textarea
        placeholder="Escribe un mensaje"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        className="min-h-[100px]" // This sets a minimum height
        rows={4} // This sets the initial number of visible text lines
      />

      {isTramitador && !hasRole7 && (
        <div className="flex items-center space-x-2">
          <Switch
            id="notify-company"
            checked={notifyCompany}
            onCheckedChange={setNotifyCompany}
          />
          <label htmlFor="notify-company">Notificar a la compañía</label>
        </div>
      )}

      {hasRole7 && (
        <div className="flex items-center space-x-2">
          <Switch
            id="notify-tramitador"
            checked={notifyTramitador}
            onCheckedChange={setNotifyTramitador}
          />
          <label htmlFor="notify-tramitador">Notificar al tramitador</label>
        </div>
      )}

      {isLoading ? (
        <Button disabled>
          <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          Enviando...
        </Button>
      ) : (
        <Button onClick={handleSendMessage}>Enviar mensaje</Button>
      )}
    </div>
  );
}
