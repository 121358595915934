import { apiFetch } from "@/services/siniestroService"; // Asegúrate de que la ruta sea correcta

export const fetchAseguradoByDocument = async (
  documentType: string,
  documentNumber: number,
) => {
  const url = `/api/asegurados/documentType/${documentType}/documentNumber/${documentNumber}`;

  try {
    const response = await apiFetch(url);
    if (response.ok) {
      const data = await response.json();
      return data; // If the request is successful, return data as before
    } else {
      // Handle errors directly here instead of re-throwing
      if (response.status === 404) {
        console.warn(
          `No asegurado found for ${documentType}: ${documentNumber}`,
        );
      } else {
        console.error(
          `Error fetching asegurado by ${documentType}:`,
          response.status,
        );
      }
      return null; // Indicate that no asegurado was found
    }
  } catch (error) {
    // Handle unexpected errors (e.g., network issues)
    console.error(`Error fetching asegurado by ${documentType}:`, error);
    return null;
  }
};
