import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Separator } from "@/components/ui/separator";
import Spinner from "@/components/ui/spinner";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import * as z from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "sonner";
import { useEffect, useState } from "react";

import { fetchSegmentos } from "@/services/segmentoService";
import { fetchCausas } from "@/services/causaService";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  useSiniestroFormStore,
  useBienesStore,
  useDireccionStore,
  useAseguradoStore,
} from "@/stores/formStore";
import { useNavigate } from "react-router-dom";

// Servicios
import { createBien, addAccionToSiniestro } from "@/services/siniestroService";

// Esquema de validación con Zod para el formulario de bienes
const formSchema = z.object({
  incident_id: z.number(),
  instance_id: z.number(),
  brand: z.string().optional(),
  segment_id: z.number(),
  resolution_id: z.number().nullable().optional(),
  cause_id: z.number(),
  item_type: z.string().optional(),
  item_payment_type: z.number().nullable().optional(),
  model: z.string().optional().nullable(),
  serial: z.string().optional().nullable(),
  accesories: z.string().optional().nullable(),
  receiptNumber: z.string().nullable().optional(),
  deliveryNumber: z.string().nullable().optional(),
  claimedValue: z.number().nullable().optional(),
  recognizedValue: z.number().nullable().optional(),
  repairedRecognizedValue: z.string().nullable().optional(),
  coveredDetails: z.string().optional(),
  observations: z.string().nullable().optional(),
  otherObservations: z.string().nullable().optional(),
  deletedAt: z.string().nullable().optional(),
});

export function BienesForm() {
  const { siniestroInfo } = useSiniestroFormStore();
  const incidentId = siniestroInfo?.incident_id;
  const [isLoading] = useState(false);
  const { bienesInfo, agregarBien } = useBienesStore();
  const navigate = useNavigate();
  const [segments, setSegments] = useState<Segmento[]>([]);
  const [causes, setCauses] = useState<Causa[]>([]);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      incident_id: incidentId,
      instance_id: 1,
      brand: "",
      segment_id: "",
      resolution_id: null,
      cause_id: null,
      item_type: "",
      item_payment_type: null,
      model: null,
      serial: null,
      accesories: null,
      receiptNumber: null,
      deliveryNumber: null,
      claimedValue: null,
      recognizedValue: null,
      repairedRecognizedValue: null,
      coveredDetails: null,
      observations: null,
      otherObservations: null,
      deletedAt: null,
    },
  });

  useEffect(() => {
    const fetchSegmentsData = async () => {
      try {
        const segmentsData = await fetchSegmentos();
        setSegments(segmentsData.segmentos);
      } catch (error) {}
    };

    void fetchSegmentsData();
  }, []);

  useEffect(() => {
    const fetchCausesData = async () => {
      try {
        const causesData = await fetchCausas();
        setCauses(causesData);
      } catch (error) {
        setCauses([]);
      }
    };

    void fetchCausesData();
  }, []);

  useEffect(() => {
    if (incidentId) {
      form.reset({
        ...form.getValues(),
        incident_id: siniestroInfo.incident_id,
      });
    }
  }, [incidentId, form]);

  const onSubmit = async (data: any) => {
    try {
      const formattedData = {
        ...data,
        repairedRecognizedValue: data.repairedRecognizedValue || null,
        coveredDetails: data.coveredDetails || null,
        observations: data.observations || null,
        otherObservations: data.otherObservations || null,
        receiptNumber: data.receiptNumber || null,
        deliveryNumber: data.deliveryNumber || null,
        claimedValue: data.claimedValue || 0,
        recognizedValue: data.recognizedValue || 0,
      };

      const nuevoBien = await createBien(formattedData);

      // Añade itemType y brandName al nuevo bien
      const bienConDetalles = {
        ...nuevoBien,
        itemType: data.item_type || "No definido",
        brandName: data.brand || "No definido",
        model: data.model || "No definido",
        serial: data.serial || "No definido",
        accesories: data.accesories || "No definido",
        coveredDetails: data.coveredDetails || "No definido",
        cause_id: data.cause_id,
        segment_id: data.segment_id,
        observations: data.observations || "No definido",
        otherObservations: data.otherObservations || "No definido",
      };

      agregarBien(bienConDetalles);

      if (incidentId) {
        await addAccionToSiniestro(incidentId, 25, null, nuevoBien.id);
      } else {
        toast.error("Error: incidentId es indefinido");
      }

      toast.success("Bien creado correctamente");

      form.reset({
        incident_id: incidentId,
        instance_id: 1,
        brand: "",
        segment_id: "",
        resolution_id: null,
        cause_id: null,
        item_type: "",
        item_payment_type: null,
        model: null,
        serial: null,
        accesories: null,
        receiptNumber: null,
        deliveryNumber: null,
        claimedValue: null,
        recognizedValue: null,
        repairedRecognizedValue: null,
        coveredDetails: null,
        observations: null,
        otherObservations: null,
        deletedAt: null,
      });
    } catch (error) {
      toast.error("Error al crear bien");
    }
  };

  const finalizarYRegresar = () => {
    useBienesStore.setState({ bienesInfo: [] });
    useSiniestroFormStore.setState({ siniestroInfo: null });
    useDireccionStore.setState({ direccionInfo: null });
    useAseguradoStore.setState({ aseguradoInfo: null });
    navigate("/siniestros");
  };

  return (
    <div className="flex flex-col md:flex-row items-start">
      <div className="flex-1 max-w-4xl">
        <h1 className="text-2xl font-bold mb-4">Agregue uno o más bienes</h1>
        <p className="text-gray-500 mb-8">
          Para comenzar, ingrese los datos del bien.
        </p>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-6 bg-white dark:bg-gray-800 p-8 rounded-lg"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Controller
                control={form.control}
                name="segment_id"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Segmento</FormLabel>
                    <Select
                      onValueChange={(value) => {
                        const segmentSelected = segments.find(
                          (segment) => segment.NOMBRE_SEGMENTO === value,
                        );
                        if (segmentSelected) {
                          field.onChange(
                            Number(segmentSelected.CODIGO_SEGMENTO),
                          );
                        }
                      }}
                      value={
                        field.value
                          ? segments.find(
                              (segment) =>
                                segment.CODIGO_SEGMENTO === Number(field.value),
                            )?.NOMBRE_SEGMENTO
                          : ""
                      }
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {segments.map((segment) => (
                          <SelectItem
                            key={segment.CODIGO_SEGMENTO}
                            value={segment.NOMBRE_SEGMENTO}
                          >
                            {segment.NOMBRE_SEGMENTO}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Controller
                control={form.control}
                name="item_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tipo de Bien</FormLabel>
                    <Input
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Ingresar tipo de bien"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Controller
                control={form.control}
                name="brand"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Marca</FormLabel>
                    <Input
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Ingresar marca"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="model"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Modelo</FormLabel>
                    <Input {...field} value={field.value ?? ""} />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="serial"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Serial</FormLabel>
                    <Input {...field} value={field.value ?? ""} />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="accesories"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Accesorios</FormLabel>
                    <Input {...field} value={field.value ?? ""} />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Controller
                control={form.control}
                name="cause_id"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Causa</FormLabel>
                    <Select
                      onValueChange={(value) => {
                        const causaSelected = causes.find(
                          (causa) => causa.name === value,
                        );
                        if (causaSelected) {
                          field.onChange(Number(causaSelected.id));
                        }
                      }}
                      value={
                        field.value
                          ? causes.find((causa) => causa.id === field.value)
                              ?.name
                          : ""
                      }
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {causes?.map((causa) => (
                          <SelectItem key={causa.id} value={causa.name}>
                            {causa.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="coveredDetails"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Detalle de cobertura</FormLabel>
                    <Input {...field} value={field.value ?? ""} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mt-4 flex justify-center">
              <Button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Crear nuevo bien
              </Button>
            </div>
          </form>
        </Form>
      </div>
      <div className="flex-1 mt-16 ml-16 max-w-lg">
        {bienesInfo.length > 0 ? (
          <Card>
            <CardHeader>
              <CardTitle>Información del bien</CardTitle>
            </CardHeader>
            <CardContent>
              {bienesInfo.map((bien, index) => (
                <div key={index} className="mb-4">
                  <p>
                    <strong>Tipo de bien:</strong> {bien.itemType}
                  </p>
                  <p>
                    <strong>Segmento:</strong>{" "}
                    {segments.find(
                      (segment) => segment.CODIGO_SEGMENTO === bien.segment_id,
                    )?.NOMBRE_SEGMENTO || "No definido"}
                  </p>
                  <p>
                    <strong>Marca:</strong> {bien.brandName}
                  </p>
                  <p>
                    <strong>Modelo:</strong> {bien.model}
                  </p>
                  <p>
                    <strong>Número de Serie:</strong> {bien.serial}
                  </p>
                  <p>
                    <strong>Accesorios:</strong> {bien.accesories}
                  </p>
                  <p>
                    <strong>Causa:</strong>{" "}
                    {causes.find((causa) => causa.id === bien.cause_id)?.name ||
                      "No definido"}
                  </p>
                  <p>
                    <strong>Cobertura:</strong> {bien.coveredDetails}
                  </p>
                  {index < bienesInfo.length - 1 && (
                    <Separator className="my-4" />
                  )}
                </div>
              ))}
            </CardContent>
          </Card>
        ) : isLoading ? (
          <Spinner />
        ) : null}
        {bienesInfo.length > 0 && (
          <div className="mt-4">
            <Button
              onClick={finalizarYRegresar}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Finalizar y regresar
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
