import { Skeleton } from "@/components/ui/skeleton";
import { TableCell, TableRow } from "@/components/ui/table";

interface SkeletonRowProps {
  columnsCount: number;
}

export const SkeletonRow: React.FC<SkeletonRowProps> = ({ columnsCount }) => (
  <TableRow>
    {Array.from({ length: columnsCount }).map((_, index) => (
      <TableCell key={index}>
        <Skeleton className="h-6 w-full" />
      </TableCell>
    ))}
  </TableRow>
);
