/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import parseISO from "date-fns/parseISO";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  fetchCompanyChildren,
  fetchParentableCompanies,
} from "@/services/companyService";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon, CheckIcon } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import format from "date-fns/format";
import {
  useAseguradoStore,
  useDireccionStore,
  useSiniestroFormStore,
} from "@/stores/formStore";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  addAccionToSiniestro,
  apiFetch,
  fetchTramitadores,
} from "@/services/siniestroService";
import { CaretSortIcon } from "@radix-ui/react-icons";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { useNavigate } from "react-router-dom";
import { ButtonLoading } from "@/components/ui/ButtonLoading";
import { es } from "date-fns/locale";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const formSchema = z.object({
  state_id: z.number().optional(),
  company_id: z.number().min(1, { message: "Compañía es obligatoria" }), // Aquí se asegura que company_id sea obligatorio
  insured_id: z.preprocess((val) => Number(val), z.number()), // Asegura que insured_id sea un número
  ocurrence_address_id: z.number(),
  responsible: z.number().min(1, { message: "Tramitador es obligatorio" }),
  preDenunciationNumber: z.string().max(100).optional(),
  companyIncidentNumber: z.string().max(100),
  policyNumber: z.string().max(100),
  policyType: z.string().max(50),
  policyObservations: z.string().max(1000).optional(),
  denunciationDatetime: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/, {
      message: "Fecha de denuncia no válida",
    }),
  ocurrenceDatetime: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/, {
      message: "Fecha de ocurrencia no válida",
    }),
  ocurrenceDescription: z.string().max(1000),
  ocurrenceObservations: z.string().max(1000).nullable().optional(),
  companyResponsible: z
    .string()
    .email({ message: "Correo electrónico no válido" })
    .max(1000),
  contactEmail: z
    .string()
    .email({ message: "Correo electrónico no válido" })
    .max(1000),
  coveredValue: z
    .number()
    .min(0, { message: "Valor asegurado no puede ser negativo" })
    .max(2147483647, {
      message: "El valor no puede ser mayor a 2,147,483,647",
    }),
  type: z.string().max(50).optional(),
  tcObservations: z.string().max(1000).nullable().optional(),
  assignedAt: z.string().nullable().optional(),
  contactedAt: z.string().nullable().optional(),
  pickedUpAt: z.string().nullable().optional(),
  docRequestedAt: z.string().nullable().optional(),
  closedAt: z.string().nullable().optional(),
  insertedAt: z.string().nullable().optional(),
  updatedAt: z.string().nullable().optional(),
  deletedAt: z.string().max(1).nullable().optional(),
  selectedCompanyCode: z.string().optional(),
});

const API_BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

export function SiniestroForm() {
  const [tramitadores, setTramitadores] = useState<Tramitador[]>([]);
  const [companias, setCompanias] = useState<Company[]>([]);
  const aseguradoInfo = useAseguradoStore((state) => state.aseguradoInfo);
  const direccionInfo = useDireccionStore((state) => state.direccionInfo);
  const { setSiniestroInfo, siniestroInfo } = useSiniestroFormStore();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [companyChildren, setCompanyChildren] = useState<Company[]>([]);
  const [, setSelectedCompanyCode] = useState("");

  const handleCompanySelect = async (companyId: number) => {
    const selectedCompany = companias.find(
      (company) => company.id === companyId
    );

    if (selectedCompany) {
      form.setValue("company_id", companyId);

      try {
        const children = await fetchCompanyChildren(companyId);
        setCompanyChildren(children.companies);
      } catch (error) {
        console.error("Error al cargar compañías hijas:", error);
      }
    } else {
      setCompanyChildren([]);
    }
  };

  const handleChildCompanySelect = (childCompanyId: number) => {
    const selectedChildCompany = companyChildren.find(
      (child) => child.id === childCompanyId
    );
    if (selectedChildCompany) {
      setSelectedCompanyCode(selectedChildCompany.code);
      form.setValue("selectedCompanyCode", selectedChildCompany.code);
    } else {
      setSelectedCompanyCode("");
    }
  };

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      state_id: 1,
      company_id: 0,
      insured_id: 0,
      ocurrence_address_id: 0,
      responsible: 0,
      preDenunciationNumber: "NULL",
      companyIncidentNumber: "",
      policyNumber: "",
      policyType: "",
      policyObservations: "",
      denunciationDatetime: "",
      ocurrenceDatetime: "",
      ocurrenceDescription: "",
      ocurrenceObservations: null,
      companyResponsible: "",
      contactEmail: "",
      coveredValue: 0,
      type: "NULL",
      tcObservations: null,
      assignedAt: null,
      contactedAt: null,
      pickedUpAt: null,
      docRequestedAt: null,
      closedAt: null,
      insertedAt: null,
      updatedAt: null,
      deletedAt: null,
      selectedCompanyCode: "",
    },
  });

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/users/tramitadores`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(async (response) => await response.json())
      .then((data) => {
        setTramitadores(data.tramitadores);
      })
      .catch((error) => {
        console.error("Error al obtener tramitadores:", error);
      });
  }, []);

  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const companies = await fetchParentableCompanies();
        setCompanias(companies);
      } catch (error) {
        console.error("Error al cargar compañías:", error);
      }
    };

    void loadCompanies();
  }, []);

  useEffect(() => {
    if (aseguradoInfo) {
      form.setValue("insured_id", aseguradoInfo.id);
    }
    if (direccionInfo) {
      form.setValue("ocurrence_address_id", direccionInfo.id);
    }
  }, [aseguradoInfo, direccionInfo, form]);

  useEffect(() => {
    const loadTramitadores = async () => {
      try {
        const tramitadores = await fetchTramitadores();
        setTramitadores(tramitadores);
      } catch (error) {
        console.error("Error al cargar tramitadores:", error);
      }
    };

    const loadCompanies = async () => {
      try {
        const companies = await fetchParentableCompanies();
        setCompanias(companies);
      } catch (error) {
        console.error("Error al cargar compañías:", error);
      }
    };

    void loadTramitadores();
    void loadCompanies();
  }, []);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const formattedData = {
        ...data,
        insured_id: Number(data.insured_id),
        ocurrence_address_id: direccionInfo ? direccionInfo.id : null,
        companyIncidentNumber: data.selectedCompanyCode
          ? `${data.selectedCompanyCode}${data.companyIncidentNumber}`
          : data.companyIncidentNumber,
        deletedAt: null,
      };

      const response = await apiFetch(`/api/siniestros`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formattedData),
      });

      if (response.ok) {
        const result = await response.json();
        const incidentId = result.siniestro.id;
        setSiniestroInfo({ ...result.siniestro, incident_id: incidentId });
        toast.success("Siniestro creado correctamente");
        await addAccionToSiniestro(incidentId, 18, null, null);
        navigate("/nuevo-siniestro/nuevo-bien");
      } else {
        const errorText = await response.text();
        if (response.status === 409) {
          toast.error("Siniestro con este número de siniestro ya existe");
        } else {
          throw new Error(errorText || "Error al crear siniestro");
        }
      }
    } catch (error) {
      console.error("Error al crear siniestro:", error);
      toast.error("Error al crear siniestro");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row items-start">
        <div className="flex-1 p-4">
          <h1 className="text-2xl font-bold mb-4">Detalles del siniestro</h1>
          <p className="text-gray-500 mb-8">Completa los datos del siniestro</p>
          <Form {...form}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                form.handleSubmit(onSubmit)(e);
              }}
              className="space-y-6 bg-white dark:bg-gray-800 p-8 rounded-lg"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:col-span-2">
                  <FormField
                    control={form.control}
                    name="company_id"
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Compañía</FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                role="combobox"
                                className="w-full justify-between"
                                disabled={!!siniestroInfo}
                              >
                                {field.value
                                  ? companias.find(
                                      (company) => company.id === field.value
                                    )?.name
                                  : "Seleccionar compañía"}
                                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent asChild className="w-full p-0">
                            <Command>
                              <CommandInput
                                placeholder="Buscar compañía..."
                                className="h-9"
                              />
                              <ScrollArea className="h-[200px]">
                                <CommandGroup>
                                  {Array.isArray(companias) &&
                                    companias.map((company) => (
                                      <CommandItem
                                        key={company.id}
                                        value={company.name}
                                        onSelect={() =>
                                          handleCompanySelect(company.id)
                                        }
                                      >
                                        {company.name}
                                        <CheckIcon
                                          className={cn(
                                            "ml-auto h-4 w-4",
                                            company.id === field.value
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                      </CommandItem>
                                    ))}
                                </CommandGroup>
                              </ScrollArea>
                            </Command>
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {Array.isArray(companyChildren) &&
                    companyChildren.length > 0 && (
                      <FormField
                        control={form.control}
                        name="selectedCompanyCode"
                        render={({ field }) => (
                          <FormItem className="flex flex-col">
                            <FormLabel>Filial de la Compañía</FormLabel>
                            <Select
                              onValueChange={(value) => {
                                const selectedChildCompany =
                                  companyChildren.find(
                                    (child) => child.code === value
                                  );
                                field.onChange(value);
                                handleChildCompanySelect(
                                  selectedChildCompany?.id || 0
                                );
                              }}
                            >
                              <SelectTrigger className="w-full">
                                <SelectValue placeholder="Seleccionar filial" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectLabel>Filiales</SelectLabel>
                                  {companyChildren.map((child) => (
                                    <SelectItem
                                      key={child.id}
                                      value={child.code}
                                    >
                                      {child.name}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}

                  <FormField
                    control={form.control}
                    name="responsible"
                    render={({ field, fieldState: { error } }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Tramitador Tu Service</FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                role="combobox"
                                className="w-full justify-between"
                                disabled={!!siniestroInfo}
                              >
                                {field.value
                                  ? tramitadores.find(
                                      (tramitador) =>
                                        tramitador.id === field.value
                                    )?.display_name
                                  : "Seleccionar tramitador"}
                                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent asChild className="w-full p-0">
                            <Command>
                              <CommandInput
                                placeholder="Buscar tramitador..."
                                className="h-9"
                              />
                              <ScrollArea className="h-[200px]">
                                <CommandGroup>
                                  {Array.isArray(tramitadores) &&
                                    tramitadores.map((tramitador) => (
                                      <CommandItem
                                        key={tramitador.id}
                                        value={tramitador.display_name}
                                        onSelect={() => {
                                          field.onChange(tramitador.id);
                                        }}
                                      >
                                        {tramitador.display_name}
                                        <CheckIcon
                                          className={cn(
                                            "ml-auto h-4 w-4",
                                            tramitador.id === field.value
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                      </CommandItem>
                                    ))}
                                </CommandGroup>
                              </ScrollArea>
                            </Command>
                          </PopoverContent>
                        </Popover>
                        {error && <FormMessage>{error.message}</FormMessage>}
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="companyIncidentNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Número de Siniestro de la Compañía</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          onChange={(e) =>
                            field.onChange(e.target.value.trim())
                          }
                          disabled={!!siniestroInfo}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="policyNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Número de Póliza</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          onChange={(e) =>
                            field.onChange(e.target.value.trim())
                          }
                          disabled={!!siniestroInfo}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="policyType"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Tipo de Póliza</FormLabel>
                      <FormControl>
                        <Input {...field} disabled={!!siniestroInfo} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="policyObservations"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Observaciones de la Póliza</FormLabel>
                      <FormControl>
                        <Input {...field} disabled={!!siniestroInfo} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:col-span-2">
                  <FormField
                    control={form.control}
                    name="denunciationDatetime"
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Fecha y Hora de Denuncia</FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                className="w-full justify-between"
                                disabled={!!siniestroInfo}
                              >
                                {field.value && field.value.length > 0
                                  ? format(parseISO(field.value), "PPPp", {
                                      locale: es,
                                    })
                                  : "Seleccionar fecha y hora"}
                                <CalendarIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0">
                            <Calendar
                              mode="single"
                              selected={
                                field.value ? parseISO(field.value) : undefined
                              }
                              onSelect={(date) => {
                                field.onChange(date ? date.toISOString() : "");
                              }}
                              locale={es}
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="ocurrenceDatetime"
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Fecha y Hora de Ocurrencia</FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                className="w-full justify-between"
                                disabled={!!siniestroInfo}
                              >
                                {field.value && field.value.length > 0
                                  ? format(parseISO(field.value), "PPPp", {
                                      locale: es,
                                    })
                                  : "Seleccionar fecha y hora"}
                                <CalendarIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0">
                            <Calendar
                              mode="single"
                              selected={
                                field.value ? parseISO(field.value) : undefined
                              }
                              onSelect={(date) => {
                                field.onChange(date ? date.toISOString() : "");
                              }}
                              locale={es}
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="ocurrenceDescription"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Descripción de la ocurrencia</FormLabel>
                      <FormControl>
                        <Input {...field} disabled={!!siniestroInfo} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="companyResponsible"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email del tramitador de la Compañía</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          onChange={(e) =>
                            field.onChange(e.target.value.trim())
                          }
                          disabled={!!siniestroInfo}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="contactEmail"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email de Contacto del Asegurado</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          onChange={(e) =>
                            field.onChange(e.target.value.trim())
                          }
                          disabled={!!siniestroInfo}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="coveredValue"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Valor Asegurado de la Póliza (colocá 1 si vas a cargar
                        más de una cobertura)
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type="number"
                          disabled={!!siniestroInfo}
                          onChange={(e) => {
                            const value = e.target.value
                              ? parseInt(e.target.value, 10)
                              : "";
                            if (
                              typeof value === "number" &&
                              value > 2147483647
                            ) {
                              form.setError("coveredValue", {
                                type: "max",
                                message:
                                  "El valor no puede ser mayor a 2,147,483,647",
                              });
                            } else {
                              form.clearErrors("coveredValue");
                              field.onChange(value);
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="mt-4 flex justify-center">
                {isLoading ? (
                  <ButtonLoading />
                ) : (
                  <Button
                    type="submit"
                    disabled={!!siniestroInfo || isLoading}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    {isLoading ? "Loading..." : "Crear siniestro"}
                  </Button>
                )}
              </div>
            </form>
          </Form>
        </div>
        {siniestroInfo && (
          <Card className="flex-1 mt-16 ml-8 max-w-sm">
            <CardHeader>
              <CardTitle>Información del siniestro creado</CardTitle>
            </CardHeader>
            <CardContent>
              <p>
                <strong>Compañía:</strong>{" "}
                {companias.find(
                  (company) => company.id === siniestroInfo.company_id
                )?.name || "No especificado"}
              </p>
              <p>
                <strong>Número de Siniestro de la Compañía:</strong>{" "}
                {siniestroInfo.companyIncidentNumber}
              </p>
              <p>
                <strong>Número de Póliza:</strong> {siniestroInfo.policyNumber}
              </p>
              <p>
                <strong>Tipo de Póliza:</strong> {siniestroInfo.policyType}
              </p>
              <p>
                <strong>Observaciones de la Póliza:</strong>{" "}
                {siniestroInfo.policyObservations}
              </p>
              <p>
                <strong>Fecha y Hora de Denuncia:</strong>{" "}
                {format(parseISO(siniestroInfo.denunciationDatetime), "PPP", {
                  locale: es,
                })}
              </p>
              <p>
                <strong>Fecha y Hora de Ocurrencia:</strong>{" "}
                {format(parseISO(siniestroInfo.ocurrenceDatetime), "PPP", {
                  locale: es,
                })}
              </p>
              <p>
                <strong>Descripción de la Ocurrencia:</strong>{" "}
                {siniestroInfo.ocurrenceDescription}
              </p>
              <p>
                <strong>Observaciones de la póliza:</strong>{" "}
                {siniestroInfo.policyObservations}
              </p>
              <p>
                <strong>Email del Tramitador de la Compañía:</strong>{" "}
                {siniestroInfo.companyResponsible}
              </p>
              <p>
                <strong>Email de Contacto del Asegurado:</strong>{" "}
                {siniestroInfo.contactEmail}
              </p>
              <p>
                <strong>Valor Asegurado de la Póliza:</strong>{" "}
                {new Intl.NumberFormat("es-AR", {
                  style: "currency",
                  currency: "ARS",
                }).format(siniestroInfo.coveredValue)}
              </p>
              <p>
                <strong>Tramitador:</strong>{" "}
                {tramitadores &&
                  siniestroInfo &&
                  tramitadores.find(
                    (tramitador) => tramitador.id === siniestroInfo.responsible
                  )?.display_name}
              </p>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
}
