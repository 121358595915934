import { create } from "zustand";
import useAuthStore from "./authStore";
import { apiFetch } from "@/services/siniestroService";

interface Role {
  id: number;
  name: string;
}

const BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

interface UserState {
  updateUserRoles: (userId: number, selectedRoles: number[]) => unknown;
  users: any[];
  setUsers: (users: any[]) => void;
  fetchUsers: () => Promise<void>;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  roles: Role[];
  setRoles: (roles: Role[]) => void;
  fetchRoles: () => Promise<void>;
  currentUserRoles: string[];
  setCurrentUserRoles: (roles: string[]) => void;
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
  userDisabled: boolean;
  editDialogId: number | null;
  setEditDialogId: (id: number | null) => void;
  editRolesDialogId: number | null;
  setEditRolesDialogId: (id: number | null) => void;
}

const useUserStore = create<UserState>((set) => {
  return {
    // Comienza el objeto de estado
    users: [],
    setUsers: (users) => {
      set({ users });
    },
    userDisabled: false,
    fetchUsers: async () => {
      set({ isLoading: true });
      try {
        const response = await apiFetch(`/api/users`, {
          headers: {
            Content: "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          if (response.status === 401) {
            return; // Salir temprano de la función para evitar procesamiento adicional
          } else {
            throw new Error("Error fetching users");
          }
        }

        const result = await response.json();

        if (Array.isArray(result.users)) {
          set({ users: result.users });
        } else {
        }
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === "USER_DISABLED") {
            useAuthStore.getState().setUserDisabled(true);
          } else {
          }
        } else {
          // Manejo para casos donde error no es una instancia de Error
        }
      } finally {
        set({ isLoading: false });
      }
    },
    currentPage: 0,
    setCurrentPage: (page) => {
      set({ currentPage: page });
    },
    fetchRoles: async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/users/roles/all`, {
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        const result = await response.json();

        if (
          Array.isArray(result.roles) &&
          Boolean(
            result.roles.every(
              (role: { id: any; name: any }) =>
                typeof role.id === "number" && typeof role.name === "string",
            ),
          )
        ) {
          set({ roles: result.roles });
        } else {
          console.error(
            "The received roles format is incorrect:",
            result.roles,
          );
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    },

    roles: [],
    setRoles: (roles) => {
      set({ roles });
    },
    currentUserRoles: [],
    setCurrentUserRoles: (roles) => {
      set({ currentUserRoles: roles });
    },
    updateUserRoles: (userId: number, roles: number[]) => {
      set((state) => {
        const updatedUsers = state.users.map((user) =>
          user.id === userId ? { ...user, roles } : user,
        );
        return { users: updatedUsers };
      });
    },
    isLoading: false,
    setLoading: (loading) => {
      set({ isLoading: loading });
    },
    editDialogId: null,
    setEditDialogId: (id) => {
      set({ editDialogId: id });
    },
    editRolesDialogId: null,
    setEditRolesDialogId: (id) => {
      set({ editRolesDialogId: id });
    },
  };
});

export default useUserStore;
