import { apiFetch } from "./siniestroService";

export async function fetchGastosProveedores(
  pageIndex: number,
  pageSize: number,
  proveedorId?: number,
  startDate?: Date,
  endDate?: Date,
  isPaid?: boolean // Nuevo parámetro
): Promise<any> {
  const params = new URLSearchParams();

  params.append("pageIndex", pageIndex.toString());
  params.append("pageSize", pageSize.toString());

  if (proveedorId !== undefined) {
    params.append("proveedorId", proveedorId.toString());
  }
  if (startDate) {
    params.append("startDate", startDate.toISOString());
  }
  if (endDate) {
    params.append("endDate", endDate.toISOString());
  }
  if (isPaid !== undefined) {
    params.append("isPaid", isPaid !== undefined ? (isPaid ? "1" : "0") : "");
  }

  const response = await apiFetch(
    `/api/gastos-siniestros/proveedores?${params.toString()}`
  );

  if (!response.ok) {
    throw new Error("Error al obtener los gastos de proveedores");
  }

  const data = await response.json();

  return data;
}

export async function exportGastosProveedoresExcel(
  proveedorId?: number,
  startDate?: Date,
  endDate?: Date
) {
  const params = new URLSearchParams();

  if (proveedorId !== undefined) {
    params.append("proveedorId", proveedorId.toString());
  }
  if (startDate) {
    params.append("startDate", startDate.toISOString());
  }
  if (endDate) {
    params.append("endDate", endDate.toISOString());
  }

  const response = await apiFetch(
    `/api/gastos-siniestros/export?${params.toString()}`
  );

  if (!response.ok) {
    throw new Error("Error al exportar los datos");
  }

  return response.blob();
}

export async function updateGastoStatus(gastoId: number, newStatus: boolean) {
  const response = await apiFetch(
    `/api/gastos-siniestros/proveedores/${gastoId}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ is_paid: newStatus }),
    }
  );

  if (!response.ok) {
    throw new Error("Error al actualizar el estado del gasto");
  }

  return await response.json();
}
