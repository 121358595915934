import { type SVGProps } from "react";

export function Icons8Support(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M21 3c-4.43 0-8 3.57-8 8c0 .235.037.553.063.844L4.5 20.406a5 5 0 0 0 0 7.094a5 5 0 0 0 7.094 0l8.562-8.563c.29.026.61.063.844.063c4.43 0 8-3.57 8-8a7.597 7.597 0 0 0-.938-3.688l-.625-1.156l-.937.938l-4.313 4.28l-1.562-1.562l4.28-4.312l.94-.938l-1.157-.625A7.618 7.618 0 0 0 21 3zm0 2c.486 0 .848.21 1.28.313l-3.78 3.78l-.72.72l.72.687l3 3l.688.72l.718-.72l3.782-3.78c.103.432.312.794.312 1.28c0 3.37-2.63 6-6 6c-.4 0-.72 0-.97-.063l-.53-.156l-.406.407l-8.907 8.907c-1.23 1.23-3.05 1.23-4.28 0l-.032-.032c-1.2-1.23-1.19-3.03.03-4.25l8.907-8.906l.407-.406l-.158-.53c-.06-.25-.062-.57-.062-.97c0-3.37 2.63-6 6-6z"
      />
    </svg>
  );
}
