import { create } from "zustand";

import { fetchInstancias, updateInstancia } from "@/services/instanciaService";
import useAuthStore from "./authStore";

interface Instancia {
  id: number;
  name: string;
}

interface InstanciaState {
  instancias: Instancia[];
  isLoading: boolean; // Añadir estado de carga
  currentPage: number;
  fetchInstancias: () => Promise<void>;
  updateInstancia: (instanciaId: number, values: any) => Promise<void>;
  setCurrentPage: (page: number) => void;
  editDialogId: number | null;
  setEditDialogId: (id: number | null) => void;
  setLoading: (loading: boolean) => void; // Método para establecer el estado de carga
}

export const useInstanciaStore = create<InstanciaState>((set) => ({
  instancias: [],
  isLoading: false, // Inicializar el estado de carga como false
  currentPage: 0,
  fetchInstancias: async () => {
    try {
      const instanciasData = await fetchInstancias();
      set({ instancias: instanciasData });
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "USER_DISABLED") {
          useAuthStore.getState().setUserDisabled(true);
        } else {
          throw error;
        }
      }
    }
  },
  updateInstancia: async (instanciaId, values) => {
    set({ isLoading: true }); // Establece el estado de carga antes de la operación
    try {
      await updateInstancia(instanciaId, values);
      // Luego de la operación exitosa puedes actualizar el estado global si es necesario
    } catch (error) {
      // Manejo de errores
    } finally {
      set({ isLoading: false }); // Restablecer el estado de carga al final de la operación
    }
  },
  setCurrentPage: (page) => {
    set({ currentPage: page });
  },
  editDialogId: null,
  setEditDialogId: (id) => {
    set({ editDialogId: id });
  },
  setLoading: (loading) => {
    set({ isLoading: loading });
  }, // Método para actualizar el estado de carga
}));
