import React from "react";
import "./spinner.css";

const Spinner: React.FC = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      }}
    >
      <div className="loader"></div>
    </div>
  );
};

export default Spinner;
