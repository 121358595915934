import React, { useEffect, useState } from "react";
import {
  fetchAllProveedores,
  fetchProveedoresSiniestro,
  assignProveedorToSiniestro,
} from "@/services/proveedoresService";
import {
  addAccionToSiniestro,
  createNotificacionForAction,
} from "@/services/siniestroService";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import Spinner from "@/components/ui/spinner";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { toast } from "sonner";
import EnviarMensajeModal from "@/components/notificaciones/EnviarMensajeModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface Proveedor {
  id: number;
  display_name: string;
}

interface ProveedoresSiniestroProps {
  siniestroId: number;
  companyIncidentNumber: string;
}

const ProveedoresSiniestro: React.FC<ProveedoresSiniestroProps> = ({
  siniestroId,
  companyIncidentNumber,
}) => {
  const [proveedores, setProveedores] = useState<Proveedor[]>([]);
  const [siniestroProveedores, setSiniestroProveedores] = useState<number[]>(
    []
  );
  const [originalProveedores, setOriginalProveedores] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedProveedor, setSelectedProveedor] = useState<Proveedor | null>(
    null
  );

  useEffect(() => {
    const loadProveedores = async () => {
      try {
        setIsLoading(true);
        const allProveedores = await fetchAllProveedores();
        const siniestroProveedores =
          await fetchProveedoresSiniestro(siniestroId);

        // Ordenar alfabéticamente
        allProveedores.sort((a: Proveedor, b: Proveedor) =>
          a.display_name.localeCompare(b.display_name)
        );

        setProveedores(allProveedores);
        const siniestroProveedoresIds = siniestroProveedores.map(
          (p: Proveedor) => p.id
        );
        setSiniestroProveedores(siniestroProveedoresIds);
        setOriginalProveedores(siniestroProveedoresIds); // Guardar la lista original de proveedores asignados
      } catch (error) {
        setError("Error al cargar los proveedores");
      } finally {
        setIsLoading(false);
      }
    };

    loadProveedores();
  }, [siniestroId]);

  const handleCheckboxChange = (proveedorId: number) => {
    setSiniestroProveedores((prevState) => {
      const newState = prevState.includes(proveedorId)
        ? prevState.filter((id) => id !== proveedorId)
        : [...prevState, proveedorId];
      return newState;
    });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await assignProveedorToSiniestro(siniestroId, siniestroProveedores);

      const removedProveedores = originalProveedores.filter(
        (id) => !siniestroProveedores.includes(id)
      );
      const addedProveedores = siniestroProveedores.filter(
        (id) => !originalProveedores.includes(id)
      );

      // Registrar la acción de asignación de proveedores
      for (const id of addedProveedores) {
        await addAccionToSiniestro(siniestroId, 552, id, null);
        await createNotificacionForAction(id, 552, siniestroId);
      }

      // Registrar la acción de eliminación de proveedores
      for (const id of removedProveedores) {
        await addAccionToSiniestro(siniestroId, 551, id, null);
      }

      setOriginalProveedores(siniestroProveedores); // Actualizar la lista original de proveedores asignados
      setError(null);
      toast.success("Proveedores actualizados correctamente");
    } catch (error) {
      setError("Error al actualizar los proveedores");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const renderDropdownMenu = () => {
    const assignedProveedores = proveedores.filter((proveedor) =>
      siniestroProveedores.includes(proveedor.id)
    );

    if (assignedProveedores.length === 1) {
      return (
        <Button
          onClick={() => setSelectedProveedor(assignedProveedores[0])}
          variant="green"
          className="ml-4"
        >
          Enviar mensaje al proveedor {assignedProveedores[0].display_name}
        </Button>
      );
    } else if (assignedProveedores.length > 1) {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="green">Enviar mensaje a proveedores</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuGroup>
              {assignedProveedores.map((proveedor) => (
                <DropdownMenuItem
                  key={proveedor.id}
                  onClick={() => setSelectedProveedor(proveedor)}
                >
                  {proveedor.display_name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    }

    return null;
  };

  return (
    <Card className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
      <CardHeader>
        <CardTitle>Proveedores</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-2">
          {proveedores.map((proveedor) => (
            <div key={proveedor.id} className="flex items-center space-x-3">
              <Checkbox
                checked={siniestroProveedores.includes(proveedor.id)}
                onCheckedChange={() => handleCheckboxChange(proveedor.id)}
              />
              <label className="text-sm font-normal">
                {proveedor.display_name}
              </label>
            </div>
          ))}
        </div>
        <div className="mt-4 flex justify-between items-center">
          <Button onClick={handleSave} className="ml-auto">
            Guardar
          </Button>
          {renderDropdownMenu()}
        </div>
        {selectedProveedor && (
          <EnviarMensajeModal
            proveedorId={selectedProveedor.id}
            siniestroId={siniestroId}
            companyIncidentNumber={companyIncidentNumber}
            onClose={() => setSelectedProveedor(null)}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default ProveedoresSiniestro;
