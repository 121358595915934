import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fetchTramitadores } from "@/services/siniestroService";
import { useEffect, useState } from "react";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useAuth } from "@/contexts/AuthContext";

interface TramitadorFilterProps {
  onChange: (value: string | undefined) => void;
  onMyCasesChange: (checked: boolean) => void;
  selectedValue?: string;
}

export const TramitadorFilter: React.FC<TramitadorFilterProps> = ({
  selectedValue,
  onChange,
  onMyCasesChange,
}) => {
  const [tramitadores, setTramitadores] = useState<Tramitador[]>([]);
  const [selectedTramitador, setSelectedTramitador] = useState<
    string | undefined
  >(selectedValue);
  const [myCasesChecked, setMyCasesChecked] = useState(false);
  const { roles, username } = useAuth();

  const hasRole7 = roles.includes(7);

  useEffect(() => {
    const fetchAndSetTramitadores = async () => {
      const fetchedTramitadores = await fetchTramitadores();
      setTramitadores(fetchedTramitadores);
    };

    fetchAndSetTramitadores();
  }, []);

  useEffect(() => {
    if (myCasesChecked) {
      onChange(username ?? undefined);
    } else {
      onChange(selectedTramitador);
    }
  }, [myCasesChecked, username, selectedTramitador, onChange]);

  return (
    <div className="flex items-center space-x-4">
      <Select
        value={myCasesChecked ? username ?? undefined : selectedTramitador}
        onValueChange={(value) => {
          const selectedValue = value === "all" ? undefined : value;
          setSelectedTramitador(selectedValue);
          if (!myCasesChecked) {
            onChange(selectedValue);
          }
        }}
      >
        <SelectTrigger className="w-[140px]">
          <SelectValue placeholder="Tramitadores">
            {myCasesChecked
              ? username ?? "Tramitadores"
              : selectedTramitador || "Tramitadores"}
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="max-h-[250px] overflow-auto">
          <SelectGroup>
            <SelectLabel>Tramitadores</SelectLabel>
            <SelectItem key="all" value="all">
              Todos
            </SelectItem>
            {tramitadores.map((tramitador) => (
              <SelectItem key={tramitador.id} value={tramitador.display_name}>
                {tramitador.display_name}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      {!hasRole7 && (
        <div className="flex items-center space-x-2">
          <Switch
            id="my-cases-switch"
            checked={myCasesChecked}
            onCheckedChange={(checked) => {
              setMyCasesChecked(checked);
              onMyCasesChange(checked);
              if (checked) {
                setSelectedTramitador(undefined);
              }
            }}
          />
          <Label htmlFor="my-cases-switch">Ver mis siniestros</Label>
        </div>
      )}
    </div>
  );
};
