/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { PhTrashFill } from "@/components/icons/PhTrashFill";

interface DeleteAttachmentDialogProps {
  onDelete: (attachmentId: string) => Promise<void>;
  attachmentId: string;
}

export function DeleteAttachmentDialog({
  onDelete,
  attachmentId,
}: DeleteAttachmentDialogProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = async () => {
    await onDelete(attachmentId);
    setIsOpen(false);
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger asChild>
        <Button
          onClick={() => {
            setIsOpen(true);
          }}
          variant="destructive"
          size="sm"
          className="hover:bg-opacity-80"
        >
          <PhTrashFill className="w-4 h-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Confirmar eliminación</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          ¿Estás seguro de que deseas eliminar este adjunto? Esta acción no se
          puede deshacer.
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancelar
          </AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete}>Eliminar</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
