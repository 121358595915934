/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useEffect, useState } from "react";
import { SolarNotebookOutline } from "@/components/icons/SolarNotebookOutline";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { FaChevronDown } from "react-icons/fa";
import { apiFetch } from "@/services/siniestroService";

interface EditObservacionesDialogProps {
  bienId: number;
  observaciones: string;
  onSave: (bienId: number, observaciones: string) => Promise<void>;
  onDetailsSaved: (bienId: number, updatedDetails: any) => void;
  disabled?: boolean;
}

export function EditObservacionesDialog({
  bienId,
  observaciones,
  onSave,
  onDetailsSaved,
  disabled = false,
}: EditObservacionesDialogProps) {
  const [observacionesInput, setObservacionesInput] = useState(observaciones);
  const [open, setOpen] = useState(false);
  const [plantillas, setPlantillas] = useState<
    Record<string, Record<string, string>>
  >({});

  useEffect(() => {
    const fetchPlantillas = async () => {
      try {
        const response = await apiFetch("/api/plantillas-observaciones/all");
        if (response.ok) {
          const data = await response.json();
          const formattedPlantillas = data.reduce(
            (
              acc: Record<string, Record<string, string>>,
              item: PlantillaItem
            ) => {
              if (!acc[item.CATEGORIA]) {
                acc[item.CATEGORIA] = {};
              }
              acc[item.CATEGORIA][item.SUBCATEGORIA] = item.TEXTO;
              return acc;
            },
            {}
          );
          setPlantillas(formattedPlantillas);
        }
      } catch (error) {
        console.error("Error fetching plantillas:", error);
      }
    };

    fetchPlantillas();
  }, []);

  const handleSave = async () => {
    onDetailsSaved(bienId, { observations: observacionesInput });
    await onSave(bienId, observacionesInput);
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="ml-2" disabled={disabled}>
          <SolarNotebookOutline className="w-5 h-5 mr-2" />
          Editar informe técnico
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editando informe técnico</DialogTitle>
          <DialogDescription>
            Modifica el informe técnico del bien aquí y haz clic en guardar
            cuando termines.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center justify-between">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="conformity">
                  Seleccionar plantilla <FaChevronDown className="ml-2" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {Object.keys(plantillas).map((categoria) => (
                  <DropdownMenuSub key={categoria}>
                    <DropdownMenuSubTrigger>{categoria}</DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>
                      {Object.keys(plantillas[categoria]).map(
                        (subcategoria) => (
                          <DropdownMenuItem
                            key={subcategoria}
                            onClick={() => {
                              setObservacionesInput(
                                plantillas[categoria][subcategoria]
                              );
                            }}
                          >
                            {subcategoria}
                          </DropdownMenuItem>
                        )
                      )}
                    </DropdownMenuSubContent>
                  </DropdownMenuSub>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <Textarea
            id="observacionesInput"
            value={observacionesInput}
            onChange={(e) => {
              setObservacionesInput(e.target.value);
            }}
            className="col-span-3 min-h-[150px]"
          />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button onClick={handleSave}>Guardar cambios</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="secondary">Cerrar</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
