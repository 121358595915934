import { apiFetch } from "./siniestroService";

export const fetchCompanies = async () => {
  try {
    const response = await apiFetch(`/api/companies`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.status === 403) {
      throw new Error("USER_DISABLED");
    }

    if (!response.ok) {
      throw new Error("Failed to fetch companies");
    }

    const data = await response.json();
    // Ordenar las compañías por nombre
    const sortedCompanies = data.companies.sort(
      (a: { name: string }, b: { name: string }) =>
        a.name.localeCompare(b.name),
    );
    return sortedCompanies;
  } catch (error) {
    throw error;
  }
};

export const createCompany = async (values: any) => {
  try {
    // Asegúrate de que el valor de companyParent es un string
    if (Boolean(values.hasParent) && Boolean(values.companyParent)) {
      values.companyParent = String(values.companyParent);
    } else {
      values.companyParent = "NULL";
    }
    const response = await apiFetch(`/api/companies`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateCompany = async (companyId: number, values: any) => {
  try {
    const response = await apiFetch(`/api/companies/${companyId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCompany = async (companyId: number) => {
  try {
    const response = await apiFetch(`/api/companies/${companyId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchParentableCompanies = async () => {
  try {
    const response = await apiFetch(`/api/companies/parentable`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.status === 403) {
      throw new Error("USER_DISABLED");
    }

    if (!response.ok) {
      throw new Error("Failed to fetch parentable companies");
    }

    const data = await response.json();

    // Ordenar por nombre de compañía (asumiendo que el campo se llama `name`)
    const sortedCompanies = data.companies.sort(
      (a: { name: string }, b: { name: string }) =>
        a.name.localeCompare(b.name),
    );

    return sortedCompanies;
  } catch (error) {
    throw error;
  }
};

export async function fetchCompanyChildren(companyId: number) {
  const response = await apiFetch(`/api/companies/children/${companyId}`, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Failed to fetch company children");
  }

  const data = await response.json();
  return data; // Esto debería devolver un objeto que incluya un array en la clave `companies`
}
