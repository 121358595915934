import { apiFetch } from "./siniestroService";

export const fetchProveedoresSiniestro = async (siniestroId: number) => {
  const response = await apiFetch(`/api/siniestros/${siniestroId}/proveedores`);
  if (!response.ok) {
    throw new Error("Error fetching proveedores asignados");
  }
  const data = await response.json();
  return data.proveedores;
};

export const fetchAllProveedores = async () => {
  const response = await apiFetch(`/api/siniestros/proveedores`);
  if (!response.ok) {
    throw new Error("Error fetching all proveedores");
  }
  const data = await response.json();
  return data.proveedores;
};

export const assignProveedorToSiniestro = async (
  siniestroId: number,
  proveedorIds: number[],
) => {
  const response = await apiFetch(
    `/api/siniestros/${siniestroId}/proveedores`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ proveedorIds }),
    },
  );

  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }
};
