/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from "react";
import { format, parseISO } from "date-fns";
import Spinner from "../../ui/spinner";
import { Card, CardContent } from "../../ui/card";
import { TextAreaHistorial } from "../TextAreaHistorial";
import { FaClock, FaCommentAlt, FaHistory, FaUserCircle } from "react-icons/fa";
import "./CustomScrollbar.css";

interface HistorialSiniestroProps {
  historial: HistorialSiniestro[];
  isLoadingHistorial: boolean;
  siniestroId: number;
  onHistorialUpdated: (
    updatedHistorial: React.SetStateAction<HistorialSiniestro[]>
  ) => void;
  hasRole7: boolean;
}

const convertUrlsToLinks = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    (url) =>
      `<a href="${url}" target="_blank" rel="noopener noreferrer" style="text-decoration: underline; color: blue;">${url}</a>`
  );
};

const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString || isNaN(Date.parse(dateString))) {
    return "Fecha no disponible";
  }
  return format(parseISO(dateString), "dd/MM/yyyy HH:mm");
};

export const HistorialSiniestro: React.FC<HistorialSiniestroProps> = ({
  historial,
  isLoadingHistorial,
  siniestroId,
  onHistorialUpdated,
  hasRole7,
}) => (
  <CardContent className="p-6">
    <Card className="bg-gradient-to-br from-blue-50 to-blue-100 dark:from-gray-800 dark:to-gray-700 p-6 rounded-lg shadow-lg">
      <h3 className="font-bold text-xl text-blue-700 dark:text-blue-300 mb-6 flex items-center">
        <FaHistory className="mr-3" /> Agenda del siniestro
      </h3>
      <div className="space-y-4 max-h-[32rem] overflow-y-auto pr-2 custom-scrollbar">
        {isLoadingHistorial ? (
          <Spinner />
        ) : (
          historial.map((entry, index) => (
            <div
              key={entry.id || index}
              className={`bg-white dark:bg-gray-900 p-4 rounded-2xl ${
                index === historial.length - 1
                  ? "border-2 border-blue-500 dark:border-blue-400"
                  : ""
              }`}
            >
              <div className="flex items-center justify-between text-sm text-gray-600 dark:text-gray-300 mb-2">
                <div className="flex items-center">
                  <FaUserCircle className="mr-2 text-blue-500 dark:text-blue-400" />
                  <span className="font-semibold">{entry.userName}</span>
                </div>
                <div className="flex items-center text-gray-500 dark:text-gray-400">
                  <FaClock className="mr-2" />
                  <span>{formatDate(entry.insertedAt)}</span>
                </div>
              </div>
              <div
                className="text-gray-700 dark:text-gray-200 mt-2 pl-6 border-l-2 border-blue-200 dark:border-blue-600 break-words overflow-wrap-anywhere"
                dangerouslySetInnerHTML={{
                  __html: convertUrlsToLinks(entry.text),
                }}
              />
            </div>
          ))
        )}
      </div>
    </Card>
    <Card className="bg-gradient-to-br from-green-50 to-green-100 dark:from-gray-800 dark:to-gray-700 p-4 rounded-lg shadow-lg mt-6">
      <h3 className="font-bold text-xl text-green-700 dark:text-green-300 mb-4 flex items-center">
        <FaCommentAlt className="mr-3" /> Agregar comentario
      </h3>
      <TextAreaHistorial
        siniestroId={siniestroId}
        onHistorialUpdated={onHistorialUpdated}
        hasRole7={hasRole7}
      />
    </Card>
  </CardContent>
);

export default HistorialSiniestro;
