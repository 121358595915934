import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { MdiPencilBoxMultipleOutline } from "@/components/icons/MdiPencilBoxMultipleOutline";
import { Label } from "@/components/ui/label";
import {
  updateSiniestroDetails,
  searchAseguradoByDNI,
} from "@/services/siniestroService";
import { toast } from "sonner";
import { ButtonLoading } from "@/components/ui/ButtonLoading";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import { ReloadIcon } from "@radix-ui/react-icons";
import { MdEdit } from "react-icons/md"; // Importa el ícono de edición
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface EditAseguradoDetailsDialogProps {
  siniestro: Siniestro;
  onSiniestroUpdated: (siniestro: Siniestro) => void;
  disabled?: boolean;
}

export const EditAseguradoDetailsDialog = ({
  siniestro,
  onSiniestroUpdated,
  disabled = false,
}: EditAseguradoDetailsDialogProps) => {
  const [nombre, setNombre] = useState(siniestro.insuredName);
  const [documentNumber, setDocumentNumber] = useState(siniestro.insuredDNI);
  const [documentType, setDocumentType] = useState("DNI"); // Inicializa con DNI
  const [email, setEmail] = useState(siniestro.contactEmail);
  const [telephone, setTelephone] = useState(siniestro.occurrenceTelephone);
  const [cellphone, setCellphone] = useState(siniestro.occurrenceCellphone);
  const [loading, setLoading] = useState(false);
  const [isNombreDisabled, setIsNombreDisabled] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Estado para controlar el cierre del modal

  const handleSave = async () => {
    if (disabled) return;
    setLoading(true);
    try {
      const updatedDetails = {
        insuredName: nombre,
        insuredDNI: documentType === "DNI" ? documentNumber : undefined,
        insuredCUIT: documentType === "CUIT" ? documentNumber : undefined,
        contactEmail: email,
        occurrenceTelephone: telephone,
        occurrenceCellphone: cellphone,
      };

      const updatedSiniestro = await updateSiniestroDetails(
        siniestro.id,
        updatedDetails
      );
      onSiniestroUpdated(updatedSiniestro);
      toast.success("Detalles del siniestro actualizados con éxito");
      setIsDialogOpen(false); // Cierra el modal al guardar con éxito
    } catch (error) {
      toast.error("Error al actualizar los detalles del siniestro");
    } finally {
      setLoading(false);
    }
  };

  const handleBuscar = async () => {
    if (disabled || loading) return;
    setLoading(true);
    try {
      const asegurado = await searchAseguradoByDNI(
        documentType,
        documentNumber
      );
      if (asegurado) {
        setNombre(asegurado.name);
        setIsNombreDisabled(true);
        toast.success(
          "Asegurado encontrado y nombre actualizado. Si quiere guardar los cambios, haga clic en 'Guardar cambios'"
        );
      } else {
        setNombre("");
        setIsNombreDisabled(false); // Habilita el campo de nombre
        toast.info("No se encontró asegurado, puedes crear uno nuevo");
      }
    } catch (error) {
      toast.error("Error al buscar el asegurado");
    } finally {
      setLoading(false);
    }
  };

  const handleEditNombre = () => {
    setIsNombreDisabled(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          onClick={() => setIsDialogOpen(true)}
          disabled={disabled}
        >
          <MdiPencilBoxMultipleOutline className="w-4 h-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editar detalles del asegurado</DialogTitle>
          <DialogDescription>
            Modifica los detalles del asegurado
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Label htmlFor="documentType">Tipo de documento</Label>
          <Select
            value={documentType}
            onValueChange={(value) => setDocumentType(value)}
            disabled={disabled}
          >
            <SelectTrigger>
              <SelectValue placeholder="Selecciona un tipo de documento" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="DNI">DNI</SelectItem>
                <SelectItem value="CUIT">CUIT</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>

          <Label htmlFor="documentNumber">Número de documento</Label>
          <div className="flex space-x-2">
            <Input
              id="documentNumber"
              type="text"
              value={documentNumber}
              onChange={(e) => {
                setDocumentNumber(e.target.value);
              }}
              disabled={disabled}
            />
            <Button onClick={handleBuscar} disabled={disabled || loading}>
              {loading ? <ReloadIcon className="animate-spin" /> : "Buscar"}
            </Button>
          </div>

          <Label htmlFor="nombreInput">Nombre</Label>
          <div className="flex space-x-2 items-center">
            <Input
              id="nombreInput"
              value={nombre}
              onChange={(e) => {
                setNombre(e.target.value);
              }}
              disabled={isNombreDisabled || disabled}
            />
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={handleEditNombre}
                    disabled={disabled}
                  >
                    <MdEdit className="w-4 h-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Editar nombre</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <Label htmlFor="emailInput">Email</Label>
          <Input
            id="emailInput"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            disabled={disabled}
          />
          <Label htmlFor="telephoneInput">Teléfono</Label>
          <Input
            id="telephoneInput"
            type="text"
            value={telephone}
            onChange={(e) => {
              setTelephone(e.target.value);
            }}
            disabled={disabled}
          />
          <Label htmlFor="cellphoneInput">Celular</Label>
          <Input
            id="cellphoneInput"
            type="text"
            value={cellphone}
            onChange={(e) => {
              setCellphone(e.target.value);
            }}
            disabled={disabled}
          />
        </div>
        <DialogFooter>
          {loading ? (
            <ButtonLoading />
          ) : (
            <Button onClick={handleSave} disabled={disabled}>
              Guardar cambios
            </Button>
          )}
          <DialogClose asChild>
            <Button variant="secondary" disabled={disabled}>
              Cerrar
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
