import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fetchProveedores } from "@/services/siniestroService";
import { useEffect, useState } from "react";

interface Proveedor {
  id: number;
  display_name: string;
}

interface ProveedorFilterProps {
  onChange: (value: string | undefined) => void;
  selectedValue?: string;
}

export const ProveedorFilter: React.FC<ProveedorFilterProps> = ({
  selectedValue,
  onChange,
}) => {
  const [proveedores, setProveedores] = useState<Proveedor[]>([]);
  const [selectedProveedor, setSelectedProveedor] = useState<
    string | undefined
  >(selectedValue);

  useEffect(() => {
    const fetchAndSetProveedores = async () => {
      const fetchedProveedores = await fetchProveedores();
      setProveedores(fetchedProveedores);
    };

    fetchAndSetProveedores();
  }, []);

  return (
    <Select
      value={selectedProveedor || "all"}
      onValueChange={(value) => {
        const selectedValue = value === "all" ? undefined : value;
        setSelectedProveedor(selectedValue);
        onChange(selectedValue);
      }}
    >
      <SelectTrigger className="w-[135px]">
        <SelectValue placeholder="Proveedores">
          {proveedores.find(
            (proveedor) => proveedor.display_name === selectedProveedor,
          )?.display_name || "Proveedores"}
        </SelectValue>
      </SelectTrigger>
      <SelectContent className="max-h-[250px] overflow-auto">
        <SelectGroup>
          <SelectLabel>Proveedores</SelectLabel>
          <SelectItem key="all" value="all">
            Todos
          </SelectItem>
          {proveedores.map((proveedor) => (
            <SelectItem key={proveedor.id} value={proveedor.display_name}>
              {proveedor.display_name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
