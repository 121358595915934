import { create } from "zustand";

import {
  fetchCompanies,
  fetchParentableCompanies,
  updateCompany,
} from "@/services/companyService";
import useAuthStore from "./authStore";

interface CompanyState {
  companies: Company[];
  isLoading: boolean; // Añadir estado de carga
  parentableCompanies: Company[];
  currentPage: number;
  fetchCompanies: () => Promise<void>;
  fetchParentableCompanies: () => Promise<void>;
  updateCompany: (companyId: number, values: any) => Promise<void>;
  setCurrentPage: (page: number) => void;
  editDialogId: number | null;
  setEditDialogId: (id: number | null) => void;
  setParentableCompanies: (companies: Company[]) => void;
  setLoading: (loading: boolean) => void; // Método para establecer el estado de carga
}

export const useCompanyStore = create<CompanyState>((set) => ({
  companies: [],
  parentableCompanies: [],
  isLoading: false, // Inicializar el estado de carga como false
  currentPage: 0,
  fetchCompanies: async () => {
    try {
      const companiesData = await fetchCompanies();
      set({ companies: companiesData });
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "USER_DISABLED") {
          useAuthStore.getState().setUserDisabled(true);
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    }
  },
  fetchParentableCompanies: async () => {
    try {
      const companiesData = await fetchParentableCompanies();
      set({ parentableCompanies: companiesData });
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "USER_DISABLED") {
          useAuthStore.getState().setUserDisabled(true);
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    }
  },
  updateCompany: async (companyId, values) => {
    set({ isLoading: true }); // Inicio de la operación
    try {
      await updateCompany(companyId, values);
    } catch (error) {
      // Manejo de errores
    } finally {
      set({ isLoading: false }); // Fin de la operación
    }
  },
  editDialogId: null,
  setEditDialogId: (id) => {
    set(() => ({ editDialogId: id }));
  },
  setCurrentPage: (page) => {
    set({ currentPage: page });
  },
  setParentableCompanies: (companies) => {
    set({ parentableCompanies: companies });
  },
  setLoading: (loading) => {
    set({ isLoading: loading });
  }, // Método para actualizar el estado de carga
}));
