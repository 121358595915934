import { type SVGProps } from "react";

export function MaterialSymbolsLightBookmarkManagerSharp(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.23 21v-2.21l5.96-5.934l2.19 2.204L16.44 21h-2.21Zm5.96-4.985l.925-.955l-.925-.945l-.95.95l.95.95ZM3 19V5h6.596l2 2H21v1.696l-9.154 9.096V19H3Z"
      />
    </svg>
  );
}
