/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Card, CardContent } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { SlashIcon } from "@radix-ui/react-icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  useAseguradoStore,
  useDireccionStore,
  useSiniestroFormStore,
} from "@/stores/formStore";

const sidebarNavItems = [
  {
    title: "Asegurado",
    href: "/nuevo-siniestro/nuevo-asegurado",
    alwaysEnabled: true,
  },
  {
    title: "Dirección",
    href: "/nuevo-siniestro/nueva-direccion",
    dependency: "isAseguradoConfirmed",
  },
  {
    title: "Detalles del siniestro",
    href: "/nuevo-siniestro/nuevo-siniestro",
    dependency: "isDireccionCompleted",
  },
  {
    title: "Bienes",
    href: "/nuevo-siniestro/nuevo-bien",
    dependency: "isSiniestroCompleted",
  },
];

export interface SiniestroLayoutProps {
  children: React.ReactNode;
}

const SiniestroLayout: React.FC<SiniestroLayoutProps> = ({ children }) => {
  const isAseguradoConfirmed = useAseguradoStore(
    (state) => state.isAseguradoConfirmed,
  );
  const isDireccionCompleted = useDireccionStore(
    (state) => state.isDireccionCompleted,
  );
  const isSiniestroCompleted = useSiniestroFormStore(
    (state) => state.isSiniestroCompleted,
  );

  const navigate = useNavigate();
  const location = useLocation();

  const handleBreadcrumbClick = (item: {
    href: string;
    dependency?: string;
    alwaysEnabled?: boolean;
  }) => {
    // Check if the breadcrumb item's dependency is met
    const isEnabled = item.alwaysEnabled ?? checkDependency(item.dependency);

    // Only navigate if enabled AND the target is not the current location
    if (isEnabled && item.href !== location.pathname) {
      navigate(item.href);
    }
  };

  const checkDependency = (dependency?: string) => {
    switch (dependency) {
      case "isAseguradoConfirmed":
        return isAseguradoConfirmed;
      case "isDireccionCompleted":
        return isDireccionCompleted;
      case "isSiniestroCompleted":
        return isSiniestroCompleted;
      default:
        return false;
    }
  };

  return (
    <div className="flex justify-center p-8 bg-white dark:bg-gray-800">
      <Card className="bg-white dark:bg-gray-800 shadow-lg rounded-lg w-full">
        <CardContent className="p-6">
          <div className="space-y-0.5 mb-6">
            <h2 className="text-2xl font-bold tracking-tight text-black dark:text-white">
              Nuevo siniestro
            </h2>
            <p className="text-muted-foreground dark:text-gray-300">
              Gestiona la información para la carga de un nuevo siniestro.
            </p>
          </div>
          <Breadcrumb>
            <BreadcrumbList>
              {sidebarNavItems.map((item, index) => {
                const isEnabled =
                  item.alwaysEnabled ?? checkDependency(item.dependency);
                return (
                  <React.Fragment key={item.href}>
                    <BreadcrumbItem>
                      <NavLink
                        to="#"
                        onClick={() => handleBreadcrumbClick(item)}
                        className={
                          ({ isActive }) =>
                            `breadcrumb-link ${
                              isActive ? "text-blue-500" : ""
                            } ${
                              isEnabled
                                ? ""
                                : "pointer-events-none text-gray-400"
                            }` // Update this line
                        }
                      >
                        {item.title}
                      </NavLink>
                    </BreadcrumbItem>
                    {index < sidebarNavItems.length - 1 && (
                      <BreadcrumbSeparator>
                        <SlashIcon />
                      </BreadcrumbSeparator>
                    )}
                  </React.Fragment>
                );
              })}
            </BreadcrumbList>
          </Breadcrumb>
          <Separator className="my-4" />
          <div className="mt-10">{children}</div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SiniestroLayout;
