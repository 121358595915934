import { useState, useEffect } from "react";
import { fetchHonorariosByCompanyId } from "@/services/ingresoService";

export const usePredefinedHonorarios = (companyId: number) => {
  const [predefinedHonorarios, setPredefinedHonorarios] = useState<
    PredefinedHonorario[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadHonorarios = async () => {
      setIsLoading(true);
      try {
        const honorarios = await fetchHonorariosByCompanyId(companyId);
        setPredefinedHonorarios(honorarios);
      } catch (err) {
        setError("Failed to load predefined honorarios");
      } finally {
        setIsLoading(false);
      }
    };

    if (companyId) {
      loadHonorarios();
    }
  }, [companyId]);

  return { predefinedHonorarios, isLoading, error };
};
