"use client";

import { DataTablePagination } from "./DataTablePagination";
import { SkeletonRow } from "../ui/SkeletonRow";
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  getSortedRowModel,
  type ColumnSort,
} from "@tanstack/react-table";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { useEffect, useState } from "react";
import useUserStore from "@/stores/userStore";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useTipoPagoStore } from "@/stores/tipoPagoStore";
import { useResolucionStore } from "@/stores/resolucionStore";
import { useCausaStore } from "@/stores/causaStore";
import { useEstadoStore } from "@/stores/estadoStore";
import { useInstanciaStore } from "@/stores/instanciaStore";
import { useSegmentoStore } from "@/stores/segmentoStore";
import { useCompanyStore } from "@/stores/companyStore";
import { useSiniestroStore } from "@/stores/siniestroStore";
import { LaBroom } from "../icons/LaBroom";

type StoreType =
  | "tipoPago"
  | "resolucion"
  | "causa"
  | "company"
  | "estado"
  | "instancia"
  | "segmento"
  | "user"
  | "predefinedHonorarios"
  | "siniestro";

interface DataTableProps<TData extends WithId, TValue> {
  columns: Array<ColumnDef<TData, TValue>>;
  data: TData[];
  currentPage: number;
  headerRight?: React.ReactNode;
  isLoading?: boolean;
  storeType: StoreType;
  filterComponent?: React.ReactNode;
}

export function DataTable<TData extends WithId, TValue>({
  columns,
  data,
  currentPage,
  headerRight,
  isLoading = false,
  storeType,
  filterComponent, // Agrega esto
}: DataTableProps<TData, TValue>) {
  const [searchTerm, setSearchTerm] = useState("");

  let editDialogId: number | null = null; // Declara la variable aquí
  let editRolesDialogId: number | null = null; // Declara la variable aquí

  switch (storeType) {
    case "tipoPago":
      editDialogId = useTipoPagoStore((state) => state.editDialogId);
      break;
    case "resolucion":
      editDialogId = useResolucionStore((state) => state.editDialogId);
      break;
    case "causa":
      editDialogId = useCausaStore((state) => state.editDialogId);
      break;
    case "company":
      editDialogId = useCompanyStore((state) => state.editDialogId);
      break;
    case "estado":
      editDialogId = useEstadoStore((state) => state.editDialogId);
      break;
    case "instancia":
      editDialogId = useInstanciaStore((state) => state.editDialogId);
      break;
    case "segmento":
      editDialogId = useSegmentoStore((state) => state.editDialogId);
      break;
    case "user":
      editDialogId = useUserStore((state) => state.editDialogId);
      editRolesDialogId = useUserStore((state) => state.editRolesDialogId);
      break;
    case "siniestro":
      editDialogId = useSiniestroStore((state) => state.editDialogId);
      break;
    default:
      editDialogId = null;
  }

  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [searchTerm]);

  function filterData<T>(data: T[], searchTerm: string): T[] {
    return data.filter((item) => {
      const recordItem = item as Record<string, unknown>;
      return Object.values(recordItem).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  const filteredData =
    searchTerm.length > 0 ? filterData(data, searchTerm) : data;

  const [sorting, setSorting] = useState<ColumnSort[]>([]);
  const [pagination, setPagination] = useState({
    pageIndex: currentPage,
    pageSize: 10,
  });

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination,
    },
    autoResetPageIndex: false,
  });

  const setCurrentPage = useUserStore((state) => state.setCurrentPage);

  useEffect(() => {
    setCurrentPage(table.getState().pagination.pageIndex);
    setPagination(table.getState().pagination);
  }, [table.getState().pagination.pageIndex, setCurrentPage]);

  return (
    <>
      <div className="py-6">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex flex-col md:flex-row md:flex-1 md:items-center md:space-x-2">
            <Input
              placeholder="Buscar..."
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
              className="w-full md:max-w-sm mb-2 md:mb-0"
            />
            {searchTerm.length > 0 && (
              <Button
                aria-label="Limpiar búsqueda"
                onClick={() => {
                  setSearchTerm("");
                }}
                variant="ghost"
              >
                Limpiar <LaBroom className="ml-2" />
              </Button>
            )}
            <div className="text-center md:text-left">
              {" "}
              {/* Centrado en móviles, texto a la izquierda en escritorio */}
              {filterComponent}
            </div>
          </div>
          <div className="mt-2 md:mt-0 md:inline-block">
            {" "}
            {/* Margen superior solo para móviles */}
            {headerRight}
          </div>
        </div>
      </div>

      <div className="rounded-md border overflow-x-auto">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row) => {
              // Verifica si la fila actual está siendo editada
              const isRowBeingEdited =
                isLoading &&
                ((row.original as WithId).id === editDialogId ||
                  (row.original as WithId).id === editRolesDialogId);
              return isRowBeingEdited ? (
                <SkeletonRow key={row.id} columnsCount={columns.length} />
              ) : (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() ? "selected" : undefined}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </>
  );
}
