import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { toast } from "sonner";
import { ReloadIcon } from "@radix-ui/react-icons";
import { addAccionToSiniestro, apiFetch } from "@/services/siniestroService";
import { type CarouselApi } from "@/components/ui/carousel";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAuth } from "@/contexts/AuthContext";
import useEmblaCarousel from "embla-carousel-react";
import { ChevronLeft, ChevronRight, RotateCcw, RotateCw } from "lucide-react";
import { FaTrash } from "react-icons/fa";
import { Skeleton } from "@/components/ui/skeleton";
import { Card, CardContent, CardFooter } from "@/components/ui/card";

interface ImageDialogProps {
  images: string[];
  isOpen: boolean;
  onClose: () => void;
  refreshImages: () => void;
  bienId: number;
  siniestroId: number;
}

const ImageDialog: React.FC<ImageDialogProps> = ({
  images,
  isOpen,
  onClose,
  refreshImages,
  bienId,
  siniestroId,
}) => {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [api, setApi] = useState<CarouselApi | undefined>(undefined);
  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(images.length);
  const { roles } = useAuth();
  const hasRole2 = roles.includes(2);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState<string | null>(null);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false, duration: 20 });
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [carouselKey, setCarouselKey] = useState(0);
  const [imagesLoading, setImagesLoading] = useState(true);
  const [rotations, setRotations] = useState<Record<string, number>>({});
  const [isRotating, setIsRotating] = useState<Record<string, boolean>>({});

  const handleRotate = (imageUrl: string, angle: number) => {
    setRotations((prev) => ({
      ...prev,
      [imageUrl]: ((prev[imageUrl] || 0) + angle + 360) % 360,
    }));
  };

  const handleSaveRotation = async (imageUrl: string) => {
    setIsRotating((prev) => ({ ...prev, [imageUrl]: true }));
    try {
      const response = await apiFetch(`/api/bienes/${bienId}/rotate-image`, {
        method: "POST",
        body: JSON.stringify({ imageUrl, rotation: rotations[imageUrl] || 0 }),
      });

      if (!response.ok) {
        throw new Error("Error saving image rotation");
      }

      toast.success("Rotación guardada correctamente");
      refreshImages();

      setCarouselKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("Error saving image rotation:", error);
      toast.error("Error saving image rotation");
    } finally {
      setIsRotating((prev) => ({ ...prev, [imageUrl]: false }));
    }
  };

  useEffect(() => {
    if (isOpen) {
      setImagesLoading(true);
      const timer = setTimeout(() => {
        setImagesLoading(false);
      }, 200); // Adjust timing as needed
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!api) return;
    setApi(api);
    setCount(images.length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api, images.length]);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const onDotButtonClick = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setCurrent(emblaApi.selectedScrollSnap() + 1);
      setPrevBtnDisabled(!emblaApi.canScrollPrev());
      setNextBtnDisabled(!emblaApi.canScrollNext());
      setSelectedIndex(emblaApi.selectedScrollSnap());
    };

    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);

    return () => {
      emblaApi.off("select", onSelect);
      emblaApi.off("reInit", onSelect);
    };
  }, [emblaApi]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedImages(Array.from(event.target.files));
    }
  };

  const handleUpload = async () => {
    if (selectedImages.length === 0) {
      alert("Por favor, selecciona al menos una imagen.");
      return;
    }

    setIsUploading(true);

    try {
      const formData = new FormData();
      selectedImages.forEach((image) => {
        formData.append("files", image);
      });
      formData.append("incidentId", siniestroId.toString());

      const response = await apiFetch(`/api/bienes/${bienId}/upload`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Error al subir las imágenes.");
      }

      await refreshImages();
      setCount(images.length + selectedImages.length);
      if (emblaApi) {
        emblaApi.reInit();
        setScrollSnaps(emblaApi.scrollSnapList());
        setSelectedIndex(emblaApi.selectedScrollSnap());
      }
      setScrollSnaps(emblaApi?.scrollSnapList() || []);
      setCarouselKey((prevKey) => prevKey + 1);
      emblaApi?.reInit();
      setSelectedImages([]);
      toast.success("Imágenes subidas correctamente.");
      await addAccionToSiniestro(siniestroId, 32, null, bienId);
    } catch (error) {
      console.error("Error al subir las imágenes:", error);
      toast.error("Error al subir las imágenes.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDownloadPDF = async () => {
    setIsDownloading(true);

    try {
      const response = await apiFetch(`/api/pdf/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ imageUrls: images }),
      });

      if (!response.ok) {
        throw new Error("Error generating PDF");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "images.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

      toast.success("PDF generado correctamente");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Error al generar el PDF");
    } finally {
      setIsDownloading(false);
    }
  };

  const confirmDeleteImage = (imageUrl: string) => {
    setImageToDelete(imageUrl);
    setIsConfirmDialogOpen(true);
  };

  const handleDeleteImage = async () => {
    if (!imageToDelete) return;

    try {
      const response = await apiFetch(
        `/api/bienes/${bienId}/images?imageUrl=${encodeURIComponent(
          imageToDelete
        )}`,
        { method: "DELETE" }
      );

      if (!response.ok) {
        throw new Error("Error al eliminar la imagen.");
      }

      setCount((prevCount) => prevCount - 1);
      await refreshImages();

      if (emblaApi) {
        emblaApi.reInit();
        const newScrollSnaps = emblaApi.scrollSnapList();
        setScrollSnaps(newScrollSnaps);
        setSelectedIndex(emblaApi.selectedScrollSnap());
        setCurrent(emblaApi.selectedScrollSnap() + 1);
      }

      setCarouselKey((prevKey) => prevKey + 1);
      setIsConfirmDialogOpen(false);
      setImageToDelete(null);
      toast.success("Imagen eliminada correctamente.");
    } catch (error) {
      console.error("Error al eliminar la imagen:", error);
      toast.error("Error al eliminar la imagen.");
    }
  };

  useEffect(() => {
    if (!api) return;

    setCount(images.length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api, images.length]);
  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[450px] mx-auto overflow-y-scroll max-h-screen">
          <DialogHeader>
            <DialogTitle>Imágenes del bien</DialogTitle>
            <DialogDescription className="mb-10">
              Aquí puedes ver y gestionar las imágenes del bien seleccionado.
            </DialogDescription>
          </DialogHeader>
          <div className="max-w-xs mx-auto mb-4 relative">
            {imagesLoading ? (
              <div className="flex items-center justify-center">
                <Skeleton className="h-[200px] w-[200px] rounded-lg" />
              </div>
            ) : (
              <div
                className="embla overflow-hidden"
                ref={emblaRef}
                key={carouselKey}
              >
                <div className="embla__container flex">
                  {images.map((image, index) => (
                    <div
                      className={`embla__slide flex-[0_0_100%] min-w-0 relative ${
                        index === selectedIndex ? "embla__slide--active" : ""
                      }`}
                      key={index}
                    >
                      <Card className="overflow-hidden">
                        <CardContent className="p-2">
                          <a
                            href={image}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block w-[200px] h-[200px] overflow-hidden"
                          >
                            <img
                              src={image}
                              alt={`Imagen ${selectedIndex + 1}`}
                              className="w-full h-full object-contain rounded-lg mx-auto"
                              style={{
                                transform: `rotate(${
                                  rotations[image] || 0
                                }deg)`,
                              }}
                            />
                          </a>
                        </CardContent>
                        <CardFooter className="p-2 flex justify-between items-center">
                          <div className="flex space-x-2">
                            <Button
                              onClick={() => handleRotate(image, -90)}
                              size="sm"
                              variant="outline"
                            >
                              <RotateCcw className="h-4 w-4" />
                            </Button>
                            <Button
                              onClick={() => handleRotate(image, 90)}
                              size="sm"
                              variant="outline"
                            >
                              <RotateCw className="h-4 w-4" />
                            </Button>
                          </div>
                          {rotations[image] !== undefined &&
                            rotations[image] !== 0 && (
                              <Button
                                onClick={() => handleSaveRotation(image)}
                                size="sm"
                                variant="default"
                                disabled={isRotating[image]}
                                className="ml-2"
                              >
                                {isRotating[image] ? (
                                  <>
                                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                                    Guardando...
                                  </>
                                ) : (
                                  "Guardar rotación"
                                )}
                              </Button>
                            )}
                        </CardFooter>
                      </Card>
                      {hasRole2 && (
                        <Button
                          onClick={() => confirmDeleteImage(image)}
                          variant="destructive"
                          className="absolute top-2 right-2"
                          size="icon"
                        >
                          <FaTrash />
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {!imagesLoading && images.length > 1 && (
              <>
                <Button
                  onClick={scrollPrev}
                  disabled={prevBtnDisabled}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-full"
                  variant="outline"
                  size="icon"
                >
                  <ChevronLeft className="h-4 w-4" />
                </Button>
                <Button
                  onClick={scrollNext}
                  disabled={nextBtnDisabled}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-full"
                  variant="outline"
                  size="icon"
                >
                  <ChevronRight className="h-4 w-4" />
                </Button>
              </>
            )}
          </div>
          <div className="embla__dots mt-2 flex justify-center space-x-2">
            {imagesLoading ? (
              <>
                <Skeleton className="w-2 h-2 rounded-full" />
                <Skeleton className="w-2 h-2 rounded-full" />
                <Skeleton className="w-2 h-2 rounded-full" />
              </>
            ) : (
              scrollSnaps.map((_, index) => (
                <Button
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={`w-2 h-2 rounded-full ${
                    index === selectedIndex ? "bg-blue-500" : "bg-gray-300"
                  }`}
                  variant="outline"
                  size="sm"
                />
              ))
            )}
          </div>
          <div className="py-2 text-center text-sm text-muted-foreground">
            {imagesLoading ? (
              <Skeleton className="w-20 h-4 mx-auto" />
            ) : (
              `Imagen ${current} de ${count}`
            )}
          </div>
          <div className="py-4 text-center">
            <div className="mb-4">
              <Label htmlFor="picture">Cargar imágenes</Label>
              <Input
                id="picture"
                type="file"
                multiple
                onChange={handleImageChange}
                className="mt-2"
              />
            </div>
            <Button
              onClick={handleUpload}
              disabled={isUploading || selectedImages.length === 0}
              className="mb-4"
              variant="green"
            >
              {isUploading ? (
                <>
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                  Cargando...
                </>
              ) : (
                "Subir imágenes"
              )}
            </Button>
          </div>
          <DialogFooter>
            <Button
              onClick={handleDownloadPDF}
              disabled={images.length === 0 || isDownloading}
              variant="green"
            >
              {isDownloading ? (
                <>
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                  Generando PDF...
                </>
              ) : (
                "Descargar imágenes como PDF"
              )}
            </Button>
            <Button onClick={onClose}>Cerrar</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirmar eliminación</DialogTitle>
            <DialogDescription>
              ¿Estás seguro de que deseas eliminar esta imagen? Esta acción no
              se puede deshacer.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={handleDeleteImage} variant="destructive">
              Confirmar
            </Button>
            <Button
              variant="secondary"
              onClick={() => setIsConfirmDialogOpen(false)}
            >
              Cancelar
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageDialog;
