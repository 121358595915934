import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fetchSegmentos } from "@/services/segmentoService";
import { fetchCausas } from "@/services/causaService";
import {
  addAccionToSiniestro,
  updateBienDetails,
} from "@/services/siniestroService";
import { toast } from "sonner";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { RiEditLine } from "react-icons/ri";

// Esquema de validación con Zod
const formSchema = z.object({
  item_type: z.string().optional(),
  brand: z.string().optional(),
  segment_id: z.number().optional(),
  model: z.string().optional(),
  serial: z.string().optional(),
  coveredDetails: z.string().optional(),
  cause_id: z.number().optional(),
  accesories: z.string().optional(),
});

interface EditDetailsDialogProps {
  bienId: number;
  itemType: string;
  segmentName: string;
  model: string;
  serial: string;
  coveredDetails: string;
  brand?: string;
  segmentId?: number;
  causeId?: number;
  brandName?: string;
  causaNombre?: string;
  accesories?: string;
  onDetailsSaved: (bienId: number, updatedDetails: any) => void;
  siniestroId: number;
  disabled?: boolean;
}

export function EditDetailsDialog({
  bienId,
  model,
  serial,
  coveredDetails,
  brand,
  segmentId,
  causeId,
  itemType,
  brandName,
  accesories,
  siniestroId,
  onDetailsSaved,
  disabled,
}: EditDetailsDialogProps) {
  const [segments, setSegments] = useState<Segmento[]>([]);
  const [causes, setCauses] = useState<Causa[]>([]);
  const [selectedItemTypeName, setSelectedItemTypeName] = useState(itemType);
  const [selectedBrandName, setSelectedBrandName] = useState(brandName);
  const [isOpen, setIsOpen] = useState(false);

  // useForm hook
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      brand: brand || "",
      segment_id: segmentId || null,
      item_type: itemType || "",
      cause_id: causeId || null,
      model: model || "",
      serial: serial || "",
      coveredDetails: coveredDetails || "",
      accesories: accesories || "",
    },
  });

  useEffect(() => {
    const fetchCausesData = async () => {
      try {
        const causesData = await fetchCausas();
        setCauses(causesData);
      } catch (error) {
        setCauses([]);
      }
    };

    void fetchCausesData();
  }, []);

  useEffect(() => {
    const fetchSegmentsData = async () => {
      try {
        const segmentsData = await fetchSegmentos();
        setSegments(segmentsData.segmentos);
      } catch (error) {}
    };

    void fetchSegmentsData();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      const tipoBienNombre = selectedItemTypeName;
      const marcaNombre = selectedBrandName;
      const segmentoNombre =
        segments.find((segment) => segment.CODIGO_SEGMENTO === data.segment_id)
          ?.NOMBRE_SEGMENTO ?? "";
      const causaNombre =
        causes.find((cause) => cause.id === data.cause_id)?.name ?? "";

      const updatedData = {
        ...data,
        itemType: tipoBienNombre,
        brandName: marcaNombre,
        segment: segmentoNombre,
        cause: causaNombre,
      };

      await updateBienDetails(bienId, updatedData);
      await addAccionToSiniestro(siniestroId, 29, null, bienId);

      // Asegúrate de pasar los datos correctamente a la función de callback
      onDetailsSaved(bienId, updatedData);
      toast.success("Detalles actualizados");

      setIsOpen(false); // Cierra el modal después de guardar los detalles
    } catch (error) {
      toast.error("Error al actualizar detalles del bien");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="icon" disabled={disabled}>
          <RiEditLine />
        </Button>
      </DialogTrigger>
      <DialogContent className="h-[600px] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Editar detalles del bien</DialogTitle>
          <DialogDescription>
            Actualiza los detalles aquí y haz clic en guardar cuando termines.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="grid gap-4 py-4"
          >
            <Controller
              control={form.control}
              name="item_type" // Cambiado a item_type para enviar el nombre
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tipo de Bien</FormLabel>
                  <Input
                    {...field}
                    value={selectedItemTypeName}
                    onChange={(e) => {
                      setSelectedItemTypeName(e.target.value);
                      field.onChange(e.target.value); // Cambiado para aceptar cualquier texto
                    }}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <Controller
              control={form.control}
              name="segment_id"
              render={({ field }) => {
                const selectedSegmentName =
                  segments.find(
                    (segment) => segment.CODIGO_SEGMENTO === field.value
                  )?.NOMBRE_SEGMENTO ?? "";

                return (
                  <FormItem>
                    <FormLabel>Segmento</FormLabel>
                    <Select
                      onValueChange={(value) => {
                        // Encuentra el ID numérico del segmento seleccionado y actualiza el campo
                        const segmentSelected = segments.find(
                          (segment) => segment.NOMBRE_SEGMENTO === value
                        );
                        if (segmentSelected) {
                          field.onChange(segmentSelected.CODIGO_SEGMENTO);
                        }
                      }}
                      value={selectedSegmentName}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {segments.map((segment) => (
                          <SelectItem
                            key={segment.CODIGO_SEGMENTO}
                            value={segment.NOMBRE_SEGMENTO}
                          >
                            {segment.NOMBRE_SEGMENTO}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <Controller
              control={form.control}
              name="brand" // Cambiado a brand para enviar el nombre
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Marca</FormLabel>
                  <Input
                    {...field}
                    value={selectedBrandName}
                    onChange={(e) => {
                      setSelectedBrandName(e.target.value);
                      field.onChange(e.target.value);
                    }}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <Controller
              control={form.control}
              name="model"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Modelo</FormLabel>
                  <Input {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <Controller
              control={form.control}
              name="serial"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Número de Serie</FormLabel>
                  <Input {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <Controller
              control={form.control}
              name="coveredDetails"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Detalles Cubiertos</FormLabel>
                  <Input {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <Controller
              control={form.control}
              name="accesories"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Accesorios</FormLabel>
                  <Input {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <Controller
              control={form.control}
              name="cause_id"
              render={({ field }) => {
                const selectedCausaName =
                  causes.find((causa) => causa.id === field.value)?.name ?? "";

                return (
                  <FormItem>
                    <FormLabel>Causa</FormLabel>
                    <Select
                      onValueChange={(value) => {
                        // Encuentra el ID numérico de la causa seleccionada y actualiza el campo
                        const causaSelected = causes.find(
                          (causa) => causa.name === value
                        );
                        if (causaSelected) {
                          field.onChange(Number(causaSelected.id));
                        }
                      }}
                      value={selectedCausaName}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {/* Chequeo de existencia */}
                        {causes?.map((causa) => (
                          <SelectItem key={causa.id} value={causa.name}>
                            {causa.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <DialogFooter>
              <Button type="submit" variant="default">
                Guardar
              </Button>
              <DialogClose asChild>
                <Button variant="secondary">Cerrar</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
