const BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

export const fetchTipoPagos = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/tipopagos`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.status === 403) {
      throw new Error("USER_DISABLED");
    }

    if (!response.ok) {
      throw new Error("Failed to fetch tipoPagos");
    }

    const data = await response.json();
    return data; // Directamente devolvemos la data que es el array de tipoPagos
  } catch (error) {
    throw error;
  }
};

export const createTipoPago = async (values: any) => {
  try {
    const response = await fetch(`${BASE_URL}/api/tipopagos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateTipoPago = async (tipoPagoId: number, values: any) => {
  try {
    const response = await fetch(`${BASE_URL}/api/tipopagos/${tipoPagoId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      const errorText = await response.text(); // Usamos .text() para leer la respuesta como texto plano
      throw new Error(
        errorText.length > 0
          ? JSON.parse(errorText).message
          : "No se pudo actualizar el tipo de pago.",
      );
    }

    // Solo intentamos analizar como JSON si hay contenido
    if (
      response.status !== 204 &&
      response.headers.get("content-length") !== "0"
    ) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const deleteTipoPago = async (tipoPagoId: number, token: string) => {
  try {
    const response = await fetch(`${BASE_URL}/api/tipopagos/${tipoPagoId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getTipoPago = async (tipoPagoId: number) => {
  try {
    const response = await fetch(`${BASE_URL}/api/tipopagos/${tipoPagoId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Failed to fetch tipoPago");
    }

    const data = await response.json();
    return data[0]; // Devuelve el primer objeto del array, que es el tipo de pago.
  } catch (error) {
    throw error;
  }
};

export const checkTipoPagoExists = async (
  tipoPagoId: number,
  token: string,
) => {
  try {
    const response = await fetch(`${BASE_URL}/api/tipopagos/${tipoPagoId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 404) {
      return false;
    }

    if (!response.ok) {
      throw new Error("Failed to fetch tipoPago");
    }

    return true;
  } catch (error) {
    throw error;
  }
};
