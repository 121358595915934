/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { PhTrashFill } from "@/components/icons/PhTrashFill";

interface DeleteIngresoDialogProps {
  onDelete: (ingresoId: number) => Promise<void>;
  ingresoId: number;
  ingresoText: string;
  disabled?: boolean;
}

export function DeleteIngresoDialog({
  onDelete,
  ingresoId,
  ingresoText,
  disabled = false,
}: DeleteIngresoDialogProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = async () => {
    await onDelete(ingresoId);
    setIsOpen(false);
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger asChild>
        <Button variant="outline" size="sm" disabled={disabled}>
          <PhTrashFill className="w-4 h-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Confirmar eliminación</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          ¿Estás seguro de que deseas eliminar el ingreso "{ingresoText}"? Esta
          acción no se puede deshacer.
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancelar
          </AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete}>Eliminar</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
