const BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

export const fetchCausas = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/causas`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.status === 403) {
      throw new Error("USER_DISABLED");
    }

    if (!response.ok) {
      throw new Error("Failed to fetch causas");
    }

    const data = await response.json();
    return data.causas; // Extraemos el arreglo de causas del objeto retornado
  } catch (error) {
    throw error;
  }
};

export const createCausa = async (values: any) => {
  try {
    const response = await fetch(`${BASE_URL}/api/causas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateCausa = async (causaId: number, values: any) => {
  try {
    const response = await fetch(`${BASE_URL}/api/causas/${causaId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCausa = async (causaId: number) => {
  try {
    const response = await fetch(`${BASE_URL}/api/causas/${causaId}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkCausaNameExists = async (name: string, token: string) => {
  try {
    const response = await fetch(`${BASE_URL}/api/causas/check-name`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = await response.json();
    return data.exists; // Asume que el backend devuelve un objeto con una propiedad 'exists'
  } catch (error) {
    throw error;
  }
};
