// Función para formatear valores en unidades monetarias
export const formattedValue = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const formattedIVAValue = (value: number) => {
  // Dividir el valor por 100 para obtener el valor correcto
  const adjustedValue = Math.floor(value / 100);

  // Convertir el valor a una cadena sin decimales
  const valueString = adjustedValue.toString();

  // Agregar separadores de miles
  const formattedValue = valueString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return "$" + formattedValue;
};

export const formattedValueARS = (value: number) =>
  new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
  }).format(value);
